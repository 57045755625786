import { FC, useEffect, useState } from 'react';
import { Alert, Button } from '../../components/primitives';
import { useAppDispatch } from '../../services/store';
import useUser from '../../utils/helpers/hooks/useUser';
import styles from './Profile.module.scss';
import BasicPanel from '../../components/primitives/basic-panel/BasicPanel';
import classNames from 'classnames';
import Drawer from '../../components/primitives/drawer/Drawer';
import EditProfile from '../../components/partials/edit-panels/profile/edit/EditProfile';
import ChangePassword from '../../components/partials/edit-panels/change-password/ChangePassword';
import useAuthorization from '../../utils/helpers/hooks/useAuthorization';
import {
	disableMFACode,
	getPasswordRequirements,
	logOut,
} from '../../services/store/slices/authorization.slice';
import DataList from '../../components/primitives/data-list/DataList';
import DataListItem from '../../components/primitives/data-list-item/DataListItem';
import useCompany from '../../utils/helpers/hooks/useCompany';
import { handleWithTryCatch } from '../../utils/helpers/errors';
import { CommonResultStatusType } from '../../utils/types';
import { getUserRoleDisplay } from '../../utils/helpers/common';
import ProfileSkeleton from './ProfileSkeleton';
import ReactSwitch from 'react-switch';
import {
	updateUserEmailPreferences,
	updateUserMFAConfigured,
} from '../../services/store/slices/user.slice';
import EditTwoFactor from '../../components/partials/edit-panels/profile/two-factor/EditTwoFactorPanel';
import ColoredTag from '../../components/primitives/tag/ColoredTag';
import { AppTagColors } from '../../utils/helpers/constants';
import Scrollbar from '../../components/primitives/scrollbar/Scrollbar';

const Profile: FC = () => {
	const dispatch = useAppDispatch();
	const { info, isAdmin, loading } = useUser();
	const { passwordRequirements } = useAuthorization();
	const { info: companyInfo } = useCompany();

	const [openEditTwoFactor, setOpenEditTwoFactor] = useState(false);
	const [openEditProfile, setOpenEditProfile] = useState(false);
	const [openChangePassword, setOpenChangePassword] = useState(false);
	const [profileUpdateResult, setProfileUpdateResult] = useState<CommonResultStatusType>('');
	const [passwordChangeResult, setPasswordChangeResult] = useState<CommonResultStatusType>('');
	const [notificationOn, setNotificationOn] = useState(!!info?.emailPreferences);
	const [emailPreferencesUpdateResult, setEmailPreferencesUpdateResult] =
		useState<CommonResultStatusType>('');
	const [twoFactorAuthOn, setTwoFactorAuthOn] = useState(!!info?.mfaConfigured);
	const [twoFactorAuthUpdateResult, setTwoFactorAuthUpdateResult] =
		useState<CommonResultStatusType>('');

	const userInfo =
		!info || !companyInfo || loading ? (
			<ProfileSkeleton />
		) : (
			<DataList className={styles['user-info']}>
				<DataListItem label="First Name" text={info?.firstName} />
				<DataListItem label="Last Name" text={info?.lastName} />
				<DataListItem label="Email" text={info?.email} />
				<DataListItem label="Title" text={info?.title} />
				<DataListItem
					label="Role"
					text={`${companyInfo?.name} ${getUserRoleDisplay(info?.role)}`}
				/>
			</DataList>
		);

	const configsBlock = (
		<div className={styles.configs}>
			<div
				className={classNames(styles.item, styles.password)}
				onClick={() => setOpenChangePassword(true)}
			>
				Change password
			</div>

			{/* <div className={classNames(styles.item, styles.notification)}>
				Notification settings
			</div> */}

			<div
				className={classNames(styles.logout, styles.item)}
				onClick={() => dispatch(logOut())}
			>
				Log Out
			</div>
		</div>
	);

	const onProfileUpdateSuccess = () => {
		setProfileUpdateResult('success');
		setOpenEditProfile(false);
	};

	const onProfileUpdateError = () => {
		setProfileUpdateResult('error');
		setOpenEditProfile(false);
	};

	const onTwoFactorSuccess = () => {
		setTwoFactorAuthUpdateResult('success');
		setOpenEditTwoFactor(false);
	};

	const onTwoFactorError = () => {
		setTwoFactorAuthUpdateResult('error');
		setOpenEditTwoFactor(false);
		if (!info?.mfaConfigured) onTwoFactorAuthChange(false);
	};

	const onPasswordChangeSuccess = () => {
		setPasswordChangeResult('success');
		setOpenChangePassword(false);
	};

	const onPasswordChangeError = () => setPasswordChangeResult('error');

	const editProfileSection = (
		<>
			<EditProfile
				open={openEditProfile}
				onUpdateSuccess={onProfileUpdateSuccess}
				onUpdateError={onProfileUpdateError}
				closeHandler={() => setOpenEditProfile(false)}
			/>

			<Alert
				uniqueKey={'profile-success'}
				show={profileUpdateResult === 'success'}
				type="success"
				message="Profile updated!"
				clearActionStatus={() => setProfileUpdateResult('')}
			/>
			<Alert
				uniqueKey={'profile-error'}
				show={profileUpdateResult === 'error'}
				type="error"
				message="Error occurred while updating profile"
				clearActionStatus={() => setProfileUpdateResult('')}
			/>

			<Alert
				uniqueKey={'email-preferences-success'}
				show={emailPreferencesUpdateResult === 'success'}
				type="success"
				message="Email preferences updated!"
				clearActionStatus={() => setEmailPreferencesUpdateResult('')}
			/>
			<Alert
				uniqueKey={'email-preferences-error'}
				show={emailPreferencesUpdateResult === 'error'}
				type="error"
				message="Error occurred while updating email preferences"
				clearActionStatus={() => setEmailPreferencesUpdateResult('')}
			/>
		</>
	);

	const editTwoStepAuthSection = (
		<>
			<EditTwoFactor
				open={openEditTwoFactor}
				onUpdateSuccess={onTwoFactorSuccess}
				onUpdateError={onTwoFactorError}
				closeHandler={() => {
					setOpenEditTwoFactor(false);
					if (!info?.mfaConfigured) onTwoFactorAuthChange(false);
				}}
			/>

			<Alert
				uniqueKey={'two-step-auth-success'}
				show={twoFactorAuthUpdateResult === 'success'}
				type="success"
				message="2-Step verification updated!"
				clearActionStatus={() => setTwoFactorAuthUpdateResult('')}
			/>
			<Alert
				uniqueKey={'two-step-auth-error'}
				show={twoFactorAuthUpdateResult === 'error'}
				type="error"
				message="Error occurred while updating 2-Step verification"
				clearActionStatus={() => setTwoFactorAuthUpdateResult('')}
			/>
		</>
	);

	const onTwoFactorAuthChange = (turnOn: boolean) => {
		setTwoFactorAuthOn(turnOn);
		if (turnOn) setOpenEditTwoFactor(true);

		if (turnOn === false && info?.mfaConfigured) {
			handleWithTryCatch(
				async () => {
					await dispatch(disableMFACode());
					await dispatch(updateUserMFAConfigured(false));
				},
				undefined,
				() => setTwoFactorAuthUpdateResult('error'),
			);
		} 
	};

	const onNotificationsChange = (turnOn: boolean) => {
		setNotificationOn(turnOn);

		handleWithTryCatch(
			async () => {
				await dispatch(updateUserEmailPreferences(turnOn));
				setEmailPreferencesUpdateResult('success');
			},
			undefined,
			() => setEmailPreferencesUpdateResult('error'),
		);
	};

	const notificationsConfigBlock = (
		<label className={styles['switcher-wrap']}>
			<ReactSwitch
				onChange={onNotificationsChange}
				checked={notificationOn}
				uncheckedIcon={false}
				checkedIcon={false}
				activeBoxShadow={'none'}
				offHandleColor={'#A195EB'}
				onHandleColor={'#7967E3'}
				onColor={'#EFEDFC'}
				offColor={'#F9F9FF'}
				className={notificationOn ? '' : styles.off}
				handleDiameter={16}
				height={20}
				width={36}
			/>

			<p className={styles.label}>Receive all email notifications</p>
		</label>
	);

	const twoFactorConfigBlock = (
		<label className={styles['switcher-wrap']}>
			<ReactSwitch
				onChange={onTwoFactorAuthChange}
				checked={twoFactorAuthOn}
				uncheckedIcon={false}
				checkedIcon={false}
				activeBoxShadow={'none'}
				offHandleColor={'#A195EB'}
				onHandleColor={'#7967E3'}
				onColor={'#EFEDFC'}
				offColor={'#F9F9FF'}
				className={twoFactorAuthOn ? '' : styles.off}
				handleDiameter={16}
				height={20}
				width={36}
			/>

			<p className={styles.label}>Enable 2-Step Verification for this account</p>
		</label>
	);

	const changePasswordSection = (
		<>
			<Drawer
				open={openChangePassword}
				onCloseClickHandler={() => setOpenChangePassword(false)}
				title="Change Password"
			>
				{openChangePassword ? (
					<ChangePassword
						onUpdateSuccess={onPasswordChangeSuccess}
						onUpdateError={onPasswordChangeError}
						closeHandler={() => setOpenChangePassword(false)}
					/>
				) : null}
			</Drawer>

			<Alert
				uniqueKey={'password-success'}
				show={passwordChangeResult === 'success'}
				type="success"
				message="Password changed!"
				clearActionStatus={() => setPasswordChangeResult('')}
			/>
			<Alert
				uniqueKey={'password-error'}
				show={passwordChangeResult === 'error'}
				type="error"
				message="Error occurred while changing password"
				clearActionStatus={() => setPasswordChangeResult('')}
			/>
		</>
	);

	useEffect(() => {
		if (!passwordRequirements) {
			handleWithTryCatch(() => dispatch(getPasswordRequirements()));
		}
	}, [dispatch, passwordRequirements]);

	useEffect(() => {
		setNotificationOn(!!info?.emailPreferences);
		setTwoFactorAuthOn(!!info?.mfaConfigured);
	}, [info]);

	return (
		<>
			<h2>Profile</h2>
			<Scrollbar className={styles.scrollbar}>
				<div className={styles.panels}>
					<BasicPanel className={styles['basic-panel']}>
						<h3>Personal Information</h3>

						{isAdmin ? (
							<Button
								small
								className={styles.edit}
								negative
								width={56}
								onClick={() => setOpenEditProfile(true)}
							>
								Edit
							</Button>
						) : null}

						{userInfo}
					</BasicPanel>

					<BasicPanel className={classNames(styles['basic-panel'], styles.notifications)}>
						<h3>Email Preferences</h3>

						{notificationsConfigBlock}
					</BasicPanel>

					<BasicPanel className={classNames(styles['basic-panel'], styles.notifications)}>
						<h3>2-Step Verification</h3>

						{twoFactorAuthOn && info?.mfaConfigured ? (
							<Button
								small
								className={styles.edit}
								negative
								width={56}
								onClick={() => setOpenEditTwoFactor(true)}
							>
								Edit
							</Button>
						) : (
							<ColoredTag
								tagId={'2-step-verification-recommended'}
								text={'Recommended'}
								bgColor={AppTagColors[8]}
								className={styles.recommendedLabel}
							/>
						)}

						{twoFactorConfigBlock}
					</BasicPanel>

					<BasicPanel className={classNames(styles['basic-panel'], styles.configs)}>
						{configsBlock}
					</BasicPanel>

					{isAdmin && info ? editProfileSection : null}

					{editTwoStepAuthSection}

					{changePasswordSection}
				</div>
			</Scrollbar>
		</>
	);
};

export default Profile;
