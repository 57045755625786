import { combineReducers, configureStore } from '@reduxjs/toolkit';

import authorization from './slices/authorization.slice';
import user from './slices/user.slice';
import company from './slices/company.slice';
import frameworks from './slices/frameworks.slice';
import controls from './slices/controls.slice';
import policies from './slices/policies.slice';
import tags from './slices/tags.slice';
import companyUsers from './slices/company-users.slice';
import assets from './slices/assets.slice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const env = process.env.REACT_APP_ENV;

const appReducers = combineReducers({
	authorization,
	user,
	company,
	frameworks,
	controls,
	policies,
	tags,
	companyUsers,
	assets,
});

const store = configureStore({
	reducer: appReducers,
	devTools: env !== 'production',
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
