import { useEffect } from 'react';
import useUser from './useUser';
import { useAppDispatch } from '../../../services/store';
import useFrameworks from './useFrameworks';
import { handleWithTryCatch } from '../errors';
import {
	getArchivedPolicies,
	getBasePolicies,
	getPolicies,
} from '../../../services/store/slices/policies.slice';
import useCompanyUsers from './useCompanyUsers';
import { getControls } from '../../../services/store/slices/controls.slice';
import { getTags } from '../../../services/store/slices/tags.slice';

export default function useGetPolicies(withLoading: boolean = true) {
	const dispatch = useAppDispatch();
	const { authorized, info: userInfo } = useUser();
	const { items: frameworkItems } = useFrameworks();
	const { items: companyUsers } = useCompanyUsers();

	useEffect(() => {
		if (authorized && frameworkItems && userInfo?.id && companyUsers) {
			(async () => {
				await handleWithTryCatch(async () => {
					// we need to have fresh controls data, mainly for tags
					dispatch(getTags());
					await handleWithTryCatch(async () => await dispatch(getControls(true)));

					dispatch(getPolicies(withLoading));
					dispatch(getBasePolicies());
					dispatch(getArchivedPolicies());
				});
			})();
		}
	}, [authorized, dispatch, frameworkItems, userInfo?.id, withLoading, companyUsers]);
}
