import React, { FC } from 'react';
import styles from './SectionTitle.module.scss';
import ISectionTitle from './ISectionTitle';
import classNames from 'classnames';

const SectionTitle: FC<ISectionTitle> = ({ className, children, withTag }) => {
	return (
		<div className={classNames(styles['section-title'], className)}>
			<span className={styles.title}>{children}</span>

			{withTag ? <div className={styles.tag}>{withTag}</div> : null}
		</div>
	);
};

export default SectionTitle;
