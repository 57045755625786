import { FC, useCallback, useEffect, useState } from 'react';
import { IControl } from '../../../../services/store/slices/controls.slice';
import Table from '../../../primitives/table/Table';
import { getBriefAssetsColumns } from './utils';
import styles from './AddAssetsTable.module.scss';
import { IKeyValuePair, ISortProps } from '../../../../utils/types';
import { processTableData } from '../../../../utils/helpers/common';
import classNames from 'classnames';
import SearchFilter from '../../../primitives/filters/search-filter/SearchFilter';
import IAddAssetsTable from './IAddAssetsTable';
import { IAsset } from '../../../../services/store/slices/assets.slice';

const AddAssetsTable: FC<IAddAssetsTable> = ({
	className,
	data,
	selectedRows,
	setSelectedRows,
	bodyMaxHeight = 360,
	withSearch = true,
}) => {
	const [dataToDisplay, setDataToDisplay] = useState<IAsset[]>([]);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const [currentFilters, setCurrentFilters] = useState<IKeyValuePair>({
		search: (dataRecord: IControl) => true,
	});

	useEffect(() => {
		if (data.length) {
			const proccessedData: IAsset[] = processTableData(data, currentFilters, currentSort);

			setDataToDisplay(proccessedData);
		}
	}, [currentFilters, currentSort, data]);

	return (
		<div className={classNames(styles['table-wrap'], className)}>
			{withSearch ? (
				<SearchFilter
					className={styles.search}
					properties={['name', 'type']}
					setFilters={setCurrentFilters}
				/>
			) : null}

			<Table
				tableKey="add-assets"
				data={dataToDisplay}
				columns={getBriefAssetsColumns()}
				options={{
					rowCheckbox: true,
					rowClassName: styles.row,
					onSortChange,
					emptyResultMessage: !data.length
						? 'No assets to display.'
						: 'No matches found. Please try another search query.',
					emptyResultMessageType: !data.length ? 'common' : 'search',
					bodyMaxHeight,
					small: true,
					scrollFullRightPosition: true,
					selectedRows,
					setSelectedRows,
				}}
			/>
		</div>
	);
};

export default AddAssetsTable;
