import { FC } from 'react';
import styles from './ModalDeleteTag.module.scss';
import Modal from '../../../primitives/modal/Modal';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import { ActionsBlock, Button } from '../../../primitives';
import { useAppDispatch } from '../../../../services/store';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import IModalDeleteTag from './IModalDeleteTag';
import { bulkDeleteTags } from '../../../../services/store/slices/tags.slice';
import useTags from '../../../../utils/helpers/hooks/useTags';
import Loader from '../../../primitives/loader/Loader';

const ModalDeleteTag: FC<IModalDeleteTag> = ({
	open,
	setOpen,
	ids,
	names,
	onDeleteError,
	onDeleteSuccess,
}) => {
	const dispatch = useAppDispatch();
	const { loading } = useTags();

	const onDelete = () => {
		handleWithTryCatch(
			async () => {
				if (ids?.length) {
					await dispatch(bulkDeleteTags(ids));
					onDeleteSuccess();
					setOpen(false);
				}
			},
			undefined,
			onDeleteError,
		);
	};

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<h2 className={styles.title}>Delete Tag{ids.length > 1 ? 's' : ''}?</h2>

				<p className={styles.text}>
					Are you sure you want to delete <strong>{names?.join(', ')}</strong> from tags?
				</p>

				<ActionsBlock>
					<Button type="button" width={160} negative onClick={() => setOpen(false)}>
						Cancel
					</Button>

					<Button
						errorStyled
						type="button"
						width={160}
						disabled={loading}
						onClick={onDelete}
					>
						{loading ? (
							<Loader
								color={'#fcedf0'}
								transparentColor="transparent"
								thin
								maxHeight={14}
								maxWidth={14}
							/>
						) : (
							'Delete'
						)}
					</Button>
				</ActionsBlock>
			</BasicPanel>
		</Modal>
	);
};

export default ModalDeleteTag;
