import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './Alert.module.scss';
import classNames from 'classnames';
import IAlert from './IAlert';
import { createPortal } from 'react-dom';

export const Alert: FC<IAlert> = ({
	className,
	message,
	type,
	show,
	uniqueKey,
	clearActionStatus,
	hideAfterDelay = true,
	hideDelayTime = 2000,
}) => {
	const portalsElement = useRef(document.getElementById('portals'));
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		if (visible && hideAfterDelay) {
			const delay = setTimeout(() => {
				setVisible(false);
				if (clearActionStatus) clearActionStatus();
				clearTimeout(delay);
			}, hideDelayTime);
		}
	}, [clearActionStatus, hideAfterDelay, visible, hideDelayTime]);

	useEffect(() => {
		setVisible(show);
	}, [show]);

	useEffect(() => {
		if (visible) {
			const visibleAlerts = document.querySelectorAll(`.${styles.visible}`);

			if (visibleAlerts.length > 1) {
				let totalHeight = 32;

				visibleAlerts.forEach((alert) => {
					//@ts-ignore
					const alertHeight = alert.offsetHeight;

					//@ts-ignore
					alert.style.bottom = `${totalHeight}px`;

					totalHeight += alertHeight + 16;
				});
			}
		}
	}, [visible]);

	return (
		<>
			{portalsElement.current
				? createPortal(
						<div
							className={classNames(
								styles.alert,
								className,
								type ? styles[type] : '',
								visible ? styles.visible : '',
							)}
						>
							{message}
						</div>,
						portalsElement.current,
						uniqueKey,
					)
				: null}
		</>
	);
};
