import { useSelector } from 'react-redux';
import { RootState } from '../../../services/store';

export default function useControls() {
	const { items, loading, controlLoading, updateLoading, error, commentsLoading } = useSelector(
		(store: RootState) => store.controls,
	);

	return {
		items,
		loading,
		error,
		commentsLoading,
		updateLoading,
		controlLoading,
	};
}
