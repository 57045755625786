import { FC, useEffect, useState } from 'react';
import styles from './ModalAddControls.module.scss';
import Modal from '../../../primitives/modal/Modal';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import IModalAddControls from './IModalAddControls';
import { ActionsBlock, Button } from '../../../primitives';
import useControls from '../../../../utils/helpers/hooks/useControls';
import AddControlsTable from '../../tables/add-controls-table/AddControlsTable';
import { sortBaseControlObjByIdAsc } from '../../../../utils/helpers/common';
import { IIdName } from '../../../../utils/types';

const ModalAddControls: FC<IModalAddControls> = ({
	predefinedItems,
	allPreselected = false,
	description,
	open,
	setOpen,
	onNewAdded,
	entityControlIds,
	setEntityControls,
	cancelBtnText = 'Cancel',
	withSearch = true,
	onCancel,
}) => {
	const { items: controlItems } = useControls();

	const itemsToDisplay = predefinedItems?.length
		? predefinedItems
		: controlItems?.filter((c) => !entityControlIds.some((ec) => ec.id === c.id));

	const [selectedControls, setSelectedControls] = useState<string[]>([]);
	const submitIsDisabled = !selectedControls.length;

	const onAdd = () => {
		const selectedObj = selectedControls.map((id) => {
			return {
				id,
				displayId: controlItems?.find((c) => c.id === id)?.controlId,
				name: controlItems?.find((c) => c.id === id)?.name,
			} as IIdName;
		});

		const newControls = entityControlIds.length
			? sortBaseControlObjByIdAsc([...entityControlIds, ...selectedObj])
			: selectedObj;

		onNewAdded(selectedObj);
		setEntityControls(newControls);
		setSelectedControls([]);
		setOpen(false);
	};

	const renderActions = () => {
		return (
			<ActionsBlock className={styles.actions}>
				<Button
					width={136}
					type="button"
					negative
					onClick={() => {
						if (onCancel) onCancel();
						setSelectedControls([]);
						setOpen(false);
					}}
				>
					{cancelBtnText}
				</Button>

				<Button width={136} type="button" disabled={submitIsDisabled} onClick={onAdd}>
					Add Selected
				</Button>
			</ActionsBlock>
		);
	};

	useEffect(() => {
		if (allPreselected && itemsToDisplay?.length) {
			setSelectedControls(itemsToDisplay.map((control) => control.id));
		}
	}, [allPreselected, itemsToDisplay]);

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<div className={styles.top}>
					<h2 className={styles.title}>Add Controls</h2>

					{description ? <p className={styles.description}>{description}</p> : null}

					<div className={styles['table-wrap']}>
						<AddControlsTable
							data={sortBaseControlObjByIdAsc(
								itemsToDisplay?.map(
									(control) =>
										({
											id: control.id,
											displayId: control.controlId,
											name: control.name,
										}) as IIdName,
								) || [],
							)}
							selectedRows={selectedControls}
							setSelectedRows={setSelectedControls}
							withSearch={withSearch}
						/>
					</div>
				</div>

				{renderActions()}
			</BasicPanel>
		</Modal>
	);
};

export default ModalAddControls;
