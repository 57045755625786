import React, { FC, useRef, useState } from 'react';
import styles from './VariableTooltip.module.scss';
import { IVariableTooltip } from './IVariableTooltip';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';

const VariableTooltip: FC<IVariableTooltip> = ({
	className,
	tooltipId,
	hidden,
	render,
	setEditMode,
}) => {
	const ref = useRef<any>(null);
	const [isBottomStart, setIsBottomStart] = useState(false);
	const [isBottomEnd, setIsBottomEnd] = useState(false);

	const afterShowHandler = () => {
		if (ref.current) {
			setIsBottomStart(ref.current?.place === 'bottom-start');
			setIsBottomEnd(ref.current?.place === 'bottom-end');
		}
	};

	return (
		<div className={classNames(styles['tooltip-wrap'], className)}>
			<Tooltip
				ref={ref}
				id={tooltipId}
				className={classNames(
					styles.tooltip,
					isBottomStart ? styles['bottom-start'] : '',
					isBottomEnd ? styles['bottom-end'] : '',
				)}
				offset={8}
				place={'bottom-end'}
				noArrow
				openOnClick
				clickable
				render={render}
				hidden={hidden}
				afterShow={afterShowHandler}
				afterHide={() => {
					setIsBottomStart(false);
					setEditMode(false);
				}}
			/>
		</div>
	);
};

export default VariableTooltip;
