import { useMemo, useState } from 'react';
import axios from 'axios';
import { deleteCompanyLogo } from '../../../services/store/slices/company.slice';
import { useAppDispatch } from '../../../services/store';
import { handleWithTryCatch } from '../errors';

export const useUploadForm = () => {
	const dispatch = useAppDispatch();
	const [uploadStatuses, setUploadStatuses] = useState({
		isSuccess: false,
		isFailed: false,
		isAborted: false,
	});
	const [uploadedFileId, setUploadedFileId] = useState<string>('');
	const [progress, setProgress] = useState(0);

	const abortController = useMemo(() => new AbortController(), []);

	const onFail = () => {
		setUploadStatuses((prev) => {
			return {
				...prev,
				isFailed: true,
			};
		});
		setProgress(0);
		setUploadedFileId('');
	};

	const uploadForm = async (fileId: string, file: File, url: string) => {
		setProgress(0);
		setUploadStatuses((prev) => {
			return {
				...prev,
				isSuccess: false,
				isFailed: false,
				isAborted: false,
			};
		});

		try {
			await axios.put(url, file, {
				signal: abortController.signal,
				onUploadProgress: (progressEvent) => {
					const progress = Math.round(
						(progressEvent.loaded / (progressEvent.total ?? 1)) * 100,
					);
					setProgress(progress);
				},
				baseURL: '',
			});

			if (fileId) {
				setUploadedFileId(fileId);
				setUploadStatuses((prev) => {
					return {
						...prev,
						isSuccess: true,
					};
				});

				return fileId;
			} else {
				onFail();
				handleWithTryCatch(() => dispatch(deleteCompanyLogo(fileId!)));
			}
		} catch (error) {
			onFail();
			handleWithTryCatch(() => dispatch(deleteCompanyLogo(fileId!)));
		}
	};

	const abortUpload = () => {
		abortController.abort();

		setUploadStatuses((prev) => {
			return {
				...prev,
				isAborted: true,
			};
		});
	};

	const clearStatuses = () => {
		setUploadStatuses({
			isSuccess: false,
			isFailed: false,
			isAborted: false,
		});
	};

	const { isSuccess, isFailed, isAborted } = uploadStatuses;

	return {
		uploadForm,
		abortUpload,
		isSuccess,
		isFailed,
		isAborted,
		progress,
		uploadedFileId,
		clearStatuses,
	};
};
