import React, { FC, MouseEvent } from 'react';
import ITagListComponentOverride from './ITagListComponentOverride';
import ColoredTag from '../../../tag/ColoredTag';
import { ISelectOption } from '../../../../../utils/types';
import styles from './TagListComponentOverride.module.scss';
import { AddNewTag } from '../../../';

const TagListComponentOverride: FC<ITagListComponentOverride> = ({
	selectedOptions,
	addNewAction,
	onChangeHandler,
	placeholder,
	disabled,
}) => {
	const onRemoveClickHandler = (e: MouseEvent<HTMLSpanElement>, tagValue: string) => {
		e.stopPropagation();
		const newValue = selectedOptions.filter((item: ISelectOption) => item.value !== tagValue);
		onChangeHandler(newValue);
	};

	const renderTag = (tag: ISelectOption) => {
		return (
			<ColoredTag
				tagId={tag.value}
				key={tag.value}
				text={tag.label}
				bgColor={tag.metaData?.color}
				onRemoveClickHandler={(e) => onRemoveClickHandler(e, tag.value)}
				withoutBackground
			/>
		);
	};

	if (!!addNewAction) {
		return (
			<div className={styles['tags-list']}>
				{selectedOptions.length ? (
					<>
						{selectedOptions?.map((tag: ISelectOption, idx: number) => {
							if (idx === selectedOptions.length - 1) {
								return (
									<>
										{renderTag(tag)}

										<AddNewTag
											key={'new'}
											onAddClickHandler={addNewAction}
											disabled={disabled}
										/>
									</>
								);
							}

							return renderTag(tag);
						})}
					</>
				) : (
					<AddNewTag onAddClickHandler={addNewAction} disabled={disabled} />
				)}
			</div>
		);
	}

	return (
		<div className={styles['tags-list']}>
			{!selectedOptions?.length && placeholder ? (
				<span className={styles.placeholder}>{placeholder}</span>
			) : null}

			{selectedOptions?.map((tag: ISelectOption) => renderTag(tag))}
		</div>
	);
};

export default TagListComponentOverride;
