import { useEffect, useMemo, useState } from 'react';
import useCompanyUsers from '../../utils/helpers/hooks/useCompanyUsers';
import ShareholdersTable from '../../components/partials/tables/shareholders/ShareholdersTable';
import useGetCompanyUsers from '../../utils/helpers/hooks/useGetCompanyUsers';
import useUser from '../../utils/helpers/hooks/useUser';
import { useLocation } from 'react-router-dom';
import { AppQueryParams } from '../../utils/helpers/constants';

const Shareholders = () => {
	const location = useLocation();
	const { info: userInfo } = useUser();

	const { items: companyUsers, loading } = useCompanyUsers();
	const [dataLoading, setDataLoading] = useState(true);

	const transformedData = useMemo(() => {
		if (!companyUsers) return [];

		return companyUsers.filter((item) => item.id !== userInfo?.id);
	}, [companyUsers, userInfo?.id]);

	const navigateToItem = useMemo(() => {
		if (!dataLoading && companyUsers) {
			const userIdFromUrl = new URLSearchParams(location.search).get(AppQueryParams.item);

			return userIdFromUrl;
		}
	}, [companyUsers, dataLoading, location.search]);

	useGetCompanyUsers();

	useEffect(() => {
		if (companyUsers) setDataLoading(false);
	}, [companyUsers]);

	return (
		<>
			<h2>Shareholders</h2>

			<ShareholdersTable
				data={transformedData || []}
				dataLoading={dataLoading || loading}
				showItemId={navigateToItem || ''}
			/>
		</>
	);
};

export default Shareholders;
