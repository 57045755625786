import React, { FC } from 'react';
import styles from './MultiSelectFilter.module.scss';
import MultiSelect from '../../form/multi-select/MultiSelect';
import IMultiSelectFilter from './IMultiSelectFilter';

const MultiSelectFilter: FC<IMultiSelectFilter> = ({
	options,
	placeholder,
	isTagStyledList,
	isTagsWithoutBg,
	onChange,
	withSearch,
}) => {
	return (
		<MultiSelect
			placeholder={placeholder}
			options={options || []}
			onValueChange={onChange}
			className={styles.filter}
			filterMode
			isTagStyledList={isTagStyledList}
			isTagsWithoutBg={isTagsWithoutBg}
			withSearch={withSearch}
		/>
	);
};

export default MultiSelectFilter;
