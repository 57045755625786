import { useEffect } from 'react';
import useUser from './useUser';
import { useAppDispatch } from '../../../services/store';
import useFrameworks from './useFrameworks';
import { getControls } from '../../../services/store/slices/controls.slice';
import { handleWithTryCatch } from '../errors';
import useCompanyUsers from './useCompanyUsers';
import { getTags } from '../../../services/store/slices/tags.slice';

export default function useGetControls(withLoading: boolean = true) {
	const dispatch = useAppDispatch();
	const { authorized, info: userInfo } = useUser();
	const { items: frameworkItems } = useFrameworks();
	const { items: companyUsers } = useCompanyUsers();

	useEffect(() => {
		if (authorized && frameworkItems && userInfo?.id && companyUsers) {
			handleWithTryCatch(async () => {
				dispatch(getTags());
				await dispatch(getControls(withLoading));
			});
		}
	}, [authorized, dispatch, frameworkItems, userInfo?.id, withLoading, companyUsers]);
}
