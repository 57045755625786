import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './PoliciesStep.module.scss';
import { ActionsBlock, Button } from '../../../components/primitives';
import IOnboardingStep from '../IOnboardingStep';
import { SUPPORTED_POLICY_TYPES, baseLineOptions } from '../../../utils/helpers/constants';
import Select from '../../../components/primitives/form/select/Select';
import { AnimatedScreenWrapper } from '../../../utils/helpers/hocs/transition/AnimatedScreenWrapper';
import FilePicker from '../../../components/primitives/file-picker/FilePicker';
import ProgressBar from '../../../components/primitives/progress-bar/ProgressBar';
import FileInfo from '../../../components/primitives/file-info/FileInfo';
import Back from '../../../components/primitives/back/Back';
import { IFileUploadResult } from '../../../utils/types';
import { useAppDispatch } from '../../../services/store';
import { initiateOnboarding } from '../../../services/store/slices/company.slice';
import useFrameworks from '../../../utils/helpers/hooks/useFrameworks';
import { handleWithTryCatch } from '../../../utils/helpers/errors';
import Error from '../../../components/primitives/error/Error';
import Loader from '../../../components/primitives/loader/Loader';

const PoliciesStep: FC<IOnboardingStep> = ({ framework, setNextStep, goBack }) => {
	const dispatch = useAppDispatch();
	const { items: frameworkItems } = useFrameworks();
	const [filesProgress, setFilesProgress] = useState<IFileUploadResult[]>([]);
	const [error, setError] = useState('');

	const [onboardingInfo, setOnboardingInfo] = useState({
		baseline: '',
	});
	const [policyFiles, setPolicyFiles] = useState<File[] | null>(null);
	const [uploadedFileIds, setUploadedFileIds] = useState<number[]>([]);
	const [filesLoading, setFilesLoading] = useState(false);
	const [finishLoading, setFinishLoading] = useState(false);

	const isNist = useMemo(
		() => framework?.name?.toLocaleLowerCase().includes('nist'),
		[framework?.name],
	);

	const renderProgress = () => {
		if (!policyFiles?.length) return null;

		const calculateGeneralProgress = () => {
			if (filesProgress?.length === 1) {
				return filesProgress[0].progress;
			}

			const result =
				filesProgress?.reduce((acc, file) => {
					return acc + file.progress;
				}, 0) || 0;

			return Math.round(result / filesProgress?.length);
		};

		const generalProgress = calculateGeneralProgress();

		const progressBlock = (
			<div className={styles['loading-progress']}>
				<ProgressBar progressPercent={generalProgress} />

				<div className={styles.info}>
					<div className={styles.text}>Uploading Files</div>
					<div className={styles.percent}>{generalProgress}%</div>
				</div>
			</div>
		);

		return generalProgress < 100 ? progressBlock : null;
	};

	const onFinish = () => {
		setFinishLoading(true);

		let selectedFrameworkId = '';

		if (isNist) {
			const baselineValue = onboardingInfo.baseline;

			const foundedFramework = frameworkItems?.find((fr) => {
				return fr.name?.toLowerCase().includes(baselineValue);
			});

			selectedFrameworkId = foundedFramework?.id || '';
		} else {
			const foundedFramework = frameworkItems?.find((fr) => {
				return fr.name?.toLowerCase().includes(framework?.name?.toLowerCase() || 'iso');
			});

			selectedFrameworkId = foundedFramework?.id || '';
		}

		handleWithTryCatch(
			async () => {
				await dispatch(
					initiateOnboarding({
						framework: Number(selectedFrameworkId),
						fileIds: uploadedFileIds,
					}),
				);

				setFinishLoading(false);
				setNextStep();
			},
			setError,
			() => setFinishLoading(false),
		);
	};

	const renderActionsBlock = () => {
		const finishIsDisabled =
			filesLoading ||
			finishLoading ||
			(isNist && !onboardingInfo.baseline) ||
			!policyFiles?.length ||
			filesProgress.some((progress) => !progress.isSuccess);

		return (
			<ActionsBlock className={styles.actions}>
				<Button type="button" disabled={finishIsDisabled} onClick={onFinish}>
					{finishLoading ? (
						<Loader thin maxHeight={14} maxWidth={14} />
					) : (
						'Start Onboarding'
					)}
				</Button>
			</ActionsBlock>
		);
	};

	const renderFileZone = () => {
		return (
			<>
				{policyFiles?.length ? (
					<>
						<div className={styles.files}>
							{policyFiles.map((file) => (
								<FileInfo
									key={file.name}
									file={file}
									setPolicyFiles={setPolicyFiles}
									setUploadedFileIds={setUploadedFileIds}
									setFilesProgress={setFilesProgress}
								/>
							))}
						</div>
					</>
				) : (
					<p className={styles.info}>Great, now let's upload your policies</p>
				)}

				<FilePicker
					acceptExtensions={SUPPORTED_POLICY_TYPES}
					setAcceptedFiles={setPolicyFiles}
				/>
			</>
		);
	};

	useEffect(() => {
		if (policyFiles?.length) setFilesLoading(true);
	}, [policyFiles?.length]);

	useEffect(() => {
		if (filesProgress && filesProgress.every((progress) => progress.isSuccess))
			setFilesLoading(false);
	}, [filesProgress]);

	return (
		<AnimatedScreenWrapper>
			<div className={styles.title}>
				<Back
					className={styles.back}
					onClick={() => {
						if (goBack) goBack();
					}}
				/>
				{framework?.logo ? (
					<img className={styles.logo} src={framework.logo} alt="F" />
				) : null}
				<h3 className={styles.title}>{framework?.name}</h3>
			</div>

			{isNist ? (
				<Select
					id="baseline"
					className={styles.baseline}
					label={'Baseline'}
					options={baseLineOptions}
					onValueChange={(value: any) =>
						setOnboardingInfo({ ...onboardingInfo, baseline: value })
					}
				/>
			) : null}

			{filesLoading ? renderProgress() : null}

			{renderFileZone()}

			{renderActionsBlock()}

			{error ? (
				<Error
					className={styles.error}
					message={'Sorry, there are some issues with processing. Please try again.'}
				/>
			) : null}
		</AnimatedScreenWrapper>
	);
};

export default PoliciesStep;
