import { FC, useState } from 'react';
import styles from './PrimaryFrameworkReporting.module.scss';
import classNames from 'classnames';
import IPrimaryFrameworkReporting from './IPrimaryFrameworkReporting';
import useCompany from '../../../utils/helpers/hooks/useCompany';
import { ActionsBlock, Button } from '../../primitives';
import StatusInfo from '../../primitives/status-info/StatusInfo';
import { useFileDownload } from '../../../utils/helpers/hooks/useFileDownload';
import { CommonResultStatusType } from '../../../utils/types';
import { useAppDispatch } from '../../../services/store';
import { handleWithTryCatch } from '../../../utils/helpers/errors';
import { getPrimaryFrameworkReport } from '../../../services/store/slices/frameworks.slice';
import { Alert } from '../../';
import { dateInMDYTimeFormat } from '../../../utils/helpers/common';

const PrimaryFrameworkReporting: FC<IPrimaryFrameworkReporting> = ({ className }) => {
	const dispatch = useAppDispatch();
	const { info: companyInfo } = useCompany();

	const [isViewReportRunning, setIsViewReportRunning] = useState(false);
	const [isDownloadReportRunning, setIsDownloadReportRunning] = useState(false);
	const [primaryFrameworkReportResult, setPrimaryFrameworkReportResult] =
		useState<CommonResultStatusType>('');

	const { setDownloadResult, downloadResult, downloadFileByUrl } = useFileDownload();

	const getStatusType = () => {
		const percent = companyInfo?.primaryFrameworkCompliancePercentage || 0;

		if (percent >= 61) return 'success';
		if (percent >= 21) return 'warning';
		return 'error';
	};

	const onViewFrameworkReport = () => {
		setIsViewReportRunning(true);

		handleWithTryCatch(
			async () => {
				const fileUrl = await dispatch(getPrimaryFrameworkReport());

				if (window && fileUrl) {
					setTimeout(() => window.open(fileUrl, '_blank'));

					setPrimaryFrameworkReportResult('success');
					setIsViewReportRunning(false);
				}
			},
			undefined,
			() => {
				setPrimaryFrameworkReportResult('error');
				setIsViewReportRunning(false);
			},
		);
	};

	const onDownloadFrameworkReport = () => {
		setIsDownloadReportRunning(true);

		handleWithTryCatch(
			async () => {
				const fileUrl = await dispatch(getPrimaryFrameworkReport());

				await downloadFileByUrl(fileUrl, 'Primary_framework_report.pdf');
				setPrimaryFrameworkReportResult('success');
				setIsDownloadReportRunning(false);
			},
			undefined,
			() => {
				setPrimaryFrameworkReportResult('error');
				setIsDownloadReportRunning(false);
			},
		);
	};

	const alerts = (
		<>
			<Alert
				uniqueKey={'download-success'}
				show={downloadResult === 'success'}
				type="success"
				message="Primary framework report downloaded"
				clearActionStatus={() => setDownloadResult('')}
			/>

			<Alert
				uniqueKey={'download-error'}
				show={downloadResult === 'error'}
				type="error"
				message={"Report wasn't downloaded. Please try again"}
				clearActionStatus={() => setDownloadResult('')}
			/>

			<Alert
				uniqueKey={'primary-report-success'}
				show={primaryFrameworkReportResult === 'success'}
				type="success"
				message="Primary framework report complete"
				clearActionStatus={() => setPrimaryFrameworkReportResult('')}
			/>

			<Alert
				uniqueKey={'primary-report-error'}
				show={primaryFrameworkReportResult === 'error'}
				type="error"
				message="Error while getting report. Please try again."
				clearActionStatus={() => setPrimaryFrameworkReportResult('')}
			/>
		</>
	);

	return (
		<div className={classNames(styles['primary-framework-actions'], className)}>
			{companyInfo?.primaryFrameworkComplianceEvaluationDate ? (
				<StatusInfo
					className={styles['status-info']}
					type={getStatusType() as CommonResultStatusType}
				>
					<span>
						You meet{' '}
						<strong>{companyInfo?.primaryFrameworkCompliancePercentage || 0}%</strong> of
						this framework guideline
					</span>
					<span>
						{dateInMDYTimeFormat(
							companyInfo?.primaryFrameworkComplianceEvaluationDate,
							companyInfo?.locationTimeZone,
						)}
					</span>
				</StatusInfo>
			) : null}

			<ActionsBlock className={styles.actions}>
				<Button
					negative
					disabled={isViewReportRunning || isDownloadReportRunning}
					width={'calc(50% - 12px)'}
					onClick={onViewFrameworkReport}
					className={classNames(
						styles.action,
						isViewReportRunning ? styles['with-loader'] : '',
					)}
				>
					View Report
				</Button>

				<Button
					disabled={isViewReportRunning || isDownloadReportRunning}
					width={'calc(50% - 12px)'}
					onClick={onDownloadFrameworkReport}
					className={classNames(
						styles.action,
						isDownloadReportRunning ? styles['with-loader'] : '',
					)}
				>
					Download Report
				</Button>
			</ActionsBlock>

			{alerts}
		</div>
	);
};

export default PrimaryFrameworkReporting;
