import { FC, useCallback, useEffect, useState } from 'react';
import styles from './EditShareholder.module.scss';
import Input from '../../../primitives/form/input/Input';
import { ActionsBlock, Button } from '../../../primitives';
import { useForm } from 'react-hook-form';
import IEditShareholder from './IEditShareholder';
import { useAppDispatch } from '../../../../services/store';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import {
	requireValidationPattern,
	//emailValidationPattern,
	nameValidationPattern,
} from '../../../../utils/helpers/common/form';
import Drawer from '../../../primitives/drawer/Drawer';
import ModalLeaveWarning from '../../modals/modal-leave-warning/ModalLeaveWarning';
import useLeaveWarning from '../../../../utils/helpers/hooks/useLeaveWarning';
import {
	ICompanyUser,
	updateCompanyUser,
} from '../../../../services/store/slices/company-users.slice';
import { getUserRoleDisplay, sanitizeData } from '../../../../utils/helpers/common';
import SectionTitle from '../../../primitives/section-title/SectionTitle';
import Select from '../../../primitives/form/select/Select';
import { getRoleOptions } from '../../../../utils/helpers/constants';
import {
	IAssignment,
	getAssignmentsForUser,
	updateAssignments,
} from '../../../../services/store/slices/assignments.slice';
import AssignmentsBriefTable from '../../tables/assignments-brief-table/AssignmentsBriefTable';
import CollapsiblePanel from '../../../primitives/collapsible-panel/CollapsiblePanel';
import useFormItemValues from '../../../../utils/helpers/hooks/useSelectValues';
import Scrollbar from '../../../primitives/scrollbar/Scrollbar';
import classNames from 'classnames';
import useCompanyUsers from '../../../../utils/helpers/hooks/useCompanyUsers';
import Loader from '../../../primitives/loader/Loader';
import useCompany from '../../../../utils/helpers/hooks/useCompany';
import { RoleType } from '../../../../utils/types';

const EditShareholder: FC<IEditShareholder> = ({
	shareholder,
	closeHandler,
	onUpdateError,
	onUpdateSuccess,
	open,
}) => {
	const dispatch = useAppDispatch();
	const {
		register,
		handleSubmit,
		getValues,
		reset,
		setValue,
		formState: { errors },
	} = useForm({
		mode: 'onChange',
	});

	const { updateLoading } = useCompanyUsers();
	const { info: companyInfo } = useCompany();
	const { id, firstName, lastName, email, title, role } = shareholder || {};
	const [userAssignments, setUserAssignments] = useState<IAssignment[] | null>(null);

	const [warningModalOpen, setWarningModalOpen] = useState(false);
	const [showBrowserLeaveWarning, setBrowserShowLeaveWarning] = useState(false);
	const [assignmentsWithChanges, setAssignmentsWithChanges] = useState<IAssignment[]>([]);
	const [error, setError] = useState('');

	useFormItemValues(setValue, { role });

	const onFormSubmitHandler = (data: Partial<ICompanyUser>) => {
		if (Object.keys(errors).length) return;

		const sanitizedData = sanitizeData(data);

		handleWithTryCatch(
			async () => {
				if (id) {
					await dispatch(updateCompanyUser({ ...sanitizedData, userId: Number(id) }));
					await dispatch(updateAssignments(assignmentsWithChanges));

					setAssignmentsWithChanges([]);
					onUpdateSuccess();
				}
			},
			setError,
			onUpdateError,
		);
	};

	const formActions = (
		<ActionsBlock className={styles.actions}>
			<Button
				type="button"
				width={136}
				onClick={() => {
					if (changesWereMade()) setWarningModalOpen(true);
					else closeHandler();
				}}
				negative
			>
				Cancel
			</Button>

			<Button
				type="submit"
				width={136}
				disabled={!!Object.keys(errors).length || updateLoading}
			>
				{updateLoading ? <Loader thin maxHeight={14} maxWidth={14} /> : 'Save'}
			</Button>
		</ActionsBlock>
	);

	const renderDetails = () => {
		const trigger = (
			<SectionTitle className={classNames(styles.details, styles['collapsible-trigger'])}>
				Details
			</SectionTitle>
		);

		const roleDefaultValue =
			role !== RoleType.shareholder
				? `${companyInfo?.name} ${getUserRoleDisplay(role)}`
				: getUserRoleDisplay(role);

		return (
			<CollapsiblePanel trigger={trigger}>
				<div className={styles['input-group']}>
					<Input
						{...register('firstName', {
							...requireValidationPattern(),
							...nameValidationPattern(),
						})}
						defaultValue={firstName}
						className={styles.input}
						withErrorStyle={!!errors.firstName}
						type="text"
						label="First Name"
					/>

					<Input
						{...register('lastName', {
							...requireValidationPattern(),
							...nameValidationPattern(),
						})}
						defaultValue={lastName}
						className={styles.input}
						withErrorStyle={!!errors.lastName}
						type="text"
						label="Last Name"
					/>
				</div>

				<div className={styles['input-group']}>
					<Input
						disabled
						// {...register('email', {
						// 	...requireValidationPattern(),
						// 	...emailValidationPattern(),
						// })}
						name="email"
						value={email}
						withErrorStyle={!!errors.email || error.includes('email')}
						error={
							error.includes('email')
								? 'Email already exists in Gravity Security GRC'
								: ''
						}
						className={styles.input}
						type="text"
						label="Email"
					/>

					<Select
						id="role"
						{...register('role', { ...requireValidationPattern() })}
						options={getRoleOptions(companyInfo?.name || '')}
						defaultValue={roleDefaultValue}
						onValueChange={(value) => {
							setValue('role', value);
						}}
						className={styles.input}
						placeholder={'Select role'}
						label="User Role"
					/>
				</div>

				<Input
					{...register('title', { ...nameValidationPattern() })}
					defaultValue={title}
					className={styles.input}
					withErrorStyle={!!errors.title}
					type="text"
					label="Title"
				/>
			</CollapsiblePanel>
		);
	};

	const renderAssignments = useCallback(() => {
		const trigger = <SectionTitle>Assignments</SectionTitle>;

		return (
			<CollapsiblePanel trigger={trigger}>
				<AssignmentsBriefTable
					assignments={userAssignments}
					onAssignmentsChange={(newAssignments) =>
						setAssignmentsWithChanges((prev) => {
							const newAssignmentsIds = newAssignments.map(
								(assignment) => assignment.id,
							);

							const filteredPrev = prev.filter(
								(assignment) => !newAssignmentsIds.includes(assignment.id),
							);

							return [...filteredPrev, ...newAssignments];
						})
					}
				/>
			</CollapsiblePanel>
		);
	}, [userAssignments]);

	const editShareholder = (
		<div className={styles['edit-shareholder']}>
			<form onSubmit={handleSubmit(onFormSubmitHandler)}>
				<Scrollbar className={styles.scrollbar}>
					<div className={styles.content}>
						{renderDetails()}

						{renderAssignments()}
					</div>
				</Scrollbar>
				{formActions}
			</form>
		</div>
	);

	const changesWereMade = useCallback(() => {
		const currentValues = getValues();

		if (id) {
			return (
				currentValues.firstName !== firstName ||
				currentValues.lastName !== lastName ||
				//currentValues.email !== email ||
				currentValues.title !== title ||
				assignmentsWithChanges.length
			);
		}

		return false;
	}, [getValues, id, firstName, lastName, title, assignmentsWithChanges.length]);

	const modals = (
		<ModalLeaveWarning
			open={warningModalOpen}
			setOpen={setWarningModalOpen}
			onConfirm={() => {
				setBrowserShowLeaveWarning(false);
				closeHandler();
			}}
		/>
	);

	useLeaveWarning(showBrowserLeaveWarning);

	useEffect(() => {
		if (changesWereMade()) setBrowserShowLeaveWarning(true);
	}, [changesWereMade]);

	useEffect(() => {
		if (!open) {
			reset({}, { keepValues: false });
			setBrowserShowLeaveWarning(false);
			setUserAssignments(null);
			setAssignmentsWithChanges([]);
			setError('');
		}
	}, [open, reset]);

	useEffect(() => {
		if (id) {
			handleWithTryCatch(
				async () => {
					const assignments = await dispatch(getAssignmentsForUser(id));
					setUserAssignments(assignments);
				},
				undefined,
				() => setUserAssignments([]),
			);
		}
	}, [dispatch, id]);

	return (
		<Drawer
			contentClassName={styles.drawer}
			open={open}
			title="Shareholder Details"
			onCloseClickHandler={() => {
				if (changesWereMade()) setWarningModalOpen(true);
				else closeHandler();
			}}
		>
			{open ? editShareholder : null}

			{modals}
		</Drawer>
	);
};

export default EditShareholder;
