import { ITableColumn } from '../../../primitives/table/ITable';

export const getBriefControlsColumns = () => {
	return [
		{
			header: 'ID',
			key: 'displayId',
			sorting: true,
			width: '13%',
		},
		{
			header: 'Control Name',
			key: 'name',
			sorting: true,
			width: '87%',
		},
	] as ITableColumn[];
};
