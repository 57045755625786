import { AssetTypeDisplayName } from '../../../../utils/helpers/constants';
import { ITableColumn } from '../../../primitives/table/ITable';

export const getBriefAssetsColumns = () => {
	return [
		{
			header: 'Asset Name',
			key: 'name',
			sorting: true,
			width: '35%',
		},
		{
			header: 'Asset Type',
			key: 'type',
			sorting: true,
			width: '65%',
			columnRender: (type: string) =>
				AssetTypeDisplayName[type as keyof typeof AssetTypeDisplayName],
		},
		
	] as ITableColumn[];
};
