import hippaImage  from '../../../assets/images/gs-updates/hippa.png';
import fedRampImage  from '../../../assets/images/gs-updates/fed-ramp.png';
import fipsImage  from '../../../assets/images/gs-updates/fips.png';

export const GSPosts = [
	{
		id: '1',
		label: 'framework',
		title: 'HIPAA Release',
		content: `
The U.S. Department of Health and Human Services, through its Office for Civil Rights (OCR) and the Substance Abuse and Mental Health Services Administration (SAMHSA), finalized modifications to the Confidentiality of Substance Use Disorder (SUD) Patient Records regulations at 42 CFR part 2 (“Part 2”), which protect the privacy of patients’ SUD treatment records. Specifically, today’s final rule increases coordination among providers treating patients for SUDs, strengthens confidentiality protections through civil enforcement, and enhances the integration of behavioral health information with other medical records to improve patient health outcomes.

February 8, 2024 rule was informed by the bipartisan Coronavirus Aid, Relief, and Economic Security Act (CARES Act) that, among other things, required HHS to bring the Part 2 program into closer alignment with the Health Insurance Portability and Accountability Act of 1996 (HIPAA) Privacy, Breach Notification, and Enforcement Rules.`,
		image: hippaImage,
	},
	{
		id: '2',
		label: 'security',
		title: 'Transition to FIPS 140-3',
		content: `
On March 22, 2019, the Secretary of Commerce approved Federal Information Processing Standards Publication (FIPS) 140-3, Security Requirements for Cryptographic Modules, which supersedes FIPS 140-2. This was announced in the Federal Register on May 1, 2019 and became effective September 22, 2019.

The new standard also introduces some significant changes. Rather than directly encompassing the module requirements, FIPS 140-3 references ISO/IEC 19790:2012. The testing for these requirements will be in accordance with ISO/IEC 24759:2017. While there are few major technical requirement changes, using the ISO documents requires several procedural changes in the management and execution of the validation program and process.

FIPS 140-2 modules can remain active for 5 years after validation or until September 21, 2026, when the FIPS 140-2 validations will be moved to the historical list.`,
		image: fipsImage,
	},
	{
		id: '3',
		label: 'program',
		title: 'FedRAMP Roadmap',
		content: `
March 28, 2024, the FedRAMP program is releasing a roadmap to convey its strategic goals and how the program is prioritizing its work in the near term to drive progress against it.

In recent years, there has been a significant public focus on modernizing FedRAMP. In 2022, Congress passed a new law codifying the program. In 2023, the White House Office of Management and Budget (OMB) released a draft policy memorandum proposing significant program operations and governance changes. Broadly, these changes have been intended to keep what’s worked well about FedRAMP while creating a runway for the program to adapt to change, now and into the future.`,
		image: fedRampImage,
	},
];

export const LabelColors = {
	frameworkBorder: '#E298FD',
	frameworkBg: '#F1CCFE',
	securityBorder: '#FF8566',
	securityBg: '#FFC3B4',
	programBorder: '#93C2FD',
	programBg: '#cae1fe',
};
