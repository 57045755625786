import { FC } from 'react';
import ITagIncludedPolicies from './ITagIncludedPolicies';
import ManagePoliciesTable from '../tables/manage-policies-table/ManagePoliciesTable';

const TagIncludedPolicies: FC<ITagIncludedPolicies> = ({
	className,
	includedPolicyIds,
	setPolicyIds,
}) => {
	return (
		<div className={className}>
			<ManagePoliciesTable
				bodyMaxHeight={160}
				data={includedPolicyIds}
				entityPolicyIds={includedPolicyIds}
				setEntityPolicyIds={setPolicyIds}
			/>
		</div>
	);
};

export default TagIncludedPolicies;
