import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonResponseError } from '../../../utils/helpers/errors/CommonResponseError';
import { RootState } from '..';
import {
	dateInMDYFormat,
	sortBaseControlObjByIdAsc,
	sortByNameAndThenByIdAsc,
	sortByNameAsc,
} from '../../../utils/helpers/common';
import { IIdName } from '../../../utils/types';
import tagsApi from '../../api/tags.api';

export interface ITagsSlice {
	loading?: boolean;
	tagLoading?: boolean;
	updateLoading?: boolean;
}

export interface ITagCommonEntity {
	id: number;
	name: string;
	type?: string;
	controlId?: string;
}

export interface ITagBasic {
	id: number;
	name: string;
	color: string;
	controls?: ITagCommonEntity[];
	policies?: ITagCommonEntity[];
	assets: ITagCommonEntity[];
	createdAt: Date;
	updatedAt: Date;
}

export interface ITag {
	id: string;
	name: string;
	color: string;
	createdAt: string;
	updatedAt: string;
	assignedControls: IIdName[];
	assignedPolicies: IIdName[];
	assignedAssets: IIdName[];
}

export interface ITagBrief {
	id: string;
	name: string;
	color: string;
}

export interface ICreateTag {
	name: string;
	color: string;
	controls: string[];
	policies: string[];
	assets: string[];
}

export interface IUpdateTag {
	name: string;
	color: string;
	controls: IIdName[];
	policies: IIdName[];
	assets: IIdName[];
}

export interface ITags extends ITagsSlice {
	items: ITag[] | null;
}

const initialState: ITags = {
	loading: false,
	tagLoading: false,
	updateLoading: false,
	items: null,
};

export const tagsSlice = createSlice({
	name: 'tags',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		tagLoading: (state) => {
			state.tagLoading = true;
		},
		tagLoaded: (state) => {
			state.tagLoading = false;
		},
		updateLoading: (state) => {
			state.updateLoading = true;
		},
		updateLoaded: (state) => {
			state.updateLoading = false;
		},
		setTags: (state, { payload }: PayloadAction<ITag[]>) => {
			state.items = payload;
		},
	},
});

export const getTags = () => async (dispatch: Dispatch<any>, getState: () => RootState) => {
	dispatch(loading());

	try {
		const companyInfo = getState().company.info;

		const tags = await tagsApi.getTags();

		const transformedTags = tags.map(
			(tag) =>
				({
					id: tag.id.toString(),
					name: tag.name,
					color: tag.color,
					createdAt: dateInMDYFormat(tag.createdAt, companyInfo?.locationTimeZone),
					updatedAt: tag.updatedAt
						? dateInMDYFormat(tag.updatedAt, companyInfo?.locationTimeZone)
						: dateInMDYFormat(tag.createdAt, companyInfo?.locationTimeZone),
					assignedControls: sortBaseControlObjByIdAsc(
						tag.controls?.map(
							(control) =>
								({
									id: control.id.toString(),
									displayId: control.controlId,
									name: control.name,
								}) as IIdName,
						) || [],
					),
					assignedPolicies: sortByNameAndThenByIdAsc(
						tag.policies?.map((policy) => ({
							id: policy.id.toString(),
							name: policy.name,
						})) || [],
					),
					assignedAssets: sortByNameAndThenByIdAsc(
						tag.assets?.map((asset) => ({
							id: asset.id.toString(),
							name: asset.name,
							type: asset.type,
						})) || [],
					),
				}) as ITag,
		);

		await dispatch(setTags(sortByNameAsc(transformedTags)));

		return transformedTags;
	} catch (error: any) {
		await dispatch(setTags([]));
		return [];
	} finally {
		dispatch(loaded());
	}
};

export const getLastUpdatedTags =
	() => async (dispatch: Dispatch<any>, getState: () => RootState) => {
		dispatch(loading());

		try {
			const companyInfo = getState().company.info;

			const tags = await tagsApi.getLastUpdated();

			const transformedTags = tags.map(
				(tag) =>
					({
						id: tag.id.toString(),
						name: tag.name,
						color: tag.color,
						createdAt: dateInMDYFormat(tag.createdAt, companyInfo?.locationTimeZone),
						updatedAt: tag.updatedAt
							? dateInMDYFormat(tag.updatedAt, companyInfo?.locationTimeZone)
							: dateInMDYFormat(tag.createdAt, companyInfo?.locationTimeZone),
						assignedControls: sortBaseControlObjByIdAsc(
							tag.controls?.map(
								(control) =>
									({
										id: control.id.toString(),
										displayId: control.controlId,
										name: control.name,
									}) as IIdName,
							) || [],
						),
						assignedPolicies: sortByNameAndThenByIdAsc(
							tag.policies?.map((policy) => ({
								id: policy.id.toString(),
								name: policy.name,
							})) || [],
						),
						assignedAssets: sortByNameAndThenByIdAsc(
							tag.assets?.map((asset) => ({
								id: asset.id.toString(),
								name: asset.name,
								type: asset.type,
							})) || [],
						),
					}) as ITag,
			);

			return transformedTags;
		} catch (error: any) {
			return [];
		} finally {
			dispatch(loaded());
		}
	};

export const getTagById = (id: string) => async (dispatch: Dispatch<any>) => {
	dispatch(tagLoading());

	try {
		const tag = await tagsApi.getTagById(id);

		if (tag) {
			const transformedTag = {
				id: tag.id.toString(),
				name: tag.name,
				color: tag.color,
				assignedControls: sortBaseControlObjByIdAsc(
					tag.controls?.map(
						(control) =>
							({
								id: control.id.toString(),
								displayId: control.controlId,
								name: control.name,
							}) as IIdName,
					) || [],
				),
				assignedPolicies: sortByNameAndThenByIdAsc(
					tag.policies?.map((policy) => ({
						id: policy.id.toString(),
						name: policy.name,
					})) || [],
				),
				assignedAssets: sortByNameAndThenByIdAsc(
					tag.assets?.map((asset) => ({
						id: asset.id.toString(),
						name: asset.name,
						type: asset.type,
					})) || [],
				),
			};

			return transformedTag;
		}

		return null;
	} catch (error: any) {
		throw new CommonResponseError('Error while getting tag.');
	} finally {
		dispatch(tagLoaded());
	}
};

export const updateTag = (tagId: string, data: IUpdateTag) => async (dispatch: Dispatch<any>) => {
	dispatch(updateLoading());

	try {
		await tagsApi.updateTag(tagId, data);

		await dispatch(getTags());
	} catch (error: any) {
		if (
			error &&
			error.message &&
			(error.message.includes('already exists') || error.message.includes('duplicate'))
		) {
			throw new CommonResponseError('Such tag name already exists.');
		}

		throw new CommonResponseError('Error while updating tag.');
	} finally {
		dispatch(updateLoaded());
	}
};

export const createTag = (data: ICreateTag) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		const createdTag = await tagsApi.createTag(data);

		await dispatch(getTags());

		return createdTag?.id?.toString();
	} catch (error: any) {
		if (error && typeof error === 'string' && error.includes('already exists'))
			throw new CommonResponseError('Such tag name already exists.');

		throw new CommonResponseError('Error while creating tag.');
	} finally {
		dispatch(loaded());
	}
};

export const deleteTag = (tagId: string) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		await tagsApi.deleteTag(tagId);

		await dispatch(getTags());
	} catch (error: any) {
		throw new CommonResponseError('Error while deleting tag.');
	} finally {
		dispatch(loaded());
	}
};

export const bulkDeleteTags = (tagIds: string[]) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		await tagsApi.bulkDeleteTags(tagIds.map((tagId) => Number(tagId)));

		await dispatch(getTags());
	} catch (error: any) {
		throw new CommonResponseError('Error while deleting tags.');
	} finally {
		dispatch(loaded());
	}
};

export const { loading, loaded, updateLoading, updateLoaded, tagLoading, tagLoaded, setTags } =
	tagsSlice.actions;
export default tagsSlice.reducer;
