import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ISortProps, CommonResultStatusType, IKeyValuePair } from '../../../../utils/types';
import Table from '../../../primitives/table/Table';
import { processTableData } from '../../../../utils/helpers/common';
import TableFilters from '../../../primitives/table-filters/TableFilters';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import styles from './TagsTable.module.scss';
import { getTagColumns } from './utils';
import usePagination from '../../../../utils/helpers/hooks/usePagination';
import { Alert } from '../../../';
import useDevice from '../../../../utils/helpers/hooks/useDevice/useDevice';
import TableSkeleton from '../../../primitives/table/TableSkeleton';
import classNames from 'classnames';
import SearchFilter from '../../../primitives/filters/search-filter/SearchFilter';
import IPartialTable from '../common/IPartialTable';
import EditTag from '../../edit-panels/tag/EditTag';
import useTags from '../../../../utils/helpers/hooks/useTags';
import { Button, ActionButton } from '../../../primitives';
import ModalCreateTag from '../../modals/modal-create-tag/ModalCreateTag';
import HoverTooltip from '../../../primitives/tooltip/HoverTooltip';
import ModalDeleteTag from '../../modals/modal-delete-tag/ModalDeleteTag';
import { useNavigate } from 'react-router-dom';
import { AppUrls } from '../../../../utils/helpers/constants/app-routes';
import { AppQueryParams } from '../../../../utils/helpers/constants';
import { ITag } from '../../../../services/store/slices/tags.slice';

const TagsTable: FC<IPartialTable<ITag>> = ({ data, dataLoading, showItemId }) => {
	const device = useDevice();
	const navigate = useNavigate();
	const { items: tagItems } = useTags();

	const [processedData, setProcessedData] = useState(data);
	const [currentPageData, setCurrentPageData] = useState<ITag[]>([]);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [tagUpdateResult, setTagUpdateResult] = useState<CommonResultStatusType>('');
	const [isProcessing, setIsProcessing] = useState(true);
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [tagCreateResult, setTagCreateResult] = useState<
		CommonResultStatusType | 'dublicate-error'
	>('');
	const [createdTag, setCreatedTag] = useState('');
	const [tagsToDelete, setTagsToDelete] = useState<string[]>([]);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [deleteResult, setDeleteResult] = useState<CommonResultStatusType>('');
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const [showDeleteBtn, setShowDeleteBtn] = useState(false);
	const [updatedTagId, setUpdatedTagId] = useState<string | null>(null);

	const {
		paginationSection,
		setCurrentPage,
		pagesCount,
		goToPageByDataEntryProperty: goToPageByDataEntryId,
	} = usePagination(processedData, setCurrentPageData, 8);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const [currentFilters, setCurrentFilters] = useState<IKeyValuePair>({
		search: (dataRecord: ITag) => true,
	});

	const tableColumnsConfig = useMemo(() => getTagColumns(device), [device]);

	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const onRowClickHandler = useCallback(
		(id: string) => navigate(`${AppUrls.tags}?${AppQueryParams.item}=${id}`),
		[navigate],
	);

	const onTagUpdateSuccess = () => {
		setUpdatedTagId(showItemId || '');
		setTagUpdateResult('success');
		setOpenDrawer(false);
		navigate(`${AppUrls.tags}`);
	};
	const onTagUpdateError = () => setTagUpdateResult('error');

	const alerts = (
		<>
			<Alert
				uniqueKey={'tag-update-success'}
				show={tagUpdateResult === 'success'}
				type="success"
				message="Tag updated!"
				clearActionStatus={() => setTagUpdateResult('')}
			/>
			<Alert
				uniqueKey={'tag-update-error'}
				show={tagUpdateResult === 'error'}
				type="error"
				message="Error occurred while updating tag"
				clearActionStatus={() => setTagUpdateResult('')}
			/>
			<Alert
				uniqueKey={'tag-created-success'}
				show={tagCreateResult === 'success'}
				type="success"
				message="Tag created!"
				clearActionStatus={() => setTagCreateResult('')}
			/>
			<Alert
				uniqueKey={'tag-created-error'}
				show={tagCreateResult === 'error' || tagCreateResult === 'dublicate-error'}
				type="error"
				message={
					tagCreateResult === 'error'
						? 'Error occurred while creating tag'
						: 'Such tag name already exists.'
				}
				clearActionStatus={() => setTagCreateResult('')}
			/>
			<Alert
				uniqueKey={'tag-delete-success'}
				show={deleteResult === 'success'}
				type="success"
				message="Tag(s) deleted!"
				clearActionStatus={() => setDeleteResult('')}
			/>
			<Alert
				uniqueKey={'tag-delete-error'}
				show={deleteResult === 'error'}
				type="error"
				message="Error occurred while deleting tag(s). Please try again."
				clearActionStatus={() => setDeleteResult('')}
			/>
		</>
	);

	const renderModals = () => {
		const foundedTagNames = tagsToDelete.map(
			(id) => tagItems?.find((tag) => tag.id === id)?.name || '',
		);

		return (
			<>
				<ModalDeleteTag
					open={deleteModalOpen}
					setOpen={setDeleteModalOpen}
					ids={tagsToDelete}
					names={foundedTagNames}
					onDeleteSuccess={() => setDeleteResult('success')}
					onDeleteError={() => setDeleteResult('error')}
				/>

				<ModalCreateTag
					open={createModalOpen}
					setOpen={setCreateModalOpen}
					onCreateSuccess={(id: string) => {
						setCreatedTag(id);
						setTagCreateResult('success');
					}}
					onCreateError={(nameAlreadyExists) =>
						nameAlreadyExists
							? setTagCreateResult('dublicate-error')
							: setTagCreateResult('error')
					}
				/>
			</>
		);
	};

	const editDrawer = (
		<EditTag
			tagId={showItemId || ''}
			onUpdateSuccess={onTagUpdateSuccess}
			onUpdateError={onTagUpdateError}
			open={openDrawer}
			onDeleteSuccess={() => setDeleteResult('success')}
			onDeleteError={() => setDeleteResult('error')}
			closeHandler={() => {
				setOpenDrawer(false);
				navigate(`${AppUrls.tags}`);
			}}
		/>
	);

	const tableFilters = useMemo(() => {
		const leftSection = (
			<div
				className={classNames(
					styles.left,
					!dataLoading && !data.length ? styles.disabled : '',
				)}
			>
				<SearchFilter
					placeholder="Search tag..."
					setFilters={setCurrentFilters}
					properties={['name']}
				/>

				{showDeleteBtn ? (
					<Button
						className={styles.delete}
						negative
						width={157}
						onClick={() => setDeleteModalOpen(true)}
					>
						Delete Selected
					</Button>
				) : null}
			</div>
		);

		const rightSection = (
			<Button
				className={styles.add}
				negative
				width={111}
				onClick={() => setCreateModalOpen(true)}
			>
				Add New
			</Button>
		);

		return (
			<TableFilters
				disabled={dataLoading || isProcessing}
				leftSection={leftSection}
				rightSection={rightSection}
			/>
		);
	}, [data.length, dataLoading, isProcessing, showDeleteBtn]);

	const mainTable = useMemo(() => {
		const highlightedRows = [createdTag || updatedTagId || ''];

		return (
			<Table
				tableKey="tags"
				data={currentPageData}
				columns={tableColumnsConfig}
				options={{
					selectedRows,
					setSelectedRows,
					rowCheckbox: true,
					rowActions: [
						{
							actionComponent: (
								<ActionButton
									tooltipId={'tooltip-delete-tag'}
									tooltipContent={'Delete Tag'}
									type="cancel"
								/>
							),
							actionHandler: (id: string) => {
								setTagsToDelete([id]);
								setDeleteModalOpen(true);
							},
						},
					],
					successHighlightedRows: highlightedRows,
					onSortChange,
					onRowClickHandler,
					emptyResultMessage:
						!dataLoading && !data.length
							? 'No tags to display yet.'
							: 'No matches found. Please try another search query.',
					emptyResultMessageType: !dataLoading && !data.length ? 'common' : 'search',
				}}
			/>
		);
	}, [
		createdTag,
		currentPageData,
		data.length,
		dataLoading,
		onRowClickHandler,
		onSortChange,
		selectedRows,
		tableColumnsConfig,
		updatedTagId,
	]);

	useEffect(() => {
		setShowDeleteBtn(!!selectedRows.length);
	}, [selectedRows.length]);

	useEffect(() => {
		if (createdTag) {
			goToPageByDataEntryId(createdTag, 'id');

			const addDelay = setTimeout(() => {
				setCreatedTag('');
				clearTimeout(addDelay);
			}, 2000);
		}
	}, [createdTag, goToPageByDataEntryId]);

	useEffect(() => {
		if (updatedTagId) {
			goToPageByDataEntryId(updatedTagId, 'id');

			const addDelay = setTimeout(() => {
				setUpdatedTagId(null);
				clearTimeout(addDelay);
			}, 2000);
		}
	}, [data, goToPageByDataEntryId, updatedTagId]);

	useEffect(() => {
		setIsProcessing(true);
		setCurrentPage(1);

		const proccessedData: ITag[] = processTableData(data, currentFilters, currentSort);

		setProcessedData(proccessedData);
		setIsProcessing(false);
	}, [currentFilters, currentSort, data, setCurrentPage]);

	useEffect(() => {
		setOpenDrawer(!!showItemId);
	}, [showItemId]);

	useEffect(() => {
		if (!deleteModalOpen) setSelectedRows([]);
	}, [deleteModalOpen]);

	useEffect(() => {
		if (selectedRows) setTagsToDelete(selectedRows);
	}, [selectedRows]);

	return (
		<>
			<BasicPanel
				className={classNames(styles['basic-panel'], isProcessing ? styles.disabled : '')}
			>
				{tableFilters}

				{isProcessing || dataLoading ? (
					<TableSkeleton rowsNumber={4} columns={tableColumnsConfig} />
				) : (
					mainTable
				)}
			</BasicPanel>

			{pagesCount > 1 && !dataLoading ? paginationSection : null}

			<HoverTooltip tooltipId="tooltip-delete-tag" place="top-end" />

			{editDrawer}

			{renderModals()}

			{alerts}
		</>
	);
};

export default TagsTable;
