import axios from 'axios';
import { CROSSWALK_EVALUATION, FRAMEWORKS } from './api.path';
import { ICrosswalkEvaluationResult, IFrameworkBasic } from '../store/slices/frameworks.slice';

const frameworksApi = {
	getFrameworks: (): Promise<IFrameworkBasic[]> => {
		return axios.get(`${FRAMEWORKS}`);
	},
	makeCrosswalkEvaluation: (
		idFrom: string,
		idTo: string,
	): Promise<ICrosswalkEvaluationResult> => {
		return axios.get(`${CROSSWALK_EVALUATION}/compare?idFrom=${idFrom}&idTo=${idTo}`);
	},
	getPrimaryFrameworkReport: (): Promise<ICrosswalkEvaluationResult> => {
		return axios.get(`${CROSSWALK_EVALUATION}/primary`);
	},
};

export default frameworksApi;
