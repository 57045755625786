import { useEffect } from 'react';
import useUser from './useUser';
import { useAppDispatch } from '../../../services/store';
import { handleWithTryCatch } from '../errors';
import { getArchivedAssets, getAssets } from '../../../services/store/slices/assets.slice';
import useCompany from './useCompany';
import useCompanyUsers from './useCompanyUsers';
import { getControls } from '../../../services/store/slices/controls.slice';

export default function useGetAssets(withLoading: boolean = true) {
	const dispatch = useAppDispatch();
	const { authorized, info: userInfo, isAdmin } = useUser();
	const { info: companyInfo } = useCompany();
	const { items: companyUsers } = useCompanyUsers();

	useEffect(() => {
		if (authorized && userInfo?.id && companyInfo?.id && companyUsers) {
			(async () => {
				await handleWithTryCatch(async () => {
					// we need to have fresh controls data, mainly for tags
					await handleWithTryCatch(async () => await dispatch(getControls(true)));

					await dispatch(getAssets(!isAdmin ? userInfo?.id : '', withLoading));
					await dispatch(getArchivedAssets(!isAdmin ? userInfo?.id : ''));
				});
			})();
		}
	}, [authorized, companyInfo?.id, companyUsers, dispatch, isAdmin, userInfo?.id, withLoading]);
}
