import { FC } from 'react';
import ITagIncludedControls from './ITagIncludedControls';
import ManageControlsTable from '../tables/manage-controls-table/ManageControlsTable';

const TagIncludedControls: FC<ITagIncludedControls> = ({
	className,
	includedControlIds,
	setControlIds,
}) => {
	return (
		<div className={className}>
			<ManageControlsTable
				bodyMaxHeight={160}
				data={includedControlIds}
				entityControlIds={includedControlIds}
				setEntityControlIds={setControlIds}
			/>
		</div>
	);
};

export default TagIncludedControls;
