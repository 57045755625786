import axios from 'axios';
import { TAGS } from './api.path';
import { ICreateTag, ITagBasic, IUpdateTag } from '../store/slices/tags.slice';

const tagsApi = {
	getTags: (): Promise<ITagBasic[]> => {
		return axios.get(`${TAGS}`);
	},
	getLastUpdated: (): Promise<ITagBasic[]> => {
		return axios.get(`${TAGS}/last-updated?limit=3`);
	},
	getTagById: (id: string): Promise<ITagBasic> => {
		return axios.get(`${TAGS}/${id}`);
	},
	updateTag: (id: string, data: IUpdateTag) => {
		return axios.put(`${TAGS}/${id}`, data);
	},
	createTag: (data: ICreateTag): Promise<ITagBasic> => {
		return axios.post(`${TAGS}`, data);
	},
	deleteTag: (id: string) => {
		return axios.delete(`${TAGS}/${id}`);
	},
	bulkDeleteTags: (ids: number[]) => {
		return axios.delete(`${TAGS}/bulk-delete`, { data: { ids } });
	},
};

export default tagsApi;
