import { useCallback, useState } from 'react';
import axios from 'axios';
import { CommonResultStatusType } from '../../types';
import fileDownload from 'js-file-download';

export const useFileDownload = () => {
	const [progress, setProgress] = useState(0);
	const [downloadResult, setDownloadResult] = useState<CommonResultStatusType>('');

	const downloadFileByUrl = useCallback(async (url: string, filename: string) => {
		const downloadResult = await axios.get(url, {
			responseType: 'blob',
			onDownloadProgress: (progressEvent: any) => {
				let percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total,
				);
				setProgress(percentCompleted);
			},
		});

		//@ts-ignore
		fileDownload(downloadResult, filename);
		setDownloadResult('success');
	}, []);

	return {
		progress,
		setProgress,
		downloadResult,
		setDownloadResult,
		downloadFileByUrl,
	};
};
