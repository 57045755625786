import React, { FC } from 'react';
import styles from './Profile.module.scss';
import LoadingSkeleton from '../../components/primitives/loading-skeleton/LoadingSkeleton';
import DataList from '../../components/primitives/data-list/DataList';
import DataListItem from '../../components/primitives/data-list-item/DataListItem';

const ProfileSkeleton: FC = () => {
	const userInfo = (
		<DataList className={styles['user-info']}>
			<DataListItem
				label="First Name"
				element={<LoadingSkeleton containerClassName={styles.skeleton} height={20} />}
			/>
			<DataListItem
				label="Last Name"
				element={<LoadingSkeleton containerClassName={styles.skeleton} height={20} />}
			/>
			<DataListItem
				label="Email"
				element={<LoadingSkeleton containerClassName={styles.skeleton} height={20} />}
			/>
			<DataListItem
				label="Title"
				element={<LoadingSkeleton containerClassName={styles.skeleton} height={20} />}
			/>
			<DataListItem
				label="Role"
				element={<LoadingSkeleton containerClassName={styles.skeleton} height={20} />}
			/>
		</DataList>
	);

	return <div className={styles['profile-skeleton']}>{userInfo}</div>;
};

export default ProfileSkeleton;
