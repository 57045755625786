import { FC } from 'react';
import styles from './ModalLeaveWarning.module.scss';
import Modal from '../../../primitives/modal/Modal';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import IModalLeaveWarning from './IModalLeaveWarning';
import { ActionsBlock, Button } from '../../../primitives';

const ModalLeaveWarning: FC<IModalLeaveWarning> = ({ open, setOpen, onConfirm }) => {
	const onConfirmHandler = () => {
		setOpen(false);
		onConfirm();
	};

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<h2 className={styles.title}>Discard changes?</h2>

				<p className={styles.text}>You made changes to this page. Are you sure you want to discard them?</p>

				<ActionsBlock>
					<Button type="button" width={147} onClick={() => setOpen(false)}>
						Cancel
					</Button>

					<Button type="button" width={147} negative onClick={onConfirmHandler}>
						Yes, discard
					</Button>
				</ActionsBlock>
			</BasicPanel>
		</Modal>
	);
};

export default ModalLeaveWarning;
