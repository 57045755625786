import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Table from '../../../primitives/table/Table';
import { getBriefAssetsColumns } from './utils';
import styles from './ManageAssetsTable.module.scss';
import { IIdName, IKeyValuePair, ISortProps } from '../../../../utils/types';
import { processTableData } from '../../../../utils/helpers/common';
import classNames from 'classnames';
import SearchFilter from '../../../primitives/filters/search-filter/SearchFilter';
import { Button, ActionButton } from '../../../primitives';
import TableFilters from '../../../primitives/table-filters/TableFilters';
import HoverTooltip from '../../../primitives/tooltip/HoverTooltip';
import IManageAssetsTable from './IManageAssetsTable';
import { IAsset } from '../../../../services/store/slices/assets.slice';
import ModalAddAssets from '../../modals/modal-add-assets/ModalAddAssets';

const ManageAssetsTable: FC<IManageAssetsTable> = ({
	className,
	data,
	bodyMaxHeight = 360,
	entityAssetIds,
	setEntityAssetIds,
}) => {
	const [dataToDisplay, setDataToDisplay] = useState<IAsset[]>([]);
	const [highlightedAssets, setHighlightedAssets] = useState<string[]>([]);

	const [addAssetsModalOpen, setAddAssetsModalOpen] = useState(false);

	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const [showDeleteBtn, setShowDeleteBtn] = useState(false);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const [currentFilters, setCurrentFilters] = useState<IKeyValuePair>({
		search: (dataRecord: IAsset) => true,
	});

	const modals = (
		<ModalAddAssets
			open={addAssetsModalOpen}
			setOpen={setAddAssetsModalOpen}
			entityAssetIds={entityAssetIds}
			setEntityAssets={setEntityAssetIds}
			onNewAdded={(newItems: IIdName[]) => {
				setHighlightedAssets(newItems.map((item) => item.id));
			}}
		/>
	);

	const tableFilters = useMemo(() => {
		const onRemoveClick = () => {
			setEntityAssetIds(entityAssetIds.filter((asset) => !selectedRows.includes(asset.id)));
			setSelectedRows([]);
		};

		const leftSection = (
			<>
				<SearchFilter
					placeholder="Search asset..."
					className={styles.search}
					properties={['name', 'type']}
					setFilters={setCurrentFilters}
					disabled={!data.length}
				/>

				{showDeleteBtn ? (
					<Button
						type="button"
						className={styles.remove}
						negative
						width={157}
						onClick={onRemoveClick}
					>
						Remove Selected
					</Button>
				) : null}
			</>
		);

		const rightSection = (
			<>
				<Button
					className={styles.add}
					negative
					width={105}
					type="button"
					onClick={() => setAddAssetsModalOpen(true)}
				>
					Add Assets
				</Button>
			</>
		);

		return (
			<TableFilters
				className={styles.filters}
				leftSection={data.length ? leftSection : null}
				rightSection={rightSection}
			/>
		);
	}, [data, entityAssetIds, selectedRows, setEntityAssetIds, showDeleteBtn]);

	const rowActions = useMemo(() => {
		return [
			{
				actionComponent: (
					<ActionButton
						className={styles['action-button']}
						tooltipId={'tooltip-remove-asset'}
						tooltipContent={'Remove from this tag'}
						type="cancel"
					/>
				),
				actionHandler: (id: string) => {
					setEntityAssetIds(entityAssetIds.filter((item) => item.id !== id));
				},
			},
		];
	}, [entityAssetIds, setEntityAssetIds]);

	const mainTable = (
		<Table
			tableKey="manage-assets"
			data={dataToDisplay}
			columns={getBriefAssetsColumns()}
			options={{
				rowActions,
				rowCheckbox: true,
				rowClassName: styles.row,
				onSortChange,
				emptyResultMessage: !data.length
					? 'No assets to display.'
					: 'No matches found. Please try another search query.',
				emptyResultMessageType: !data.length ? 'common' : 'search',
				bodyMaxHeight,
				small: true,
				scrollFullRightPosition: true,
				selectedRows,
				setSelectedRows,
				successHighlightedRows: highlightedAssets,
			}}
		/>
	);

	useEffect(() => {
		if (data.length) {
			const proccessedData: IAsset[] = processTableData(data, currentFilters, currentSort);

			setDataToDisplay(proccessedData);
		}
	}, [currentFilters, currentSort, data]);

	useEffect(() => {
		setShowDeleteBtn(!!selectedRows.length);
	}, [selectedRows.length]);

	useEffect(() => {
		if (!highlightedAssets.length) return;

		const delay = setTimeout(() => {
			setHighlightedAssets([]);
			clearTimeout(delay);
		}, 2000);
	}, [highlightedAssets]);

	return (
		<div className={classNames(styles['table-wrap'], className)}>
			{!data.length ? (
				<p className={styles.empty}>No assets are included in this tag yet.</p>
			) : null}

			{tableFilters}

			{data.length ? (
				<>
					{mainTable}

					<HoverTooltip tooltipId="tooltip-remove-asset" place="top-end" />
				</>
			) : null}

			{modals}
		</div>
	);
};

export default ManageAssetsTable;
