import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ISortProps, IKeyValuePair, CommonResultStatusType } from '../../../../../utils/types';
import Table from '../../../../primitives/table/Table';
import TableFilters from '../../../../primitives/table-filters/TableFilters';
import BasicPanel from '../../../../primitives/basic-panel/BasicPanel';
import styles from './GSPoliciesTable.module.scss';
import { getGSPolicyColumns } from './utils';
import usePagination from '../../../../../utils/helpers/hooks/usePagination';
import {
	IBasePolicy,
	addBasePolicyToCompany,
} from '../../../../../services/store/slices/policies.slice';
import useDevice from '../../../../../utils/helpers/hooks/useDevice/useDevice';
import TableSkeleton from '../../../../primitives/table/TableSkeleton';
import classNames from 'classnames';
import IPartialTable from '../../common/IPartialTable';
import SearchFilter from '../../../../primitives/filters/search-filter/SearchFilter';
import ControlsFilter from '../../../../primitives/filters/controls-filter/ControlsFilter';
import { getBaseControlOptions, processTableData } from '../../../../../utils/helpers/common';
import Drawer from '../../../../primitives/drawer/Drawer';
import { ActionButton } from '../../../../primitives';
import HoverTooltip from '../../../../primitives/tooltip/HoverTooltip';
import useUser from '../../../../../utils/helpers/hooks/useUser';
import GSPolicyDetails from '../../../edit-panels/policy/gs-details/GSPolicyDetails';
import usePolicies from '../../../../../utils/helpers/hooks/usePolicies';
import { useAppDispatch } from '../../../../../services/store';
import { handleWithTryCatch } from '../../../../../utils/helpers/errors';
import { Alert } from '../../../../';
import Loader from '../../../../primitives/loader/Loader';
import { AppUrls } from '../../../../../utils/helpers/constants/app-routes';
import { AppQueryParams } from '../../../../../utils/helpers/constants';
import { useNavigate } from 'react-router-dom';

const GSPoliciesTable: FC<
	IPartialTable<IBasePolicy> & { onBasePolicyCopied: (newPolicyId: string) => void }
> = ({ data, dataLoading, showItemId, onBasePolicyCopied }) => {
	const device = useDevice();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { baseControls } = usePolicies();
	const { isAdmin } = useUser();

	const [processedData, setProcessedData] = useState(data);
	const [currentPageData, setCurrentPageData] = useState<IBasePolicy[]>([]);
	const [isProcessing, setIsProcessing] = useState(true);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [copyResult, setCopyResult] = useState<CommonResultStatusType>('');
	const [copyLoading, setCopyLoading] = useState(false);
	const [policyToCopy, setPolicyToCopy] = useState<string | null>(null);

	const { paginationSection, setCurrentPage, pagesCount, goToPageByDataEntryProperty } =
		usePagination(processedData, setCurrentPageData, 8);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const [currentFilters, setCurrentFilters] = useState<IKeyValuePair>({
		search: (dataRecord: IBasePolicy) => true,
		frameworkFilter: (dataRecord: IBasePolicy) => true,
		controlsFilter: (dataRecord: IBasePolicy) => true,
	});

	const tableColumnsConfig = useMemo(() => getGSPolicyColumns(device), [device]);

	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const onRowClickHandler = useCallback(
		(id: string) =>
			navigate(`${AppUrls.policies}?${AppQueryParams.item}=${id}&${AppQueryParams.type}=gs`),
		[navigate],
	);

	const onCopyBasePolicy = useCallback(
		async (basePolicyId: string) => {
			setCopyLoading(true);

			handleWithTryCatch(
				async () => {
					const newPolicy = await dispatch(addBasePolicyToCompany(basePolicyId));

					if (newPolicy?.id) onBasePolicyCopied(newPolicy.id.toString());
					setCopyLoading(false);
					setPolicyToCopy(null);
				},
				undefined,
				() => {
					setCopyResult('error');
					setCopyLoading(false);
					setPolicyToCopy(null);
				},
			);
		},
		[dispatch, onBasePolicyCopied],
	);

	const tableFilters = useMemo(() => {
		const leftSection = (
			<Fragment>
				<SearchFilter
					placeholder="Search policy..."
					setFilters={setCurrentFilters}
					properties={['name']}
				/>
				<ControlsFilter
					options={getBaseControlOptions(baseControls || [])}
					setFilters={setCurrentFilters}
					propertyName="baseControls"
				/>
			</Fragment>
		);

		return <TableFilters disabled={dataLoading || !data.length} leftSection={leftSection} />;
	}, [baseControls, data.length, dataLoading]);

	const renderRowActions = useMemo(() => {
		if (copyLoading) {
			return [
				{
					actionComponent: (
						<Loader className={styles.loader} thin maxHeight={15} maxWidth={15} />
					),
					showHandler: (id: string) => policyToCopy === id,
				},
			];
		}

		return [
			{
				actionComponent: (
					<ActionButton
						tooltipId={'tooltip-action'}
						tooltipContent={'Copy to Company Library'}
						type="copy"
					/>
				),
				actionHandler: (id: string) => {
					setPolicyToCopy(id);
					onCopyBasePolicy(id);
				},
			},
		];
	}, [copyLoading, onCopyBasePolicy, policyToCopy]);

	const mainTable = useMemo(
		() => (
			<Table
				tableKey="gs-policies"
				data={currentPageData}
				columns={tableColumnsConfig}
				options={{
					...(isAdmin && {
						rowActions: renderRowActions,
					}),
					actionsDisplayForRowsId: [policyToCopy || ''],
					bodyMaxHeight: 'calc(100vh - 370px)',
					onRowClickHandler,
					onSortChange,
					emptyResultMessage:
						!dataLoading && !data.length
							? `No policies to display yet.`
							: `No matches found. Please try another search query.`,
					emptyResultMessageType: !dataLoading && !data.length ? 'common' : 'search',
				}}
			/>
		),
		[
			currentPageData,
			data.length,
			dataLoading,
			isAdmin,
			onRowClickHandler,
			onSortChange,
			policyToCopy,
			renderRowActions,
			tableColumnsConfig,
		],
	);

	const detailsDrawer = (
		<Drawer
			title="Policy Details"
			open={openDrawer}
			onCloseClickHandler={() => {
				setOpenDrawer(false);
				navigate(`${AppUrls.policies}`);
			}}
		>
			{showItemId ? (
				<GSPolicyDetails policy={data.find((policy) => policy.id === showItemId)} />
			) : null}
		</Drawer>
	);

	const alerts = (
		<Alert
			uniqueKey={'copy-error'}
			show={copyResult === 'error'}
			type="error"
			message="Error while policy copy."
			clearActionStatus={() => setCopyResult('')}
		/>
	);

	useEffect(() => {
		setOpenDrawer(!!showItemId);
	}, [showItemId]);

	useEffect(() => {
		if (showItemId) goToPageByDataEntryProperty(showItemId, 'id');
	}, [goToPageByDataEntryProperty, showItemId]);

	useEffect(() => {
		setIsProcessing(true);
		setCurrentPage(1);

		const proccessedData: IBasePolicy[] = processTableData(data, currentFilters, currentSort);

		setProcessedData(proccessedData);
		setIsProcessing(false);
	}, [currentFilters, currentSort, data, setCurrentPage]);

	return (
		<>
			<BasicPanel
				className={classNames(styles['basic-panel'], isProcessing ? styles.disabled : '')}
			>
				{tableFilters}

				{isProcessing || dataLoading ? (
					<TableSkeleton columns={tableColumnsConfig} />
				) : (
					mainTable
				)}
			</BasicPanel>

			{pagesCount > 1 && !dataLoading ? paginationSection : null}

			{detailsDrawer}

			<HoverTooltip tooltipId="tooltip-action" place="top-end" />

			{alerts}
		</>
	);
};

export default GSPoliciesTable;
