import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Table from '../../../primitives/table/Table';
import { getBriefControlsColumns } from './utils';
import styles from './ManageControlsTable.module.scss';
import { IIdName, IKeyValuePair, ISortProps } from '../../../../utils/types';
import { processTableData } from '../../../../utils/helpers/common';
import classNames from 'classnames';
import SearchFilter from '../../../primitives/filters/search-filter/SearchFilter';
import IManageControlsTable from './IManageControlsTable';
import { Button, ActionButton } from '../../../primitives';
import TableFilters from '../../../primitives/table-filters/TableFilters';
import ModalAddControls from '../../modals/modal-add-controls/ModalAddControls';
import HoverTooltip from '../../../primitives/tooltip/HoverTooltip';

const ManageControlsTable: FC<IManageControlsTable> = ({
	className,
	data,
	bodyMaxHeight = 360,
	entityControlIds,
	setEntityControlIds,
}) => {
	const [dataToDisplay, setDataToDisplay] = useState<IIdName[]>([]);
	const [highlightedControls, setHighlightedControls] = useState<string[]>([]);

	const [addControlsModalOpen, setAddControlsModalOpen] = useState(false);

	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const [showDeleteBtn, setShowDeleteBtn] = useState(false);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const [currentFilters, setCurrentFilters] = useState<IKeyValuePair>({
		search: (dataRecord: IIdName) => true,
	});

	const modals = (
		<ModalAddControls
			open={addControlsModalOpen}
			setOpen={setAddControlsModalOpen}
			entityControlIds={entityControlIds}
			setEntityControls={setEntityControlIds}
			onNewAdded={(newItems: IIdName[]) => {
				setHighlightedControls(newItems.map((item) => item.id));
			}}
		/>
	);

	const tableFilters = useMemo(() => {
		const onRemoveClick = () => {
			setEntityControlIds(
				entityControlIds.filter((control) => !selectedRows.includes(control.id)),
			);
			setSelectedRows([]);
		};

		const leftSection = (
			<>
				<SearchFilter
					placeholder="Search control..."
					className={styles.search}
					properties={['id', 'name']}
					setFilters={setCurrentFilters}
					disabled={!data.length}
				/>

				{showDeleteBtn ? (
					<Button
						type="button"
						className={styles.remove}
						negative
						width={157}
						onClick={onRemoveClick}
					>
						Remove Selected
					</Button>
				) : null}
			</>
		);

		const rightSection = (
			<>
				<Button
					className={styles.add}
					negative
					width={110}
					type="button"
					onClick={() => setAddControlsModalOpen(true)}
				>
					Add Controls
				</Button>
			</>
		);

		return (
			<TableFilters
				className={styles.filters}
				leftSection={data.length ? leftSection : null}
				rightSection={rightSection}
			/>
		);
	}, [data.length, entityControlIds, selectedRows, setEntityControlIds, showDeleteBtn]);

	const rowActions = useMemo(() => {
		return [
			{
				actionComponent: (
					<ActionButton
						className={styles['action-button']}
						tooltipId={'tooltip-remove-control'}
						tooltipContent={'Remove from this tag'}
						type="cancel"
					/>
				),
				actionHandler: (id: string) => {
					setEntityControlIds(entityControlIds.filter((item) => item.id !== id));
				},
			},
		];
	}, [entityControlIds, setEntityControlIds]);

	const mainTable = (
		<Table
			tableKey="manage-controls"
			data={dataToDisplay}
			columns={getBriefControlsColumns()}
			options={{
				rowActions,
				rowCheckbox: true,
				rowClassName: styles.row,
				onSortChange,
				emptyResultMessage: !data.length
					? 'No controls to display.'
					: 'No matches found. Please try another search query.',
				emptyResultMessageType: !data.length ? 'common' : 'search',
				bodyMaxHeight,
				small: true,
				scrollFullRightPosition: true,
				selectedRows,
				setSelectedRows,
				successHighlightedRows: highlightedControls,
			}}
		/>
	);

	useEffect(() => {
		if (highlightedControls.length) {
			const delay = setTimeout(() => {
				setHighlightedControls([]);
				clearTimeout(delay);
			}, 2000);
		}
	}, [highlightedControls]);

	useEffect(() => {
		if (data.length) {
			const proccessedData: IIdName[] = processTableData(data, currentFilters, currentSort);

			setDataToDisplay(proccessedData);
		}
	}, [currentFilters, currentSort, data]);

	useEffect(() => {
		setShowDeleteBtn(!!selectedRows.length);
	}, [selectedRows.length]);

	return (
		<div className={classNames(styles['table-wrap'], className)}>
			{!data.length ? (
				<p className={styles.empty}>No controls are included in this tag yet.</p>
			) : null}

			{tableFilters}

			{data.length ? (
				<>
					{mainTable}

					<HoverTooltip tooltipId="tooltip-remove-control" place="top-end" />
				</>
			) : null}

			{modals}
		</div>
	);
};

export default ManageControlsTable;
