import { Dispatch } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { CommonResponseError } from '../../../utils/helpers/errors/CommonResponseError';
import assignmentsApi from '../../api/assignments.api';
import { RootState } from '..';
import { ICompanyUser } from './company-users.slice';
import { IIdName } from '../../../utils/types';
import { dateInMDYFormat, sortByDateAsc } from '../../../utils/helpers/common';
import { ASSIGNMENT_ADD_DAYS, UserTaskType } from '../../../utils/helpers/constants';
import { addDays } from 'date-fns';
import { assignAssets } from './assets.slice';
import { assignPolicies } from './policies.slice';
import { assignControls } from './controls.slice';

export interface IAssignmentsSlice {
	loading?: boolean;
}

export interface IAssignmentBasic {
	id: string;
	taskType: string;
	entity: any;
	ownerId?: string | null;
	createdAt: Date;
}

export interface IAssignment extends Omit<IAssignmentBasic, 'ownerId' | 'entity' | 'createdAt'> {
	owner?: ICompanyUser | null;
	dueDate: string;
	relatedItem: IIdName;
}

export interface IAssignments extends IAssignmentsSlice {}

const initialState: IAssignments = {
	loading: false,
};

export const assignmentsSlice = createSlice({
	name: 'assignments',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
	},
});

export const getAssignmentsForUser =
	(userId?: string) => async (dispatch: Dispatch<any>, getState: () => RootState) => {
		dispatch(loading());

		try {
			if (!userId) return [];

			const companyInfo = getState().company?.info;

			const resultData = await assignmentsApi.getAssignments(userId);

			const companyUsers = getState().companyUsers.items;
			const currentUser = companyUsers?.find((user) => user.id === userId);

			const transformedData = resultData.map((item) => {
				const relatedItem = item.entity;

				switch (item.taskType) {
					case UserTaskType.ControlOwner:
						return {
							id: item.id.toString(),
							taskType: item.taskType,
							relatedItem: {
								id: relatedItem?.id.toString() || '',
								displayId: relatedItem?.baseControl?.controlId || '',
								name: relatedItem?.baseControl?.controlId || '',
							},
							owner: currentUser,
							dueDate: dateInMDYFormat(
								addDays(item.createdAt, ASSIGNMENT_ADD_DAYS),
								companyInfo?.locationTimeZone,
							),
						};
					case UserTaskType.PolicyOwner:
					case UserTaskType.PolicyReview:
						return {
							id: item.id.toString(),
							taskType: item.taskType,
							relatedItem: {
								id: relatedItem?.id.toString() || '',
								name: relatedItem?.name || '',
							},
							owner: currentUser,
							dueDate: dateInMDYFormat(
								addDays(item.createdAt, ASSIGNMENT_ADD_DAYS),
								companyInfo?.locationTimeZone,
							),
						};
					case UserTaskType.AssetOwner:
						return {
							id: item.id.toString(),
							taskType: item.taskType,
							relatedItem: {
								id: relatedItem?.id.toString() || '',
								name: relatedItem?.name || '',
							},
							owner: currentUser,
							dueDate: dateInMDYFormat(
								addDays(item.createdAt, ASSIGNMENT_ADD_DAYS),
								companyInfo?.locationTimeZone,
							),
						};
					default:
						return {};
				}
			}) as IAssignment[];

			return sortByDateAsc(transformedData, 'dueDate');
		} catch (error: any) {
			throw new CommonResponseError('Error while getting assignments.');
		} finally {
			dispatch(loaded());
		}
	};

export const updateAssignments =
	(assignments: IAssignment[]) => async (dispatch: Dispatch<any>) => {
		dispatch(loading());

		try {
			if (assignments.length) {
				assignments.forEach(async (assignment) => {
					switch (assignment.taskType) {
						case UserTaskType.AssetOwner:
							await dispatch(
								assignAssets(assignment.owner?.id || '', [
									assignment.relatedItem.id,
								]),
							);

							break;

						case UserTaskType.ControlOwner:
							await dispatch(
								assignControls(assignment.owner?.id || '', [
									assignment.relatedItem.id || '',
								]),
							);
							break;

						case UserTaskType.PolicyOwner:
						case UserTaskType.PolicyReview:
							await dispatch(
								assignPolicies(assignment.owner?.id || '', [
									assignment.relatedItem.id,
								]),
							);

							break;

						default:
							break;
					}
				});
			}
		} catch (error: any) {
			throw new CommonResponseError('Error while updating assignments.');
		} finally {
			dispatch(loaded());
		}
	};

export const { loading, loaded } = assignmentsSlice.actions;
export default assignmentsSlice.reducer;
