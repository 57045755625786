import React, { FC } from 'react';
import styles from './StatusInfo.module.scss';
import IStatusInfo from './IStatusInfo';
import classNames from 'classnames';

const StatusInfo: FC<IStatusInfo> = ({ className, children, type }) => {
	return (
		<div className={classNames(styles['status-info'], className, styles[type])}>{children}</div>
	);
};

export default StatusInfo;
