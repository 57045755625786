import {
	AssetCriticalityDisplayName,
	AssetDispositionDisplayName,
	AssetSensitivityDisplayName,
	AssetTypeDisplayName,
	SensitivityColors,
} from '../../../../utils/helpers/constants';
import { ITableColumn } from '../../../primitives/table/ITable';
import ColoredTag from '../../../primitives/tag/ColoredTag';
import styles from './not-archived/AssetsTable.module.scss';
import { IAsset } from '../../../../services/store/slices/assets.slice';
import { ICompanyUser } from '../../../../services/store/slices/company-users.slice';

export const getAssetColumns = () => {
	return [
		{
			header: 'Asset Name',
			key: 'name',
			sorting: true,
			width: '17%',
			columnRender: (value: string, rowData: IAsset) => {
				return (
					<>
						<h6 className={styles.name}>{value}</h6>
						{rowData.description ? (
							<div className={styles.description}>{rowData.description}</div>
						) : null}
					</>
				);
			},
		},
		{
			header: 'Type',
			key: 'type',
			sorting: true,
			width: '17%',
			columnRender: (type: string) =>
				AssetTypeDisplayName[type as keyof typeof AssetTypeDisplayName],
		},
		{
			header: 'Disposition',
			key: 'disposition',
			sorting: true,
			width: '14.8%',
			columnRender: (disposition: string) => {
				if (!disposition) return null;

				return AssetDispositionDisplayName[
					disposition as keyof typeof AssetDispositionDisplayName
				];
			},
		},
		{
			header: 'Owner',
			key: 'owner',
			sorting: true,
			width: '14%',
			columnRender: (owner: ICompanyUser) =>
				owner && owner.fullName ? owner.fullName : 'Unassigned',
		},
		{
			header: 'Criticality',
			key: 'criticality',
			sorting: true,
			width: '13%',
			columnRender: (criticality: string) => {
				if (!criticality) return null;

				return AssetCriticalityDisplayName[
					criticality as keyof typeof AssetCriticalityDisplayName
				];
			},
		},
		{
			header: 'Sensitivity',
			key: 'sensitivity',
			sorting: true,
			width: '13%',
			columnRender: (sensitivity: string) => {
				if (!sensitivity) return null;

				const sensitivityColor =
					SensitivityColors[sensitivity as keyof typeof SensitivityColors];

				return (
					<ColoredTag
						tagId={sensitivity}
						key={sensitivity}
						text={
							AssetSensitivityDisplayName[
								sensitivity as keyof typeof AssetSensitivityDisplayName
							]
						}
						bgColor={sensitivityColor}
					/>
				);
			},
		},
		{
			header: '',
			key: 'actions',
			width: '5%',
		},
	] as ITableColumn[];
};
