import { IControl } from '../../../../services/store/slices/controls.slice';
import {
	checkForMissedVariableValues,
	tagsColumnRenderHelper,
} from '../../../../utils/helpers/common';
import {
	CommonColors,
	CommonStatusesDisplayName,
	StatusColors,
} from '../../../../utils/helpers/constants';
import { IIdName } from '../../../../utils/types';
import { ITableColumn } from '../../../primitives/table/ITable';
import ColoredTag from '../../../primitives/tag/ColoredTag';
import styles from './ControlsTable.module.scss';
import warningIcon from '../../../../assets/images/icon-exclamation-in-circle-dark.svg';
import { DeviceType } from '../../../../utils/helpers/hooks/useDevice/IUseDevice';
import { ITag, ITagBrief } from '../../../../services/store/slices/tags.slice';
import { ICompanyUser } from '../../../../services/store/slices/company-users.slice';

export const getControlColumns = (device?: DeviceType | null, appTags?: ITag[]) => {
	return [
		{
			header: 'Id',
			key: 'controlId',
			sorting: true,
			width: '8.4%',
			columnRender: (value: string, rowData: IControl) => {
				const missedData =
					(rowData.capturedValues &&
						checkForMissedVariableValues(rowData.capturedValues)) ||
					!rowData.statement;

				const getAlertInfo = () => {
					const missedCapturedValues = !!(
						rowData.capturedValues &&
						checkForMissedVariableValues(rowData.capturedValues)
					);

					if (missedCapturedValues && !rowData.statement)
						return 'Control Statement and Captured Value are missing';

					if (missedCapturedValues) return 'Captured Value is missing';

					if (!rowData.statement) return 'Control Statement is missing';
				};

				return (
					<div className={styles['id-wrap']}>
						{value}
						{missedData ? (
							<ColoredTag
								tagId={'alert'}
								small
								iconOnLeft={warningIcon}
								key={'alert'}
								text={'Review'}
								bgColor={CommonColors.alertTag}
								className={styles['alert-tag']}
								tooltipId={missedData ? 'tooltip-alert-info' : ''}
								tooltipContent={missedData ? getAlertInfo() : ''}
							/>
						) : null}
					</div>
				);
			},
		},
		{
			header: 'Control Name',
			key: 'name',
			sorting: true,
			width: '25.6%',
			columnRender: (value: string, rowData: IControl) => {
				return (
					<>
						<h6 className={styles.name}>{value}</h6>
						{rowData.statement ? (
							<div className={styles.statement}>{rowData.statement}</div>
						) : null}
					</>
				);
			},
		},
		{
			header: 'Status',
			key: 'status',
			sorting: true,
			width: '14.9%',
			columnRender: (status: string) => {
				const statusColor = StatusColors[status as keyof typeof StatusColors];
				return (
					<ColoredTag
						tagId={status}
						key={status}
						text={
							CommonStatusesDisplayName[
								status as keyof typeof CommonStatusesDisplayName
							]
						}
						bgColor={statusColor}
					/>
				);
			},
		},
		{
			header: 'Owner',
			key: 'owner',
			sorting: true,
			width: '17%',
			columnRender: (owner: ICompanyUser) =>
				owner && owner.id ? owner.fullName : 'Unassigned',
		},
		{
			header: 'Framework',
			key: 'framework',
			sorting: true,
			width: '17%',
			columnRender: (framework: IIdName) => (framework ? framework.name : ''),
		},
		{
			header: 'Tags',
			key: 'tags',
			width: '17%',
			columnRender: (tags: ITagBrief[]) => tagsColumnRenderHelper(tags, device, appTags),
		},
	] as ITableColumn[];
};
