import React from 'react';
import styles from './PageNotFound.module.scss';
import { AppUrls } from '../../utils/helpers/constants/app-routes';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
	return (
		<div className={styles['page-not-found']}>
			<p className={styles.title}>404 Error: Gravity has taken hold of this page.</p>
			<p className={styles.message}>
				Let's get you back on track{' '}
				<Link className={styles.link} to={AppUrls.home}>
					home
				</Link>{' '}
				before things get too heavy.
			</p>
		</div>
	);
};

export default PageNotFound;
