import { FC } from 'react';
import styles from './ModalArchiveWarning.module.scss';
import Modal from '../../../primitives/modal/Modal';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import IModalArchiveWarning from './IModalArchiveWarning'
import { ActionsBlock, Button } from '../../../primitives';

const ModalArchiveWarning: FC<IModalArchiveWarning> = ({ open, setOpen, onConfirm }) => {
	const onConfirmHandler = () => {
		setOpen(false);
		onConfirm();
	};

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<h2 className={styles.title}>Are you sure you want to archivate it?</h2>

				<p className={styles.text}>You have unsaved changes that will be lost.</p>

				<ActionsBlock>
					<Button type="button" width={147} onClick={() => setOpen(false)}>
						Cancel
					</Button>

					<Button type="button" width={147} negative onClick={onConfirmHandler}>
						Yes, I'm sure
					</Button>
				</ActionsBlock>
			</BasicPanel>
		</Modal>
	);
};

export default ModalArchiveWarning;
