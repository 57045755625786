import { dueDateColumnRenderHelper } from '../../../../utils/helpers/common';
import { UserTaskTypeDisplayName } from '../../../../utils/helpers/constants';
import { IIdName } from '../../../../utils/types';
import { ITableColumn } from '../../../primitives/table/ITable';
import styles from './UserTasksBriefTable.module.scss';

export const getBriefTasksColumns = () => {
	return [
		{
			header: 'Type',
			key: 'taskType',
			sorting: true,
			width: '27%',
			columnRender: (type: string) => UserTaskTypeDisplayName[type as keyof typeof UserTaskTypeDisplayName],
		},
		{
			header: 'Related Item',
			key: 'relatedItem',
			sorting: true,
			width: '49%',
			columnRender: (relatedItem: IIdName) => (
				<h4 className={styles['related-item']}>{relatedItem.name}</h4>
			),
		},
		{
			header: 'Due Date',
			key: 'dueDate',
			sorting: true,
			width: '22%',
			columnRender: (dueDate: string) => dueDateColumnRenderHelper(dueDate, styles['date-tag']),
		},
	] as ITableColumn[];
};
