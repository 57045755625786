import gdprIcon from '../../../assets/images/frameworks/logo-gdpr.png';
import psiIcon from '../../../assets/images/frameworks/logo-psi.png';

export const FrameworksDescription = [
	{
		displayName: 'GDPR',
		name: 'GDPR',
		description: `The General Data Protection Regulation (GDPR), effective from May 25, 2018, across the European Union (EU) and European Economic Area (EEA), establishes stringent data protection standards to empower individuals with control over their personal data, while streamlining the regulatory framework for businesses operating internationally. Applicable to any organization processing the personal data of EU and EEA residents, GDPR mandates explicit consent for data processing, upholds rights to data access, erasure, and portability, and requires prompt notification of data breaches. Its global impact extends beyond the EU borders, obliging international businesses to comply or face severe penalties, including fines up to 4% of annual global turnover or €20 million. GDPR has significantly influenced global data protection practices, setting a benchmark for privacy and data security.`,
		logo: gdprIcon,
	},
	{
		displayName: 'PCI DSS',
		name: 'PCI DSS',
		description: `The Payment Card Industry Data Security Standard (PCI DSS) is a global security standard designed to protect cardholder data and secure payment card transactions. Governed by the PCI Security Standards Council (PCI SSC), it establishes a framework of technical and operational requirements for organizations that store, process, or transmit credit card information. The most recent revision, as of my last update, is PCI DSS 4.0, which was released in March 2022. This latest version introduces changes aimed at enhancing the security of payment transactions in the face of evolving threats and new technologies. It emphasizes a continuous security approach, flexibility for organizations to implement security measures tailored to their specific environments, and an increased focus on authentication, encryption, and critical data protection mechanisms. Applicable to merchants, payment processors, issuers, acquirers, and service providers, compliance with PCI DSS is mandatory for all entities involved in payment card processing. Achieving and maintaining PCI DSS compliance helps organizations protect sensitive cardholder data, minimize the risk of data breaches, and ensure trust in the payment ecosystem.`,
		logo: psiIcon,
	}
];
