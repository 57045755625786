import axios from 'axios';
import { ASSIGNMENTS } from './api.path';
import { IAssignmentBasic } from '../store/slices/assignments.slice';

const assignmentsApi = {
	getAssignments: (userId: string): Promise<IAssignmentBasic[]> => {
		return axios.get(`${ASSIGNMENTS}/${userId}`);
	},
	// updateAssignments: (assignments: IAssignment[]): Promise<any> => {
	// 	return axios.put(`${ASSIGNMENTS}`, { data: assignments });
	// },
};

export default assignmentsApi;
