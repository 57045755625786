import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './EditAsset.module.scss';
import { IEditAsset } from './IEditAsset';
import Input from '../../../../primitives/form/input/Input';
import Textarea from '../../../../primitives/form/textarea/Textarea';
import Select from '../../../../primitives/form/select/Select';
import { ActionsBlock, Button } from '../../../../primitives';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../../services/store';
import { handleWithTryCatch } from '../../../../../utils/helpers/errors';
import useUser from '../../../../../utils/helpers/hooks/useUser';
import CommentsBlock from '../../../comments-block/CommentsBlock';
import ChangesHistoryBlock from '../../../changes-history-block/ChangesHistoryBlock';
import SectionTitle from '../../../../primitives/section-title/SectionTitle';
import CollapsiblePanel from '../../../../primitives/collapsible-panel/CollapsiblePanel';
import {
	controlsAreDifferent,
	getOwnerOptions,
	sanitizeData,
	tagsAreDifferent,
} from '../../../../../utils/helpers/common';
import Drawer from '../../../../primitives/drawer/Drawer';
import ModalLeaveWarning from '../../../modals/modal-leave-warning/ModalLeaveWarning';
import useLeaveWarning from '../../../../../utils/helpers/hooks/useLeaveWarning';
import {
	IAsset,
	IUpdateAsset,
	addCommentToAsset,
	archiveAssets,
	getAssetById,
	updateAsset,
} from '../../../../../services/store/slices/assets.slice';
import EditAssetSkeleton from './EditAssetSkeleton';
import useAssets from '../../../../../utils/helpers/hooks/useAssets';
import {
	AssetCriticalityOptions,
	AssetDispositionOptions,
	AssetSensitivityOptions,
	AssetTypeOptions,
} from '../../../../../utils/helpers/constants';
import TagsAndControlsBlock from '../../../tags-and-controls-block/TagsAndControlsBlock';
import ModalArchiveWarning from '../../../modals/modal-archive-warning/ModalArchiveWarning';
import Scrollbar from '../../../../primitives/scrollbar/Scrollbar';
import classNames from 'classnames';
import useCompanyUsers from '../../../../../utils/helpers/hooks/useCompanyUsers';
import Loader from '../../../../primitives/loader/Loader';
import ModalAddControls from '../../../modals/modal-add-controls/ModalAddControls';
import { IControl, getControlsByType } from '../../../../../services/store/slices/controls.slice';
import { EntityTypes, IIdName } from '../../../../../utils/types';
import { commonStringIsValid } from '../../../../../utils/helpers/common/form';
import Error from '../../../../primitives/error/Error';
import { ITagBrief, getTags } from '../../../../../services/store/slices/tags.slice';

const EditAsset: FC<IEditAsset> = ({
	assetId,
	onUpdateError,
	onUpdateSuccess,
	closeHandler,
	open,
	onAssetArchiveError,
	onAssetArchiveSuccess,
}) => {
	const dispatch = useAppDispatch();
	const { isAdmin, info: userInfo } = useUser();
	const { items: companyUsers } = useCompanyUsers();
	const { assetLoading, commentsLoading, archivationLoading } = useAssets();

	const { handleSubmit } = useForm();
	const [selectedTypeControls, setSelectedTypeControls] = useState<IControl[]>([]);
	const [typeControlsLoading, setTypeControlsLoading] = useState(false);

	const [currentAsset, setCurrentAsset] = useState<IAsset | null>();

	const {
		id,
		name,
		description,
		details,
		type,
		owner,
		tags,
		disposition,
		criticality,
		sensitivity,
		comments,
		history,
		companyControls,
	} = currentAsset || {};

	const [currentFormState, setCurrentFormState] = useState<IUpdateAsset | null>();
	const [loading, setLoading] = useState(false);
	const [submitIsDisabled, setSubmitIsDisabled] = useState(false);
	const [warningModalOpen, setWarningModalOpen] = useState(false);
	const [archiveModalOpen, setArchiveModalOpen] = useState(false);
	const [showBrowserLeaveWarning, setShowBrowserLeaveWarning] = useState(false);
	const [error, setError] = useState('');
	const [commentIsAdding, setCommentIsAdding] = useState(false);

	const [openAddControlsModal, setOpenAddControlsModal] = useState(false);
	const [addedControlIds, setAddedControlIds] = useState<IIdName[]>([]);

	const initialEditFormState = useMemo(
		() => ({
			id,
			name,
			description,
			details,
			type,
			ownerId: owner?.id || 'unassigned',
			tags,
			disposition,
			criticality,
			sensitivity,
			companyControls,
		}),
		[
			id,
			companyControls,
			criticality,
			description,
			details,
			disposition,
			name,
			owner?.id,
			sensitivity,
			tags,
			type,
		],
	);

	const ownersOptions = useMemo(
		() => getOwnerOptions(companyUsers, userInfo?.id),
		[companyUsers, userInfo?.id],
	);

	const onFormSubmitHandler = () => {
		if (submitIsDisabled) return;

		const sanitizedData = sanitizeData(currentFormState);

		const owner = currentFormState?.ownerId === 'unassigned' ? null : currentFormState?.ownerId;

		if (id && userInfo?.companyId) {
			handleWithTryCatch(
				async () => {
					await dispatch(
						updateAsset(
							id,
							userInfo?.companyId!,
							{
								...sanitizedData,
								ownerId: owner,
								companyControlIds: currentFormState?.companyControls?.map(
									(c) => c.id,
								),
								tags: currentFormState?.tags?.map((t) => t.id),
							},
							!isAdmin ? userInfo?.id : '',
						),
					);
					if (onUpdateSuccess) onUpdateSuccess();
				},
				undefined,
				onUpdateError,
			);
		}
	};

	const onCommentAdded = useCallback(() => {
		setCommentIsAdding(true);

		handleWithTryCatch(
			async () => {
				const asset = await dispatch(getAssetById(assetId));
				const newComments = asset?.comments;
				setCurrentAsset((prev: IAsset | null | undefined) => ({
					...prev!,
					comments: newComments,
				}));

				setCommentIsAdding(false);
			},
			setError,
			() => setCommentIsAdding(false),
		);
	}, [assetId, dispatch]);

	const renderTagsAndControls = () => {
		const trigger = (
			<SectionTitle className={styles['collapsible-trigger']}>Tags and Controls</SectionTitle>
		);

		const setAssetControls = (newControls: IIdName[]) => {
			setCurrentFormState((prev) => ({
				...prev,
				companyControls: newControls,
			}));
		};

		const setAssetTags = (newTags: ITagBrief[]) => {
			setCurrentFormState((prev) => ({ ...prev, tags: newTags }));
		};

		return (
			<CollapsiblePanel trigger={trigger} isOpened>
				<TagsAndControlsBlock
					entityTagIds={currentFormState?.tags || []}
					entityControlIds={currentFormState?.companyControls || []}
					setControlIds={setAssetControls}
					setTagIds={setAssetTags}
					isViewMode={!isAdmin}
					externalAddedControlIds={addedControlIds.map((c) => c.id)}
					clearExternalAddedControlIds={() => setAddedControlIds([])}
				/>
			</CollapsiblePanel>
		);
	};

	const renderChangesHistory = () => {
		const trigger = (
			<SectionTitle className={styles['collapsible-trigger']}>History</SectionTitle>
		);

		return (
			<CollapsiblePanel trigger={trigger}>
				<ChangesHistoryBlock changes={history} />
			</CollapsiblePanel>
		);
	};

	const renderComments = () => {
		const trigger = (
			<SectionTitle className={styles['collapsible-trigger']}>Comments</SectionTitle>
		);

		return (
			<CollapsiblePanel trigger={trigger}>
				<CommentsBlock
					entityId={id}
					entityType={EntityTypes.Asset}
					comments={comments}
					addDispatchHandler={addCommentToAsset}
					commentsLoading={commentsLoading || commentIsAdding}
					onAddSuccess={onCommentAdded}
				/>
			</CollapsiblePanel>
		);
	};

	const archivateAsset = () => {
		if (id) {
			handleWithTryCatch(
				async () => {
					await dispatch(archiveAssets([id]));
					onAssetArchiveSuccess();
				},
				undefined,
				onAssetArchiveError,
			);
		}
	};

	const renderActions = () => {
		return (
			<ActionsBlock className={classNames(styles.actions, isAdmin ? '' : styles.right)}>
				{isAdmin ? (
					<Button
						disabled={assetLoading || archivationLoading}
						width={136}
						type="button"
						negative
						onClick={() => {
							if (changesWereMade) setArchiveModalOpen(true);
							else archivateAsset();
						}}
					>
						{assetLoading || archivationLoading ? (
							<Loader thin maxHeight={14} maxWidth={14} />
						) : (
							'Archive'
						)}
					</Button>
				) : null}

				<Button width={136} type="submit" disabled={submitIsDisabled || assetLoading}>
					{assetLoading || archivationLoading ? (
						<Loader thin maxHeight={14} maxWidth={14} />
					) : (
						'Save'
					)}
				</Button>
			</ActionsBlock>
		);
	};

	const renderModals = () => {
		const showControlsModal = () => {
			if (!selectedTypeControls.length) return false;

			const allControlsAreSame = selectedTypeControls.every((control) =>
				currentFormState?.companyControls?.some((c) => c.id === control.id),
			);

			return selectedTypeControls.length && !allControlsAreSame;
		};

		return (
			<>
				<ModalLeaveWarning
					open={warningModalOpen}
					setOpen={setWarningModalOpen}
					onConfirm={() => {
						setShowBrowserLeaveWarning(false);
						if (closeHandler) closeHandler();
					}}
				/>

				<ModalArchiveWarning
					open={archiveModalOpen}
					setOpen={setArchiveModalOpen}
					onConfirm={() => {
						setShowBrowserLeaveWarning(false);
						archivateAsset();
					}}
				/>

				{showControlsModal() ? (
					<ModalAddControls
						predefinedItems={selectedTypeControls}
						allPreselected
						open={openAddControlsModal}
						setOpen={setOpenAddControlsModal}
						entityControlIds={currentFormState?.companyControls || []}
						setEntityControls={(newControls: IIdName[]) => {
							setCurrentFormState((prev) => ({
								...prev,
								companyControls: newControls,
							}));
						}}
						onNewAdded={setAddedControlIds}
						description="By selecting this type you can also add the following controls to it:"
						withSearch={false}
						cancelBtnText="Skip"
					/>
				) : null}
			</>
		);
	};

	const getTypeControls = (type: string) => {
		setSelectedTypeControls([]);
		setTypeControlsLoading(true);

		handleWithTryCatch(
			async () => {
				const controlObjs = await dispatch(getControlsByType(type));

				if (controlObjs.length) {
					const filteredControls = controlObjs.filter((control) =>
						currentFormState?.companyControls?.some((c) => c.id === control.id),
					);
					setSelectedTypeControls(filteredControls || []);
				}

				setTypeControlsLoading(false);
			},
			undefined,
			() => {
				setSelectedTypeControls([]);
				setTypeControlsLoading(false);
			},
		);
	};

	const renderDetails = () => {
		const trigger = (
			<SectionTitle className={classNames(styles.details, styles['collapsible-trigger'])}>
				Details
			</SectionTitle>
		);

		return (
			<CollapsiblePanel trigger={trigger}>
				<Input
					disabled={!isAdmin}
					className={styles.input}
					type="text"
					value={currentFormState?.name}
					label="Asset Name"
					onValueChange={(value) =>
						setCurrentFormState((prev) => ({ ...prev, name: value }))
					}
					withErrorStyle={!commonStringIsValid(currentFormState?.name)}
					error={
						!commonStringIsValid(currentFormState?.name)
							? 'The input field allows alpha-numeric and special characters'
							: ''
					}
				/>

				<Input
					className={styles.input}
					type="text"
					value={currentFormState?.description}
					placeholder="Enter or paste asset description here..."
					label="Asset Description"
					onValueChange={(value) =>
						setCurrentFormState((prev) => ({ ...prev, description: value }))
					}
					withErrorStyle={!commonStringIsValid(currentFormState?.description)}
					error={
						!commonStringIsValid(currentFormState?.description)
							? 'The input field allows alpha-numeric and special characters'
							: ''
					}
				/>

				<Textarea
					value={currentFormState?.details}
					className={styles.input}
					placeholder="Enter or paste asset details here..."
					label="Asset Details"
					onValueChange={(value) =>
						setCurrentFormState((prev) => ({ ...prev, details: value }))
					}
					withErrorStyle={!commonStringIsValid(currentFormState?.details)}
					error={
						!commonStringIsValid(currentFormState?.details)
							? 'The input field allows alpha-numeric and special characters'
							: ''
					}
				/>

				<Select
					id="owner"
					disabled={!isAdmin}
					onValueChange={(value) =>
						setCurrentFormState((prev) => ({ ...prev, ownerId: value }))
					}
					defaultValue={currentFormState?.ownerId}
					className={styles.input}
					label="Owner"
					options={ownersOptions || []}
				/>

				<div className={styles['input-group']}>
					<Select
						id="type"
						disabled={!isAdmin}
						onValueChange={(value) => {
							setCurrentFormState((prev) => ({ ...prev, type: value }));

							getTypeControls(value);
							setOpenAddControlsModal(true);
						}}
						defaultValue={currentFormState?.type}
						className={styles.input}
						label="Type"
						labelWithLoader={typeControlsLoading}
						options={AssetTypeOptions}
						groupedBy="groupName"
					/>

					<Select
						id="disposition"
						disabled={!isAdmin}
						onValueChange={(value) =>
							setCurrentFormState((prev) => ({ ...prev, disposition: value }))
						}
						defaultValue={currentFormState?.disposition}
						className={styles.input}
						label="Disposition"
						options={AssetDispositionOptions}
					/>
				</div>

				<div className={styles['input-group']}>
					<Select
						id="criticality"
						disabled={!isAdmin}
						onValueChange={(value) =>
							setCurrentFormState((prev) => ({ ...prev, criticality: value }))
						}
						defaultValue={currentFormState?.criticality}
						className={styles.input}
						options={AssetCriticalityOptions}
						label="Criticality"
					/>

					<Select
						id="sensitivity"
						disabled={!isAdmin}
						onValueChange={(value) =>
							setCurrentFormState((prev) => ({ ...prev, sensitivity: value }))
						}
						defaultValue={currentFormState?.sensitivity}
						className={styles.input}
						label="Sensitivity"
						options={AssetSensitivityOptions}
					/>
				</div>
			</CollapsiblePanel>
		);
	};

	const editForm = (
		<form onSubmit={handleSubmit(onFormSubmitHandler)}>
			<Scrollbar className={styles.scrollbar}>
				<div className={styles.inputs}>
					{renderDetails()}

					{renderTagsAndControls()}

					{renderComments()}

					{renderChangesHistory()}
				</div>
			</Scrollbar>

			{renderActions()}
		</form>
	);

	const changesWereMade = useMemo(() => {
		if (currentFormState?.id && initialEditFormState.id) {
			const controlsDiffer = controlsAreDifferent(
				currentFormState?.companyControls,
				initialEditFormState?.companyControls,
			);
			const tagsDiffer = tagsAreDifferent(currentFormState?.tags, initialEditFormState?.tags);

			return !!(
				initialEditFormState.name !== currentFormState?.name ||
				initialEditFormState.description !== currentFormState?.description ||
				initialEditFormState.details !== currentFormState?.details ||
				initialEditFormState.ownerId !== currentFormState?.ownerId ||
				initialEditFormState.type !== currentFormState?.type ||
				initialEditFormState.disposition !== currentFormState?.disposition ||
				initialEditFormState.criticality !== currentFormState?.criticality ||
				initialEditFormState.sensitivity !== currentFormState?.sensitivity ||
				controlsDiffer ||
				tagsDiffer
			);
		}
		return false;
	}, [
		currentFormState?.companyControls,
		currentFormState?.criticality,
		currentFormState?.description,
		currentFormState?.details,
		currentFormState?.disposition,
		currentFormState?.id,
		currentFormState?.name,
		currentFormState?.ownerId,
		currentFormState?.sensitivity,
		currentFormState?.tags,
		currentFormState?.type,
		initialEditFormState?.companyControls,
		initialEditFormState.criticality,
		initialEditFormState.description,
		initialEditFormState.details,
		initialEditFormState.disposition,
		initialEditFormState.id,
		initialEditFormState.name,
		initialEditFormState.ownerId,
		initialEditFormState.sensitivity,
		initialEditFormState?.tags,
		initialEditFormState.type,
	]);

	useLeaveWarning(showBrowserLeaveWarning);

	useEffect(() => {
		setCurrentFormState(initialEditFormState);
	}, [initialEditFormState]);

	useEffect(() => {
		if (!open) {
			setCurrentAsset(null);
			setShowBrowserLeaveWarning(false);
			setSubmitIsDisabled(false);
			setAddedControlIds([]);
			setSelectedTypeControls([]);
			setError('');
		}
	}, [open]);

	useEffect(() => {
		if (changesWereMade) setShowBrowserLeaveWarning(true);
	}, [changesWereMade]);

	useEffect(() => {
		if (error) setLoading(false);
	}, [error]);

	useEffect(() => {
		if (!currentAsset && assetId) setLoading(true);
	}, [currentAsset, assetId]);

	useEffect(() => {
		if (assetId && !currentAsset && !loading && !error) {
			handleWithTryCatch(async () => {
				dispatch(getTags());
				const asset = await dispatch(getAssetById(assetId));

				//@ts-ignore
				setCurrentAsset(asset);
				setLoading(false);
			}, setError);
		}
	}, [assetId, currentAsset, dispatch, error, loading]);

	useEffect(() => {
		if (
			!currentFormState?.name ||
			!commonStringIsValid(currentFormState?.name) ||
			!commonStringIsValid(currentFormState?.description) ||
			!commonStringIsValid(currentFormState?.details)
		)
			setSubmitIsDisabled(true);
		else setSubmitIsDisabled(false);
	}, [currentFormState?.description, currentFormState?.details, currentFormState?.name]);

	useEffect(() => {
		if (!open) {
			setCurrentAsset(null);
			setShowBrowserLeaveWarning(false);
			setError('');
		}
	}, [open]);

	return (
		<Drawer
			contentClassName={styles.drawer}
			title="Asset Details"
			open={open}
			onCloseClickHandler={() => {
				if (changesWereMade) setWarningModalOpen(true);
				else if (closeHandler) closeHandler();
			}}
		>
			{error ? <Error message={error} /> : null}

			{loading ? <EditAssetSkeleton /> : null}

			{!loading && id ? (
				<div className={styles['edit-asset']}>
					{editForm}

					{renderModals()}
				</div>
			) : null}
		</Drawer>
	);
};

export default EditAsset;
