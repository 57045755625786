import { FC } from 'react';
import styles from './EditTag.module.scss';
import LoadingSkeleton from '../../../primitives/loading-skeleton/LoadingSkeleton';
import HistorySkeleton from '../../../primitives/skeletons/history/HistorySkeleton';

const EditTagSkeleton: FC = () => {
	const editForm = (
		<div className={styles.form}>
			<div className={styles.inputs}>
				<LoadingSkeleton containerClassName={styles['section-title']} height={24} />

				<LoadingSkeleton containerClassName={styles.input} height={54} />
				<LoadingSkeleton containerClassName={styles.input} height={54} />
				<LoadingSkeleton containerClassName={styles.input} height={54} />

				<HistorySkeleton />
				<HistorySkeleton />
				<HistorySkeleton />
				<HistorySkeleton />
			</div>
		</div>
	);

	return <div className={styles['edit-tag-skeleton']}>{editForm}</div>;
};

export default EditTagSkeleton;
