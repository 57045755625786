import React, { FC } from 'react';
import styles from './BasicPanel.module.scss';
import IBasicPanel from './IBasicPanel';
import classNames from 'classnames';
import Back from '../back/Back';

const BasicPanel: FC<IBasicPanel> = ({
	className,
	children,
	withLogo,
	title,
	withBackNavigation,
	onBackClick,
}) => {
	const onBackClickHandler = () => {
		if (onBackClick) {
			onBackClick();
		}
	};

	return (
		<div
			className={classNames(
				className,
				styles['basic-panel'],
				withLogo ? styles['with-logo'] : '',
				withBackNavigation ? styles['with-back'] : '',
			)}
		>
			{withBackNavigation ? (
				<Back className={styles.close} onClick={onBackClickHandler} />
			) : null}

			{title ? <h2 className={(styles.title)}>{title}</h2> : null}

			{children}
		</div>
	);
};

export default BasicPanel;
