import React, { FC, useEffect, useState } from 'react';
import styles from './Onboarding.module.scss';
import classNames from 'classnames';
import FrameworkStep from './framework-step/FrameworkStep';
import PoliciesStep from './policies-step/PoliciesStep';
import AnalysisStep from './analysis-step/AnalysisStep';
import StepsProgress from '../../components/primitives/steps-progress/StepsProgress';
import BasicPanel from '../../components/primitives/basic-panel/BasicPanel';
import { IFrameworkStepData } from './IOnboardingStep';
import useUser from '../../utils/helpers/hooks/useUser';
import gravityLogo from '../../assets/images/logo-gravity-grc-for-light-bg.svg';
import useCompany from '../../utils/helpers/hooks/useCompany';
import { OnboardingStatus } from '../../utils/helpers/constants';
import Error from '../../components/primitives/error/Error';
import Loader from '../../components/primitives/loader/Loader';

const Onboarding: FC = () => {
	const { isAdmin } = useUser();
	const { onBoardingStatus, onboarded } = useCompany();
	const [currentStep, setCurrentStep] = useState('framework');
	const [selectedFramework, setSelectedFramework] = useState<IFrameworkStepData>({
		key: '',
		name: '',
		logo: '',
	});
	const [error, setError] = useState(false);

	const onboardingSteps = [
		{
			stepName: 'framework',
			component: (
				<FrameworkStep
					setNextStep={() => {
						setError(false);
						setCurrentStep('policies');
					}}
					setFramework={setSelectedFramework}
				/>
			),
		},
		{
			stepName: 'policies',
			component: (
				<PoliciesStep
					goBack={() => setCurrentStep('framework')}
					setNextStep={() => setCurrentStep('analysis')}
					framework={selectedFramework}
				/>
			),
		},
		{
			stepName: 'analysis',
			component: <AnalysisStep />,
		},
	];

	const activeStepNumber = onboardingSteps.findIndex((step) => step.stepName === currentStep);

	const renderFailedError = () => {
		if (isAdmin && error) {
			return (
				<Error
					className={styles.error}
					message="Looks like your previous onboarding process has failed for some reason. Please try again."
				/>
			);
		}
	};

	useEffect(() => {
		if (onBoardingStatus && onBoardingStatus === OnboardingStatus.failed) setError(true);
	}, [onBoardingStatus]);

	if (onboarded === undefined) return <Loader wide thin />;

	if (!isAdmin && !onboarded)
		return (
			<BasicPanel className={classNames(styles.onboarding, styles['no-access'])}>
				<img src={gravityLogo} alt="Gravity GRC" height={20} />
				<p>
					Well, looks like your company account hasn't been set up yet. We recommend
					promptly contacting your company's administrator to address this matter.
				</p>
			</BasicPanel>
		);

	if (
		isAdmin &&
		!onboarded &&
		onBoardingStatus &&
		onBoardingStatus !== OnboardingStatus.failed &&
		onBoardingStatus !== OnboardingStatus.pending
	) {
		return (
			<BasicPanel className={classNames(styles.onboarding, styles['no-access'])}>
				<img src={gravityLogo} alt="Gravity GRC" height={20} />
				<p>
					Well, looks like your onboarding process hasn't finished yet. You'll receive the
					email when it is finished.
				</p>
			</BasicPanel>
		);
	}

	return (
		<BasicPanel className={classNames(styles.onboarding, styles[currentStep])}>
			<StepsProgress
				className={styles.progress}
				stepsNumber={onboardingSteps.length}
				activeStep={activeStepNumber}
			/>

			{onboardingSteps.find((step) => step.stepName === currentStep)?.component}

			{renderFailedError()}
		</BasicPanel>
	);
};

export default Onboarding;
