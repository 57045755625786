import React, { useEffect, useMemo, useState } from 'react';
import { PAGE_SIZE } from '../constants';
import Pagination from '../../../components/primitives/pagination/Pagination';

export default function usePagination(
	data: any[],
	setCurrentPageData: (data: any[]) => void,
	pageSize?: number,
) {
	const [currentPage, setCurrentPage] = useState(1);
	const pageSizeValue = pageSize || PAGE_SIZE;

	const pagesCount = useMemo(
		() => Math.ceil(data.length / pageSizeValue),
		[data.length, pageSizeValue],
	);

	useEffect(() => {
		setCurrentPageData(
			data.slice((currentPage - 1) * pageSizeValue, currentPage * pageSizeValue),
		);
	}, [data, currentPage, setCurrentPageData, pageSizeValue]);

	const paginationSection = (
		<Pagination
			currentPage={currentPage}
			pageCount={pagesCount}
			onPageChangeHandler={(page: number) => setCurrentPage(page)}
		/>
	);

	const findPageByDataEntry = (value: string, propertyName: string) => {
		const index = data.findIndex((entry) => entry[propertyName] === value);
		return Math.ceil((index + 1) / pageSizeValue);
	};

	const goToPageByDataEntryProperty = (value: string, propertyName: string) => {
		const pageNumber = findPageByDataEntry(value, propertyName);
		setCurrentPage(pageNumber);
	};

	return {
		paginationSection,
		setCurrentPage,
		pagesCount,
		goToPageByDataEntryProperty,
	};
}
