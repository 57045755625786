import { FC, useEffect, useState } from 'react';
import styles from './UserRecentUpdates.module.scss';
import classNames from 'classnames';
import IUserRecentUpdates from './IUserRecentUpdates';
import ChangesHistoryBlock from '../changes-history-block/ChangesHistoryBlock';
import { useAppDispatch } from '../../../services/store';
import { handleWithTryCatch } from '../../../utils/helpers/errors';
import { getUserUpdates } from '../../../services/store/slices/user.slice';
import { IChangeHistoryEntry } from '../../../utils/types';
import usePolicies from '../../../utils/helpers/hooks/usePolicies';
import useControls from '../../../utils/helpers/hooks/useControls';
import useAssets from '../../../utils/helpers/hooks/useAssets';
import UserRecentUpdatesSkeleton from './UserRecentUpdatesSkeleton';
import Scrollbar from '../../primitives/scrollbar/Scrollbar';

const UserRecentUpdates: FC<IUserRecentUpdates> = ({ className }) => {
	const dispatch = useAppDispatch();
	const [dataLoading, setDataLoading] = useState(true);
	const [dataToDisplay, setDataToDisplay] = useState<IChangeHistoryEntry[]>([]);
	const { items: policyItems } = usePolicies();
	const { items: controlItems } = useControls();
	const { items: assetItems } = useAssets();

	const renderEmptyState = () => {
		return (
			<div className={styles['empty-message']}>
				<span className={styles.message}>No updates to display yet</span>
			</div>
		);
	};

	const renderUpdates = () => {
		if (dataToDisplay?.length)
			return (
				<Scrollbar small className={styles.scrollbar}>
					<ChangesHistoryBlock className={styles.history} entitiesMode changes={dataToDisplay} />
				</Scrollbar>
			);

		return renderEmptyState();
	};

	useEffect(() => {
		if (policyItems && controlItems && assetItems) {
			handleWithTryCatch(
				async () => {
					const updates = await dispatch(getUserUpdates());
					setDataToDisplay(updates);
					setDataLoading(false);
				},
				undefined,
				() => setDataLoading(false),
			);
		}
	}, [assetItems, controlItems, dispatch, policyItems]);

	return (
		<div className={classNames(styles.updates, className)}>
			<div className={styles['title-section']}>
				<h3>My Updates</h3>

				{dataLoading ? <UserRecentUpdatesSkeleton /> : renderUpdates()}
			</div>
		</div>
	);
};

export default UserRecentUpdates;
