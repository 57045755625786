import axios from 'axios';
import { REGISTER_USER, USERS } from './api.path';
import { ICompanyUser } from '../store/slices/company-users.slice';

const companyUsersApi = {
	getCompanyUsers: (): Promise<ICompanyUser[]> => {
		return axios.get(`${USERS}`);
	},
	getCompanyUserById: (id: string): Promise<ICompanyUser> => {
		return axios.get(`${USERS}/${id}`);
	},
	addNewCompanyUser: (data: Partial<ICompanyUser>, companyId: string): Promise<ICompanyUser> => {
		return axios.post(`${REGISTER_USER}`, { ...data, companyId: Number(companyId) });
	},
	deleteCompanyUser: (userId: string) => {
		return axios.delete(`${USERS}/${userId}`);
	},
	updateCompanyUser: (data: Partial<ICompanyUser>): Promise<ICompanyUser> => {
		return axios.put(`${USERS}/admin`, data);
	},
};

export default companyUsersApi;
