import { useEffect, useMemo, useState } from 'react';
import ControlsTable from '../../components/partials/tables/controls/ControlsTable';
import useControls from '../../utils/helpers/hooks/useControls';
import useUser from '../../utils/helpers/hooks/useUser';
import { IControl } from '../../services/store/slices/controls.slice';
import useGetControls from '../../utils/helpers/hooks/useGetControls';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppQueryParams } from '../../utils/helpers/constants';
import { AppUrls } from '../../utils/helpers/constants/app-routes';

const Controls = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { items: controlItems, loading } = useControls();
	const { info: userInfo, isAdmin } = useUser();

	const [itemsToDisplay, setItemsToDisplay] = useState<IControl[] | null>();
	const [dataLoading, setDataLoading] = useState(true);

	const navigateToItem = useMemo(() => {
		if (!dataLoading && controlItems && userInfo?.id) {
			const controlIdFromUrl = new URLSearchParams(location.search).get(AppQueryParams.item);

			const foundedControl = controlItems.find((control) => control.id === controlIdFromUrl);

			if (foundedControl) {
				if (!isAdmin && foundedControl.owner?.id !== userInfo?.id)
					return navigate(AppUrls.controls);

				return foundedControl.id;
			}
		}
	}, [controlItems, dataLoading, isAdmin, location.search, navigate, userInfo?.id]);

	useGetControls();

	useEffect(() => {
		if (userInfo?.id && controlItems) {
			const filtered = isAdmin
				? controlItems
				: controlItems.filter((control) => control.owner?.id === userInfo?.id);

			setItemsToDisplay(filtered || []);
		}
	}, [controlItems, isAdmin, userInfo?.id]);

	useEffect(() => {
		if (controlItems) setDataLoading(false);
	}, [controlItems]);

	return (
		<>
			<h2>Controls</h2>

			<ControlsTable
				data={itemsToDisplay || []}
				dataLoading={dataLoading || loading}
				showItemId={navigateToItem || ''}
			/>
		</>
	);
};

export default Controls;
