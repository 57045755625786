import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './Error.module.scss';
import IError from './IError';

const Error: FC<IError> = ({ className, message, disabled, ...rest }) => {
	return (
		<div
			className={classNames(styles.error, className, disabled ? styles.disabled : '')}
			{...rest}
		>
			{message}
		</div>
	);
};

export default React.memo(Error);
