import { FC, useCallback, useEffect, useState } from 'react';
import styles from './ControlStatementBlock.module.scss';
import classNames from 'classnames';
import useUser from '../../../utils/helpers/hooks/useUser';
import ColoredTag from '../../primitives/tag/ColoredTag';
import { Button } from '../../primitives';
import editIcon from '../../../assets/images/icon-edit.svg';
import plusIcon from '../../../assets/images/icon-plus-dark.svg';
import IControlStatementBlock from './IControlStatementBlock';
import Textarea from '../../primitives/form/textarea/Textarea';
import ReadMore from '../../primitives/read-more/ReadMore';
import { truncateString } from '../../../utils/helpers/common';

const ControlStatementBlock: FC<IControlStatementBlock> = ({
	className,
	statement,
	onStatementChange,
	setOpenModal,
}) => {
	const { isAdmin } = useUser();

	const [editMode, setEditMode] = useState(false);
	const [inputValue, setInputValue] = useState(statement);

	const onStatementTagClick = (e: React.MouseEvent, tagId: string) => {
		if (!isAdmin) return;
		setEditMode(true);
	};

	const onSaveClick = useCallback(() => {
		setEditMode(false);

		onStatementChange(inputValue);
	}, [inputValue, onStatementChange]);

	const onCancelClick = () => {
		setEditMode(false);
		setInputValue('');
	};

	const renderInputDialog = () => {
		return (
			<div className={styles.dialog}>
				<Textarea
					disabled={!isAdmin}
					onValueChange={(value) => setInputValue(value)}
					defaultValue={statement || ''}
					className={styles.statement}
					placeholder="Enter or paste control statement here..."
					asInput
				/>

				<div className={styles.actions}>
					<Button
						className={styles.action}
						width={73}
						small
						type="button"
						negative
						onClick={onCancelClick}
					>
						Cancel
					</Button>

					<Button
						className={styles.action}
						width={62}
						small
						type="button"
						onClick={onSaveClick}
					>
						Save
					</Button>
				</div>
			</div>
		);
	};

	const renderValueTag = () => {
		if (statement)
			return (
				<ColoredTag
					className={classNames(
						styles.tag,
						styles['with-value'],
						editMode ? styles.focus : '',
						!isAdmin ? styles.disabled : '',
					)}
					tagId={'statement'}
					text={truncateString(statement, 380)}
					onClickHandler={onStatementTagClick}
					iconOnRight={isAdmin ? editIcon : ''}
				/>
			);

		return (
			<ColoredTag
				className={classNames(
					styles.tag,
					styles.add,
					editMode ? styles.focus : '',
					!isAdmin ? styles.disabled : '',
				)}
				tagId={'statement'}
				text="Add Control Statement"
				onClickHandler={onStatementTagClick}
				iconOnRight={plusIcon}
			/>
		);
	};

	useEffect(() => {
		const onEnterPress = (e: KeyboardEvent) => {
			if (editMode && e.key === 'Enter' && !e.shiftKey) onSaveClick();
		};

		window.addEventListener('keydown', onEnterPress);
		return () => window.removeEventListener('keydown', onEnterPress);
	}, [editMode, onSaveClick]);

	return (
		<div className={classNames(styles['statement-item'], className)}>
			{renderValueTag()}

			<p className={styles.help}>
				Want some help in writing a good control statement? See
				<ReadMore
					className={styles.info}
					text="Language Guidance"
					onClickHandler={() => setOpenModal(true)}
				/>
			</p>

			{editMode ? renderInputDialog() : null}
		</div>
	);
};

export default ControlStatementBlock;
