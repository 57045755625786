import axios from 'axios';
import { COMPANIES, FILES, ONBOARDING } from './api.path';
import { ICompanyBasic, IOnboardingData, IOnboardingStatus } from '../store/slices/company.slice';
import { IFilePreassignedData } from '../../utils/types';

const companyApi = {
	initiateOnboarding: (data: IOnboardingData) => {
		return axios.post(`${ONBOARDING}`, data);
	},
	checkIfOnboarded: (): Promise<IOnboardingStatus> => {
		return axios.get(`${ONBOARDING}/onboarded`);
	},
	getCompanyInfo: (companyId: string): Promise<ICompanyBasic> => {
		return axios.get(`${COMPANIES}/${companyId}`);
	},
	updateCompanyInfo: (
		companyId: string,
		data: Partial<ICompanyBasic>,
	): Promise<ICompanyBasic> => {
		const logo = data.logoId ? Number(data.logoId) : data.logoId;
		return axios.put(`${COMPANIES}/${companyId}`, { ...data, logoId: logo });
	},
	getPreassignedUrlForLogo: (fileName: string): Promise<IFilePreassignedData> => {
		return axios.get(`${FILES}?fileName=${fileName}`);
	},
	getPreassignedUrlForPolicy: (fileName: string): Promise<IFilePreassignedData> => {
		return axios.get(`${ONBOARDING}/preassign-url?fileName=${fileName}`);
	},
	getCompanyLogoUrl: (fileId: string): Promise<any> => {
		return axios.get(`${FILES}/${fileId}`);
	},
	deleteCompanyLogo: (fileId: string) => {
		return axios.delete(`${FILES}/${fileId}`);
	},
};

export default companyApi;
