import { controlsColumnRenderHelper } from '../../../../../utils/helpers/common';
import { ITableColumn } from '../../../../primitives/table/ITable';
import { DeviceType } from '../../../../../utils/helpers/hooks/useDevice/IUseDevice';
import { IPolicy } from '../../../../../services/store/slices/policies.slice';
import { IIdName } from '../../../../../utils/types';

export const getGSPolicyColumns = (device?: DeviceType | null) => {
	return [
		{
			header: 'Policy Name',
			key: 'name',
			sorting: true,
			width: '32%',
			columnRender: (value: string, rowData: IPolicy) => {
				return <h4>{value}</h4>;
			},
		},
		{
			header: 'Controls',
			key: 'controls',
			width: '34%',
			columnRender: (controls: IIdName[]) =>
				controlsColumnRenderHelper(
					controls.map((c) => c.displayId || ''),
					device,
				),
		},
		{
			header: 'Framework',
			key: 'framework',
			sorting: true,
			width: '34%',
			columnRender: (framework: IIdName) => (framework ? framework.name : ''),
		},
	] as ITableColumn[];
};
