import React, { ChangeEvent, FC, forwardRef, useState } from 'react';
import IInput from './IInput';
import styles from './Input.module.scss';
import Error from '../../error/Error';
import classNames from 'classnames';

const Input: FC<IInput> = forwardRef(
	(
		{
			id,
			name,
			type,
			placeholder,
			defaultValue,
			value,
			error,
			errorClassName,
			disabled,
			withErrorStyle,
			className,
			label,
			onChange,
			onValueChange,
			negative,
			smallSearch,
			...rest
		},
		ref,
	) => {
		const [showPassword, setShowPassword] = useState(false);

		const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
			if (onValueChange) onValueChange(e.target.value);
			if (onChange) onChange(e);
		};

		const renderInput = () => {
			const onShowToggleClick = () => setShowPassword((prev) => !prev);

			switch (type) {
				case 'password':
					return (
						<div className={styles['password-wrapper']}>
							<input
								className={styles.input}
								type={showPassword ? 'text' : 'password'}
								onChange={onChangeHandler}
								{...{
									ref,
									name,
									id,
									placeholder,
									value,
									defaultValue,
									disabled,
									...rest,
								}}
							/>

							{value ? (
								<span
									className={classNames(
										styles['show-toggle'],
										showPassword ? styles.show : styles.hide,
									)}
									onClick={onShowToggleClick}
								></span>
							) : null}
						</div>
					);
				case 'search':
					return (
						<div
							className={classNames(
								styles['search-wrapper'],
								smallSearch ? styles.small : '',
							)}
						>
							<input
								className={classNames(styles.input, styles.search)}
								onChange={onChangeHandler}
								{...{
									ref,
									type,
									name,
									id,
									placeholder,
									value,
									defaultValue,
									disabled,
									...rest,
								}}
							/>
						</div>
					);
				default:
					return (
						<input
							className={styles.input}
							onChange={onChangeHandler}
							{...{
								ref,
								type,
								name,
								id,
								placeholder,
								value,
								defaultValue,
								disabled,
								...rest,
							}}
						/>
					);
			}
		};

		return (
			<div
				className={classNames(
					className,
					negative ? styles.negative : '',
					withErrorStyle ? styles.error : '',
					disabled ? styles.disabled : '',
				)}
			>
				{label ? (
					<label className={styles.label} htmlFor={id}>
						{label}
					</label>
				) : (
					''
				)}

				{renderInput()}

				{error ? (
					<Error disabled={disabled} message={error} className={errorClassName} />
				) : null}
			</div>
		);
	},
);

export default Input;
