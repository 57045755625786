import { FC } from 'react';
import styles from './SearchFilter.module.scss';
import ISearchFilter from './ISearchFilter';
import Input from '../../form/input/Input';
import classNames from 'classnames';
import { getSearchHandlerByProperty } from '../../../../utils/helpers/common';

const SearchFilter: FC<ISearchFilter> = ({
	placeholder,
	setFilters,
	properties,
	onValueChange,
	className,
	disabled,
}) => {
	const onSearchValueChange = (value: string) => {
		const debounced = setTimeout(() => {
			if (!value) {
				setFilters((prev: any) => ({
					...prev,
					search: (dataRecord: any) => true,
				}));
			} else {
				setFilters((prev: any) => ({
					...prev,
					search: (dataRecord: any) => {
						return properties.some((property) => {
							const propertyValue = dataRecord[property];

							if (typeof propertyValue === 'string') {
								return propertyValue.toLowerCase().includes(value.toLowerCase());
							} else {
								const result = getSearchHandlerByProperty(property, propertyValue, value);
								return result;
							}
						});
					},
				}));
			}

			if (onValueChange) onValueChange(value);
			clearTimeout(debounced);
		}, 1000);
	};

	return (
		<Input
			disabled={disabled}
			type="search"
			placeholder={placeholder || 'Search...'}
			className={classNames(styles.search, className)}
			onValueChange={onSearchValueChange}
		/>
	);
};

export default SearchFilter;
