import React, { FC, useEffect, useState } from 'react';
import styles from './Frameworks.module.scss';
import BasicPanel from '../../components/primitives/basic-panel/BasicPanel';
import ColoredTag from '../../components/primitives/tag/ColoredTag';
import ReadMore from '../../components/primitives/read-more/ReadMore';
import Drawer from '../../components/primitives/drawer/Drawer';
import { ActionsBlock, Button } from '../../components/primitives';
import { CommonColors } from '../../utils/helpers/constants';
import {
	IFramework,
	makeCrosswalkEvaluation,
} from '../../services/store/slices/frameworks.slice';
import useFrameworks from '../../utils/helpers/hooks/useFrameworks';
import classNames from 'classnames';
import Scrollbar from '../../components/primitives/scrollbar/Scrollbar';
import FrameworksSkeleton from './FrameworksSkeleton';
import { Alert } from '../../components/';
import { CommonResultStatusType } from '../../utils/types';
import { useAppDispatch } from '../../services/store';
import { handleWithTryCatch } from '../../utils/helpers/errors';
import useCompany from '../../utils/helpers/hooks/useCompany';
import { useFileDownload } from '../../utils/helpers/hooks/useFileDownload';
import PrimaryFrameworkReporting from '../../components/partials/primary-framework-reporting/PrimaryFrameworkReporting';

const Frameworks: FC = () => {
	const dispatch = useAppDispatch();
	const { info: companyInfo } = useCompany();
	const { items: frameworkItems } = useFrameworks();
	const [openInfo, setOpenInfo] = useState(false);
	const [currentFramework, setCurrentFramework] = useState<IFramework>();
	const [loading, setLoading] = useState(true);
	const [isRunningId, setIsRunningId] = useState('');

	const [crosswalkEvaluationResult, setCrosswalkEvaluationResult] =
		useState<CommonResultStatusType>('');
	const [primaryFrameworkReportResult, setPrimaryFrameworkReportResult] =
		useState<CommonResultStatusType>('');

	const { setDownloadResult, downloadResult, downloadFileByUrl } = useFileDownload();

	const onRunCrosswalkEvaluation = (framework: IFramework) => {
		setIsRunningId(framework.id || '');

		handleWithTryCatch(
			async () => {
				const fileUrl = await dispatch(
					makeCrosswalkEvaluation(
						companyInfo?.primaryFrameworkId || '',
						framework.id || '',
					),
				);

				await downloadFileByUrl(fileUrl, `${framework.name}_crosswalk_evaluation.pdf`);
				setIsRunningId('');
				setCrosswalkEvaluationResult('success');
			},
			undefined,
			() => {
				setIsRunningId('');
				setCrosswalkEvaluationResult('error');
			},
		);
	};

	const renderBottomSection = (framework: IFramework) => {
		return (
			<>
				{framework.isPrimary || framework.evaluationEnabled ? (
					<div className={styles.bottom}>
						{framework.isPrimary ? <PrimaryFrameworkReporting /> : null}

						{framework.evaluationEnabled && !framework.isPrimary ? (
							<ActionsBlock className={styles.actions}>
								<Button
									className={classNames(
										styles.action,
										isRunningId && framework.id === isRunningId
											? styles['with-loader']
											: '',
									)}
									onClick={() => onRunCrosswalkEvaluation(framework)}
									disabled={!!isRunningId}
								>
									{isRunningId && framework.id === isRunningId
										? 'Running Crosswalk Evaluation...'
										: 'Run Crosswalk Evaluation'}
								</Button>
							</ActionsBlock>
						) : null}
					</div>
				) : null}
			</>
		);
	};

	const renderFrameworks = () => {
		const frameworksByPairs = frameworkItems?.reduce((acc, framework, index) => {
			if (index % 2 === 0) acc.push([framework]);
			else acc[acc.length - 1].push(framework);

			return acc;
		}, [] as any[][]);

		return (
			<Scrollbar className={styles.scrollbar}>
				<div className={styles.frameworks}>
					{frameworksByPairs?.map((pair: IFramework[]) => {
						const withActions = pair.some(
							(framework: IFramework) =>
								framework.isPrimary || framework.evaluationEnabled,
						);
						const descriptionLineClamp = (framework: IFramework) => {
							if (withActions) {
								if (framework.isPrimary) return styles['clamp-5'];
								if (framework.evaluationEnabled) return styles['clamp-7'];
								return styles['clamp-11'];
							}

							return styles['clamp-8'];
						};

						return (
							<>
								{pair.map((framework: IFramework) => (
									<BasicPanel key={framework.name} className={styles.framework}>
										<div className={styles.main}>
											{framework.isPrimary ? (
												<ColoredTag
													tagId="primary"
													className={styles.tag}
													bgColor={CommonColors.primaryFramework}
													text="Primary"
												/>
											) : null}

											<div
												className={classNames(
													styles.title,
													framework.isPrimary ? styles['with-tag'] : '',
												)}
											>
												{framework.logo ? (
													<img src={framework.logo} alt="F" />
												) : null}

												<h3>{framework.name}</h3>
											</div>

											<p
												className={classNames(
													styles.description,
													descriptionLineClamp(framework),
												)}
											>
												{framework.description}
											</p>

											<ReadMore
												className={styles.more}
												onClickHandler={() => {
													setCurrentFramework(framework);
													setOpenInfo(true);
												}}
											/>
										</div>

										{renderBottomSection(framework)}
									</BasicPanel>
								))}
							</>
						);
					})}
				</div>
			</Scrollbar>
		);
	};

	const infoSection = (
		<Drawer
			open={openInfo}
			onCloseClickHandler={() => setOpenInfo(false)}
			title={currentFramework?.name}
		>
			{openInfo ? (
				<div className={styles.details}>{currentFramework?.description}</div>
			) : null}
		</Drawer>
	);

	const alerts = (
		<>
			<Alert
				uniqueKey={'crosswalk-success'}
				show={crosswalkEvaluationResult === 'success'}
				type="success"
				message="Crosswalk evaluation complete"
				clearActionStatus={() => setCrosswalkEvaluationResult('')}
			/>

			<Alert
				uniqueKey={'crosswalk-error'}
				show={crosswalkEvaluationResult === 'error'}
				type="error"
				message="Error while getting crosswalk evaluation. Please try again."
				clearActionStatus={() => setCrosswalkEvaluationResult('')}
			/>

			<Alert
				uniqueKey={'download-success'}
				show={downloadResult === 'success'}
				type="success"
				message="Report downloaded"
				clearActionStatus={() => setDownloadResult('')}
			/>

			<Alert
				uniqueKey={'download-error'}
				show={downloadResult === 'error'}
				type="error"
				message={"Report wasn't downloaded. Please try again"}
				clearActionStatus={() => setDownloadResult('')}
			/>

			<Alert
				uniqueKey={'primary-report-success'}
				show={primaryFrameworkReportResult === 'success'}
				type="success"
				message="Primary framework report complete"
				clearActionStatus={() => setPrimaryFrameworkReportResult('')}
			/>

			<Alert
				uniqueKey={'primary-report-error'}
				show={primaryFrameworkReportResult === 'error'}
				type="error"
				message="Error while getting report. Please try again."
				clearActionStatus={() => setPrimaryFrameworkReportResult('')}
			/>
		</>
	);

	useEffect(() => {
		if (frameworkItems) setLoading(false);
	}, [frameworkItems]);

	return (
		<>
			<h2>Frameworks</h2>

			{loading ? (
				<FrameworksSkeleton />
			) : (
				<>
					{frameworkItems?.length ? renderFrameworks() : null}

					{infoSection}

					{alerts}
				</>
			)}
		</>
	);
};

export default Frameworks;
