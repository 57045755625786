import { FC, useCallback, useEffect, useState } from 'react';
import styles from './EditProfile.module.scss';
import Input from '../../../../primitives/form/input/Input';
import { Button, ActionsBlock } from '../../../../primitives';
import { useForm } from 'react-hook-form';
import IEditProfile from './IEditProfile';
import useUser from '../../../../../utils/helpers/hooks/useUser';
import { useAppDispatch } from '../../../../../services/store';
import { IUser, updateUserInfo } from '../../../../../services/store/slices/user.slice';
import { handleWithTryCatch } from '../../../../../utils/helpers/errors';
import {
	requireValidationPattern,
	//emailValidationPattern,
	nameValidationPattern,
} from '../../../../../utils/helpers/common/form';
import Drawer from '../../../../primitives/drawer/Drawer';
import ModalLeaveWarning from '../../../modals/modal-leave-warning/ModalLeaveWarning';
import useLeaveWarning from '../../../../../utils/helpers/hooks/useLeaveWarning';
import { getUserRoleDisplay, sanitizeData } from '../../../../../utils/helpers/common';
import Loader from '../../../../primitives/loader/Loader';

const EditProfile: FC<IEditProfile> = ({ closeHandler, onUpdateError, onUpdateSuccess, open }) => {
	const dispatch = useAppDispatch();
	const { info, loading } = useUser();
	const { firstName, lastName, email, title, role } = info || {};

	const {
		register,
		handleSubmit,
		getValues,
		reset,
		formState: { errors, isValid },
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			firstName,
			lastName,
			title,
		},
	});

	const [warningModalOpen, setWarningModalOpen] = useState(false);
	const [showBrowserLeaveWarning, setBrowserShowLeaveWarning] = useState(false);

	const onFormSubmitHandler = async (data: Partial<IUser>) => {
		if (Object.keys(errors).length) return;

		const sanitizedData = sanitizeData(data);

		handleWithTryCatch(
			async () => {
				//TODO: change to this when email is changable {...newData, email: newData.email.trim()}
				await dispatch(updateUserInfo(sanitizedData)); 
				
				onUpdateSuccess();
			},
			undefined,
			onUpdateError,
		);
	};

	const formActions = (
		<ActionsBlock>
			<Button
				type="button"
				width={136}
				onClick={() => {
					if (changesWereMade()) setWarningModalOpen(true);
					else closeHandler();
				}}
				negative
				disabled={loading}
			>
				Cancel
			</Button>

			<Button type="submit" width={136} disabled={loading || !isValid}>
				{loading ? <Loader thin maxHeight={14} maxWidth={14} /> : 'Save'}
			</Button>
		</ActionsBlock>
	);

	const editForm = (
		<div className={styles['edit-profile']}>
			<form onSubmit={handleSubmit(onFormSubmitHandler)}>
				<div className={styles.inputs}>
					<Input
						{...register('firstName', {
							...requireValidationPattern(),
							...nameValidationPattern(),
						})}
						defaultValue={firstName}
						className={styles.input}
						withErrorStyle={!!errors.firstName}
						type="text"
						label="First Name"
					/>

					<Input
						{...register('lastName', {
							...requireValidationPattern(),
							...nameValidationPattern(),
						})}
						defaultValue={lastName}
						className={styles.input}
						withErrorStyle={!!errors.lastName}
						type="text"
						label="Last Name"
					/>

					<Input
						disabled
						// {...register('email', {
						// 	...requireValidationPattern(),
						// 	...emailValidationPattern(),
						// })}
						value={email}
						//withErrorStyle={!!errors.email}
						className={styles.input}
						type="text"
						label="Email"
					/>

					<Input
						{...register('title', { ...nameValidationPattern() })}
						defaultValue={title}
						className={styles.input}
						withErrorStyle={!!errors.title}
						type="text"
						label="Title"
					/>

					<Input
						type="text"
						name="role"
						disabled
						value={getUserRoleDisplay(role)}
						className={styles.input}
						label="User Role"
					/>
				</div>

				{formActions}
			</form>
		</div>
	);

	const changesWereMade = useCallback(() => {
		if (!email) return false;

		const currentValues = getValues();

		return (
			currentValues.firstName !== firstName ||
			currentValues.lastName !== lastName ||
			//currentValues.email !== email ||
			currentValues.title !== title
		);
	}, [getValues, firstName, lastName, email, title]);

	useLeaveWarning(showBrowserLeaveWarning);

	useEffect(() => {
		if (changesWereMade()) setBrowserShowLeaveWarning(true);
	}, [changesWereMade]);

	useEffect(() => {
		if (!open) {
			reset({}, { keepValues: false });
			setBrowserShowLeaveWarning(false);
		}
	}, [open, reset]);

	return (
		<Drawer
			open={open}
			title="Personal Information"
			onCloseClickHandler={() => {
				if (changesWereMade()) setWarningModalOpen(true);
				else closeHandler();
			}}
		>
			{open ? editForm : null}

			<ModalLeaveWarning
				open={warningModalOpen}
				setOpen={setWarningModalOpen}
				onConfirm={() => {
					setBrowserShowLeaveWarning(false);
					closeHandler();
				}}
			/>
		</Drawer>
	);
};

export default EditProfile;
