import React, { FC, useEffect, useState } from 'react';
import ISelect from './ISelect';
import styles from './Select.module.scss';
import Error from '../../error/Error';
import classNames from 'classnames';
import { DropdownList as RWDropdownList } from 'react-widgets';
import { ISelectOption } from '../../../../utils/types';
import OptionsListComponentOverride from './options-list-component-override/OptionsListComponentOverride';
import Loader from '../../loader/Loader';

const Select: FC<ISelect> = ({
	name,
	id,
	defaultValue,
	value,
	onValueChange,
	options,
	groupedBy,
	error,
	className,
	label,
	labelClassName,
	withEmptyOption,
	emptyOptionLabel,
	placeholder,
	disabled,
	onSearchHandler,
	withErrorStyle,
	withInTableStyle,
	withFilter = false,
	smallCarret = false,
	labelWithLoader = false,
}) => {
	const [isOpened, setIsOpened] = useState(false);
	const [selectedOption, setSelectedOption] = useState('');

	const onSelectChange = (option: ISelectOption) => {
		setSelectedOption(option.value);
		if (onValueChange) onValueChange(option.value);
		setIsOpened(false);
	};

	useEffect(() => {
		setSelectedOption((value as string) || (defaultValue as string));
	}, [defaultValue, value]);

	return (
		<div
			className={classNames(
				styles['select-wrapper'],
				className,
				withErrorStyle ? styles.error : '',
			)}
		>
			{label ? (
				<label htmlFor={id} className={classNames(styles.label, labelClassName)}>
					{label}

					{labelWithLoader ? (
						<Loader className={styles.loader} thin maxHeight={10} maxWidth={10} />
					) : null}
				</label>
			) : null}

			<RWDropdownList
				{...{ name, id, disabled }}
				className={classNames(styles.select, withErrorStyle ? styles.error : '')}
				value={selectedOption}
				defaultValue={selectedOption}
				onChange={onSelectChange}
				groupBy={groupedBy}
				//@ts-ignore
				data={
					withEmptyOption
						? [{ value: '', label: emptyOptionLabel || '' }, ...options]
						: options
				}
				dataKey={'value'}
				textField={'label'}
				placeholder={placeholder || 'Select'}
				filter={withFilter}
				onSearch={onSearchHandler}
				listComponent={({ data }) => (
					<OptionsListComponentOverride
						selectId={id}
						selectedOption={selectedOption}
						options={data}
						onSelectChangeHandler={onSelectChange}
					/>
				)}
				popupComponent={(props) => (
					<div
						{...props}
						className={classNames(
							styles['select-popup'],
							isOpened ? styles.opened : '',
							withInTableStyle ? styles['in-table'] : '',
						)}
					/>
				)}
				containerClassName={classNames(
					styles['select-input'],
					isOpened ? styles.opened : '',
					smallCarret ? styles['small-carret'] : '',
					withInTableStyle ? styles['in-table'] : '',
				)}
				onToggle={setIsOpened}
				open={isOpened}
			/>

			{error ? <Error disabled={disabled} message={error} /> : null}
		</div>
	);
};

export default Select;
