import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './ImagePicker.module.scss';
import IImagePicker from './IImagePicker';
import classNames from 'classnames';
import { Alert, Button } from '../';
import { useDropzone } from 'react-dropzone';
import ProgressBar from '../progress-bar/ProgressBar';
import { useUploadForm } from '../../../utils/helpers/hooks/useUploadForm';
import { CommonResultStatusType } from '../../../utils/types';
import { useAppDispatch } from '../../../services/store';
import { getPreassignedUrlForLogo } from '../../../services/store/slices/company.slice';
import { handleWithTryCatch } from '../../../utils/helpers/errors';

const ImagePicker: FC<IImagePicker> = ({ className, setAcceptedFileId, existingFileUrl }) => {
	const dispatch = useAppDispatch();
	const [currentFile, setCurrentFile] = useState<File | null>(null);
	const [existingLogoUrl, setExistingLogoUrl] = useState(existingFileUrl);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [fileUploadResult, setFileUploadResult] = useState<CommonResultStatusType>('');

	const { isSuccess, isFailed, uploadForm, progress, uploadedFileId, clearStatuses } =
		useUploadForm();

	const { getRootProps, getInputProps, open } = useDropzone({
		noClick: true,
		noDrag: true,
		accept: {
			'image/*': [],
		},
		multiple: false,
		maxFiles: 1,
		onDrop: (acceptedFiles: File[]) => {
			clearStatuses();
			const file: File = acceptedFiles[0];
			setCurrentFile(
				Object.assign(file, {
					preview: URL.createObjectURL(file),
				}),
			);
		},
		onError: (error: any) => setError(error.cause),
	});

	const onImageUpload = useCallback(async () => {
		if (currentFile) {
			handleWithTryCatch(async () => {
				const transformedFileName = encodeURIComponent(currentFile.name);
				const preassignedUrl = await dispatch(getPreassignedUrlForLogo(transformedFileName));

				if (preassignedUrl?.signedUrl) {
					await uploadForm(preassignedUrl.file.id, currentFile, preassignedUrl.signedUrl);
				}
			}, setError);
		}
	}, [currentFile, dispatch, uploadForm]);

	const progressDisplay = useMemo(
		() => (
			<div className={styles['progress-wrap']}>
				<div className={styles.percent}>{progress}%</div>
				<ProgressBar className={styles.progress} progressPercent={progress} />
			</div>
		),
		[progress],
	);

	const renderImagePreview = () => {
		if (currentFile) {
			return (
				<div className={classNames(styles.preview, loading ? styles.uploading : '')}>
					<img
						alt="img"
						src={(currentFile as any)?.preview}
						className={styles.image}
						onLoad={() => URL.revokeObjectURL((currentFile as any)?.preview)}
					/>

					{loading ? progressDisplay : null}
				</div>
			);
		}

		if (existingLogoUrl) {
			return (
				<div className={styles.preview}>
					<img alt="img" src={existingLogoUrl} className={styles.image} />
				</div>
			);
		}
	};

	const onImageDelete = () => {
		clearStatuses();
		setCurrentFile(null);
		setExistingLogoUrl('');
		if (setAcceptedFileId) setAcceptedFileId(null);
	};

	useEffect(() => {
		if (currentFile && !loading && !isFailed && !isSuccess) {
			setLoading(true);
			onImageUpload();
		}
	}, [currentFile, isFailed, isSuccess, loading, onImageUpload]);

	useEffect(() => {
		if (uploadedFileId && setAcceptedFileId) {
			setAcceptedFileId(uploadedFileId);
			setLoading(false);
		}
	}, [setAcceptedFileId, uploadedFileId]);

	useEffect(() => {
		return () => {
			if (uploadedFileId) URL.revokeObjectURL((currentFile as any)?.preview);
		};
	}, [currentFile, uploadedFileId]);

	useEffect(() => {
		if (isFailed) {
			setCurrentFile(null);
			setFileUploadResult('error');
			setLoading(false);
		}
	}, [isFailed]);

	return (
		<div className={classNames(styles['image-picker'], className)}>
			<div className={styles['logo-group']}>
				<div
					className={classNames(
						styles.logo,
						currentFile || existingLogoUrl ? styles.hide : '',
						error || isFailed ? styles.error : '',
					)}
				>
					{renderImagePreview()}
				</div>

				<div className={styles.upload}>
					<div {...getRootProps()} className={styles.actions}>
						<input {...getInputProps()} />

						<Button small negative width={110} type="button" onClick={open}>
							{loading ? 'Uploading...' : 'Upload logo'}
						</Button>

						{currentFile || existingLogoUrl ? (
							<Button
								small
								negative
								errorStyled
								className={styles.delete}
								width={71}
								type="button"
								onClick={onImageDelete}
							>
								Delete
							</Button>
						) : null}
					</div>

					<span className={classNames(styles.text, error ? styles.error : '')}>
						{error || isFailed
							? 'Error occurred while uploading an logo. Please try again'
							: 'You can upload .png or .jpeg file up to 5MB in size'}
					</span>
				</div>

				<Alert
					uniqueKey={'logo-error'}
					show={fileUploadResult === 'error'}
					type="error"
					message="Error occurred while uploading logo file"
					clearActionStatus={() => setFileUploadResult('')}
				/>
			</div>
		</div>
	);
};

export default ImagePicker;
