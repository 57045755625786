import { useEffect } from 'react';
import useUser from './useUser';
import { useAppDispatch } from '../../../services/store';
import { getTags } from '../../../services/store/slices/tags.slice';
import { handleWithTryCatch } from '../errors';

export default function useGetTags(skip: boolean = false) {
	const dispatch = useAppDispatch();
	const { authorized } = useUser();

	useEffect(() => {
		if (skip) return;

		if (authorized) {
			handleWithTryCatch(() => dispatch(getTags()));
		}
	}, [authorized, dispatch, skip]);
}
