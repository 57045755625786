import React, { FC } from 'react';
import styles from './SelectedNumber.module.scss';
import ISelectedNumber from './ISelectedNumber';
import classNames from 'classnames';

const SelectedNumber: FC<ISelectedNumber> = ({ className, number, text }) => {
	if (!number) return null;

	return (
		<span className={classNames(styles.selected, className)}>
			{text || 'Selected: '} {number}
		</span>
	);
};

export default React.memo(SelectedNumber);
