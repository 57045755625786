import { FC, useEffect, useState } from 'react';
import IArchivedPolicyDetails from './IArchivedPolicyDetails';
import styles from './ArchivedPolicyDetails.module.scss';
import SectionTitle from '../../../../primitives/section-title/SectionTitle';
import CollapsiblePanel from '../../../../primitives/collapsible-panel/CollapsiblePanel';
import DataList from '../../../../primitives/data-list/DataList';
import DataListItem from '../../../../primitives/data-list-item/DataListItem';
import TagsAndControlsBlock from '../../../tags-and-controls-block/TagsAndControlsBlock';
import { ActionsBlock, Button } from '../../../../primitives';
import useUser from '../../../../../utils/helpers/hooks/useUser';
import { handleWithTryCatch } from '../../../../../utils/helpers/errors';
import { useAppDispatch } from '../../../../../services/store';
import {
	IPolicy,
	getPolicyById,
	unarchivePolicy,
} from '../../../../../services/store/slices/policies.slice';
import { CommonResultStatusType } from '../../../../../utils/types';
import { Alert } from '../../../../';
import usePolicies from '../../../../../utils/helpers/hooks/usePolicies';
import Loader from '../../../../primitives/loader/Loader';
import ArchivedPolicyDetailsSkeleton from './ArchivedPolicyDetailsSkeleton';
import Error from '../../../../primitives/error/Error';

const ArchivedPolicyDetails: FC<IArchivedPolicyDetails> = ({ policyId, onUnarchive }) => {
	const dispatch = useAppDispatch();
	const { isAdmin } = useUser();
	const { policyLoading } = usePolicies();

	const [currentPolicy, setCurrentPolicy] = useState<IPolicy | null>();
	const policyControls = currentPolicy?.controls;
	const policyTags = currentPolicy?.tags;

	const [unarchiveResult, setUnarchiveResult] = useState<CommonResultStatusType>('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	const renderDetails = () => {
		const trigger = <SectionTitle className={styles.details}>Details</SectionTitle>;

		return (
			<CollapsiblePanel trigger={trigger}>
				<DataList className={styles['policy-info']}>
					<DataListItem label="Name" text={currentPolicy?.name} />
					<DataListItem label="Version" text={currentPolicy?.version} />
				</DataList>
			</CollapsiblePanel>
		);
	};

	const renderTagsAndControls = () => {
		const trigger = (
			<SectionTitle className={styles['collapsible-trigger']}>Tags and Controls</SectionTitle>
		);

		return (
			<CollapsiblePanel trigger={trigger} isOpened>
				<TagsAndControlsBlock
					entityControlIds={policyControls || []}
					entityTagIds={policyTags || []}
					isViewMode
				/>
			</CollapsiblePanel>
		);
	};

	const onReturnToLibrary = () => {
		handleWithTryCatch(
			async () => {
				await dispatch(unarchivePolicy(currentPolicy?.id || ''));
				setUnarchiveResult('success');
				onUnarchive(currentPolicy?.id || '');
			},
			undefined,
			() => setUnarchiveResult('error'),
		);
	};

	const alerts = (
		<Alert
			uniqueKey={'unarchived-error'}
			show={unarchiveResult === 'error'}
			type="error"
			message="Error while policy unarchive."
			clearActionStatus={() => setUnarchiveResult('')}
		/>
	);

	useEffect(() => {
		if (!currentPolicy && policyId) setLoading(true);
	}, [currentPolicy, policyId]);

	useEffect(() => {
		if (policyId) setError('');
	}, [policyId]);

	useEffect(() => {
		if (policyId && !currentPolicy && !loading && !error) {
			handleWithTryCatch(async () => {
				const policy = await dispatch(getPolicyById(policyId));
				setCurrentPolicy(policy);
				setLoading(false);
			}, setError);
		}
	}, [currentPolicy, dispatch, error, loading, policyId]);

	useEffect(() => {
		if (error) setLoading(false);
	}, [error]);

	return (
		<div className={styles['policy-details']}>
			{error ? <Error message={error} /> : null}

			{loading ? <ArchivedPolicyDetailsSkeleton /> : null}

			{!loading ? (
				<>
					<div className={styles.section}>
						{renderDetails()}

						{renderTagsAndControls()}
					</div>

					{isAdmin ? (
						<ActionsBlock className={styles.actions}>
							<Button
								width={241}
								type="button"
								negative
								disabled={policyLoading}
								onClick={onReturnToLibrary}
							>
								{policyLoading ? (
									<Loader thin maxHeight={14} maxWidth={14} />
								) : (
									'Return to Company Library'
								)}
							</Button>
						</ActionsBlock>
					) : null}

					{alerts}
				</>
			) : null}
		</div>
	);
};

export default ArchivedPolicyDetails;
