import { useEffect, useState } from 'react';
import IUseDevice, { DeviceType } from './IUseDevice';

const devicesSettings: IUseDevice[] = [
	{
		device: 'largeDesktop',
		resolution: 1920,
	},
	{
		device: 'mediumDesktop',
		resolution: 1440,
	},
	{
		device: 'smallDesktop',
		resolution: 1280,
	},
];

export default function useDevice() {
	const [activeDevice, setActiveDevice] = useState<DeviceType | null>(null);

	const getDevice = (windowWidth: number) => {
		const deviceObj: IUseDevice | undefined = devicesSettings.find(
			({ resolution }) => windowWidth >= resolution,
		);

		if (deviceObj) return deviceObj.device;
		else return null;
	};

	const getWindowOuterWidth = () => {
		const windowWidth = window.innerWidth;
		const device = getDevice(windowWidth);

		if (device) setActiveDevice(device);
	};

	useEffect(() => {
		getWindowOuterWidth();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		window.addEventListener('resize', getWindowOuterWidth);

		return () => window.removeEventListener('resize', getWindowOuterWidth);
		// eslint-disable-next-line
	}, []);

	return activeDevice;
}
