import React, { useEffect } from 'react';
import BasicPanel from '../../components/primitives/basic-panel/BasicPanel';
import styles from './Home.module.scss';
import useUser from '../../utils/helpers/hooks/useUser';
import HomeSkeleton from './HomeSkeleton';
import RecentTags from '../../components/partials/tables/recent-tags/RecentTags';
import classNames from 'classnames';
import UserTasksBriefTable from '../../components/partials/tables/user-tasks-brief-table/UserTasksBriefTable';
import PrimaryFrameworkCard from '../../components/partials/primary-framework-card/PrimaryFrameworkCard';
import Scrollbar from '../../components/primitives/scrollbar/Scrollbar';
import UserRecentUpdates from '../../components/partials/user-recent-updates/UserRecentUpdates';
import useFrameworks from '../../utils/helpers/hooks/useFrameworks';
import GSUpdatesBrief from '../../components/partials/gs-updates-brief/GSUpdatesBrief';
import { useAppDispatch } from '../../services/store';
import { checkIfOnboarded } from '../../services/store/slices/company.slice';

const Home = () => {
	const dispatch = useAppDispatch();
	const { info: userInfo, isAdmin } = useUser();
	const { items: frameworkItems } = useFrameworks();

	const homeMain = (
		<Scrollbar className={styles.scrollbar}>
			<div className={styles.panels}>
				{isAdmin ? (
					<BasicPanel className={classNames(styles['basic-panel'], styles.framework)}>
						<PrimaryFrameworkCard />
					</BasicPanel>
				) : null}

				{isAdmin ? (
					<BasicPanel className={classNames(styles['basic-panel'], styles.tags)}>
						<RecentTags />
					</BasicPanel>
				) : null}

				<BasicPanel className={classNames(styles['basic-panel'], styles.tasks)}>
					<UserTasksBriefTable />
				</BasicPanel>

				<BasicPanel className={classNames(styles['basic-panel'], styles.updates)}>
					<UserRecentUpdates />
				</BasicPanel>

				<BasicPanel className={classNames(styles['basic-panel'], styles['gs-updates'])}>
					<GSUpdatesBrief />
				</BasicPanel>
			</div>
		</Scrollbar>
	);

	useEffect(() => {
		dispatch(checkIfOnboarded());
	}, [dispatch]);

	return (
		<>
			<h2>Home</h2>

			{!userInfo || !frameworkItems ? <HomeSkeleton /> : homeMain}
		</>
	);
};

export default Home;
