import { FC } from 'react';
import styles from './ModalDeleteShareholder.module.scss';
import Modal from '../../../primitives/modal/Modal';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import { ActionsBlock, Button } from '../../../primitives';
import IModalDeleteShareholder from './IModalDeleteShareholder';
import { useAppDispatch } from '../../../../services/store';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import { deleteCompanyUser } from '../../../../services/store/slices/company-users.slice';
import useCompanyUsers from '../../../../utils/helpers/hooks/useCompanyUsers';
import Loader from '../../../primitives/loader/Loader';

const ModalDeleteShareholder: FC<IModalDeleteShareholder> = ({
	open,
	setOpen,
	id,
	name,
	onDeleteError,
	onDeleteSuccess,
}) => {
	const dispatch = useAppDispatch();
	const { loading } = useCompanyUsers();

	const onDelete = () => {
		handleWithTryCatch(
			async () => {
				if (id) {
					await dispatch(deleteCompanyUser(id));
					onDeleteSuccess();
					setOpen(false);
				}
			},
			undefined,
			onDeleteError,
		);
	};

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<h2 className={styles.title}>Delete Shareholder?</h2>

				<p className={styles.text}>
					Are you sure you want to delete <strong>{name}</strong> from shareholders?
				</p>

				<ActionsBlock>
					<Button type="button" width={160} negative onClick={() => setOpen(false)}>
						Cancel
					</Button>

					<Button
						errorStyled
						type="button"
						width={160}
						disabled={loading}
						onClick={onDelete}
					>
						{loading ? (
							<Loader
								color={'#fcedf0'}
								transparentColor="transparent"
								thin
								maxHeight={14}
								maxWidth={14}
							/>
						) : (
							'Delete'
						)}
					</Button>
				</ActionsBlock>
			</BasicPanel>
		</Modal>
	);
};

export default ModalDeleteShareholder;
