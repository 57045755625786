import React from 'react';
import styles from './AnalysisStep.module.scss';

const AnalysisStep = () => {
	return (
		<>
			<h3 className={styles.title}>Doing our magic</h3>

			<p className={styles.info}>
				This could take a few minutes. You can close this page and come back later. We'll
				send you an email notification once it's done.
			</p>
		</>
	);
};

export default AnalysisStep;
