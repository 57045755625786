import React, { FC } from 'react';
import styles from './Pagination.module.scss';
import IPagination from './IPagination';
import classNames from 'classnames';
import ReactPaginate from 'react-paginate';

const Pagination: FC<IPagination> = ({
	className,
	pageCount,
	onPageChangeHandler,
	currentPage,
}) => {
	const onPageChange = (selected: { selected: number }) => {
		onPageChangeHandler(selected.selected + 1);
	};

	return (
		<div className={classNames(styles['pagination-wrap'], className)}>
			<ReactPaginate
				forcePage={currentPage && currentPage - 1}
				onPageChange={onPageChange}
				nextLabel={<div className={styles.next} />}
				previousLabel={<div className={styles.previous} />}
				pageCount={pageCount}
				pageClassName={styles['page-item']}
				pageLinkClassName={styles['page-link']}
				breakClassName={styles['page-item']}
				breakLinkClassName={classNames(styles['page-link'], styles.break)}
				containerClassName={styles['pagination']}
				renderOnZeroPageCount={null}
				disabledClassName={styles.disabled}
				activeLinkClassName={styles.active}
				pageRangeDisplayed={2}
				marginPagesDisplayed={1}
			/>
		</div>
	);
};

export default Pagination;
