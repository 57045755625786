import { FC } from 'react';
import styles from './GSPolicyDetails.module.scss';
import IGSPolicyDetails from './IGSPolicyDetails';
import BaseControlsBriefTable from '../../../tables/base-controls-brief-table/BaseControlsBriefTable';

const GSPolicyDetails: FC<IGSPolicyDetails> = ({ policy }) => {
	return (
		<div className={styles['policy-details']}>
			<h4 className={styles.title}>{policy?.name}</h4>

			{policy?.description ? <p>{policy?.description || ''}</p> : null}

			<BaseControlsBriefTable
				className={styles.table}
				baseControls={policy?.baseControls || []}
			/>
		</div>
	);
};

export default GSPolicyDetails;
