import { FC, useEffect, useState } from 'react';
import styles from './ModalAddAsset.module.scss';
import Modal from '../../../primitives/modal/Modal';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import IModalAddAsset from './IModalAddAsset';
import NameStep from './steps/name-step/NameStep';
import StepsProgress from '../../../primitives/steps-progress/StepsProgress';
import DetailsStep from './steps/details-step/DetailsStep';
import Back from '../../../primitives/back/Back';
import { ICreateAsset } from '../../../../services/store/slices/assets.slice';

const ModalAddAsset: FC<IModalAddAsset> = ({ open, setOpen, onAddSuccess, onAddError }) => {
	const [currentStep, setCurrentStep] = useState('name');
	const [currentFormState, setCurrentFormState] = useState<ICreateAsset>({
		name: '',
		type: '',
		description: '',
		disposition: '',
		sensitivity: '',
		criticality: '',
		ownerId: '',
		companyControls: [],
	});

	const addAssetSteps = [
		{
			stepName: 'name',
			component: (
				<NameStep
					setOpen={setOpen}
					currentFormState={currentFormState}
					setCurrentFormState={setCurrentFormState}
					setNextStep={() => setCurrentStep('details')}
				/>
			),
		},
		{
			stepName: 'details',
			component: (
				<DetailsStep
					setOpen={setOpen}
					currentFormState={currentFormState}
					setCurrentFormState={setCurrentFormState}
					setBackStep={() => setCurrentStep('name')}
					onAddSuccess={onAddSuccess}
					onAddError={onAddError}
				/>
			),
		},
	];

	const activeStepNumber = addAssetSteps.findIndex((step) => step.stepName === currentStep);

	useEffect(() => {
		if (!open) {
			setCurrentStep('name');
			setCurrentFormState({
				name: '',
				type: '',
				description: '',
				disposition: '',
				sensitivity: '',
				criticality: '',
				ownerId: '',
				companyControls: [],
			});
		}
	}, [open]);

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<StepsProgress
					small
					className={styles.progress}
					stepsNumber={addAssetSteps.length}
					activeStep={activeStepNumber}
				/>

				<div className={currentStep === 'details' ? styles.title : ''}>
					{currentStep === 'details' ? (
						<Back className={styles.back} onClick={() => setCurrentStep('name')} />
					) : null}

					<h2>Add Asset</h2>
				</div>

				{addAssetSteps.find((step) => step.stepName === currentStep)?.component}
			</BasicPanel>
		</Modal>
	);
};

export default ModalAddAsset;
