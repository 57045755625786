import { useEffect } from 'react';

export default function useLeaveWarning(stopFromLeaving: boolean) {
	useEffect(() => {
		window.onbeforeunload = (event: any) => {
			if (stopFromLeaving) {
				event.preventDefault();
				return false;
			} else return null;
		};

		return () => {
			window.onbeforeunload = null;
		};
	}, [stopFromLeaving]);
}
