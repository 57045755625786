const EMAIL_PATTERN = /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,20}\s*$/i;
const LOCATION_PATTERN = /^.*?,.*?\bUTC\b.*/;
const VARIABLE_VALUE_PATTERN = /^[a-zA-Z0-9\s:;,-.'"?\\/]*$/;
const COMMON_STRING_PATTERN = /^[a-zA-Z0-9\s!@#$%^&*()-_=+{}[\]:;<>,.'"?\\/]*$/;

export const spacesValidation = (
	message: string = 'The field should have at least one letter',
) => ({
	pattern: {
		value: /.*\S.*/,
		message,
	},
});

export const requireValidationPattern = (
	value: boolean = true,
	message: string = 'This field is required',
) => ({
	required: {
		value,
		message,
	},
});

export const locationValidationPattern = (message: string = 'Enter a valid location') => ({
	pattern: {
		value: LOCATION_PATTERN,
		message,
	},
});

export const emailValidationPattern = (message: string = 'Enter a valid e-mail address') => ({
	pattern: {
		value: EMAIL_PATTERN,
		message,
	},
});

export const minLengthValidationPattern = (
	value: number = 8,
	message: string = 'Field must be at least 8 characters long',
) => ({
	pattern: {
		value: new RegExp(`[0-9]{${value},}`),
		message,
	},
	minLength: {
		value,
		message,
	},
});

export const emailIsValid = (email: string) => {
	return EMAIL_PATTERN.test(email);
};

export const stringIsNotEmpty = (str: string) => {
	return !!str.trim();
};

export const companyNameValidationPattern = (message: string = 'Company name is not valid') => ({
	pattern: {
		value: /^[a-zA-Z0-9\s!@#$%^&*()-_=+{}[\]:;<>,.'"?\\/]*$/,
		message,
	},
});

export const commonStringIsValid = (value?: string) => {
	if (!value) return true;
	return COMMON_STRING_PATTERN.test(value);
};

export const variableValueIsValid = (value: string) => {
	return VARIABLE_VALUE_PATTERN.test(value);
};

export const nameValidationPattern = (
	message: string = "Only alphabetic input space and '-' are accepted",
) => ({
	pattern: {
		value: /^[a-zA-Z\s-]*$/,
		message,
	},
});

export const setTextareaHeight = (
	textareaElement: HTMLTextAreaElement | null,
	wrapper: HTMLDivElement | null,
) => {
	if (!textareaElement || !wrapper) return;

	const style = getComputedStyle(textareaElement, null);

	if (textareaElement.value === '') {
		if (style.minHeight) textareaElement.style.height = style.minHeight;
		if (style.height) wrapper.style.height = style.height;
		textareaElement.style.overflowY = 'hidden';

		return;
	}

	const verticalBorders = Math.round(
		parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth),
	);

	const maxHeight = parseFloat(style.maxHeight) || 136;

	const newHeight = textareaElement.scrollHeight + verticalBorders;

	textareaElement.style.overflowY = newHeight > maxHeight ? 'auto' : 'hidden';
	textareaElement.style.height = Math.min(newHeight, maxHeight) + 'px';
	wrapper.style.height = Math.min(newHeight, maxHeight) + 'px';
};
