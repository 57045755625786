import React, { FC } from "react";
import classNames from "classnames";

import SpinnerInterface from "./ISpinner";

import styles from "./Spinner.module.scss";

const Spinner: FC<SpinnerInterface> = ({
  thin,
  style,
  width,
  height,
  ...rest
}) => {
  const inlineStyles = {
    ...style,
    width,
    height,
  };

  return (
    <div
      style={inlineStyles}
      className={classNames(styles.spinner, thin ? styles.thin : '')}
      {...rest}
    ></div>
  );
};

export default Spinner;
