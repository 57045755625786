import React, { FC, useEffect, useState } from 'react';
import styles from './TagColorPicker.module.scss';
import ITagColorPicker from './ITagColorPicker';
import classNames from 'classnames';
import { AppTagColors } from '../../../utils/helpers/constants';

const TagColorPicker: FC<ITagColorPicker> = ({ className, onColorSelect, selectedColor }) => {
	const [activeColor, setActiveColor] = useState('');

	const onColorClick = (color: string) => {
		setActiveColor(color);
		onColorSelect(color);
	};

	useEffect(() => {
		if (selectedColor) setActiveColor(selectedColor);
	}, [selectedColor]);

	return (
		<div className={styles['color-picker']}>
			{AppTagColors.map((color) => (
				<div
					key={color}
					className={classNames(
						styles.color,
						className,
						activeColor === color ? styles.active : '',
					)}
					style={{ backgroundColor: color }}
					onClick={() => onColorClick(color)}
				/>
			))}
		</div>
	);
};

export default React.memo(TagColorPicker);
