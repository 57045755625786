import { FC, useState } from 'react';
import styles from './ModalAddAssets.module.scss';
import Modal from '../../../primitives/modal/Modal';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import { ActionsBlock, Button } from '../../../primitives';
import IModalAddAssets from './IModalAddAssets';
import useAssets from '../../../../utils/helpers/hooks/useAssets';
import AddAssetsTable from '../../tables/add-assets-table/AddAssetsTable';
import { IIdName } from '../../../../utils/types';
import { sortObjByNameAsc } from '../../../../utils/helpers/common';

const ModalAddAssets: FC<IModalAddAssets> = ({
	open,
	setOpen,
	onNewAdded,
	entityAssetIds,
	setEntityAssets,
}) => {
	const { items: assetItems } = useAssets();

	const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
	const submitIsDisabled = !selectedAssets.length;

	const onAdd = () => {
		const selected = selectedAssets.map((id) => {
			const foundedAsset = assetItems?.find((asset) => asset.id === id);
			return {
				id,
				name: foundedAsset?.name,
				type: foundedAsset?.type,
			} as IIdName;
		});

		const newAssets = entityAssetIds.length
			? sortObjByNameAsc([...entityAssetIds, ...selected])
			: selected;

		onNewAdded(selected);
		setEntityAssets(newAssets);
		setSelectedAssets([]);
		setOpen(false);
	};

	const renderActions = () => {
		return (
			<ActionsBlock className={styles.actions}>
				<Button width={136} type="button" negative onClick={() => setOpen(false)}>
					Cancel
				</Button>

				<Button width={136} type="button" disabled={submitIsDisabled} onClick={onAdd}>
					Add Selected
				</Button>
			</ActionsBlock>
		);
	};

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<div className={styles.top}>
					<h2 className={styles.title}>Add Assets</h2>

					<div className={styles['table-wrap']}>
						<AddAssetsTable
							data={
								assetItems
									?.filter(
										(asset) =>
											!entityAssetIds?.some(
												(entityAsset) => entityAsset.id === asset.id,
											),
									)
									.map((asset) => ({
										id: asset.id,
										name: asset.name,
										type: asset.type,
									})) || []
							}
							selectedRows={selectedAssets}
							setSelectedRows={setSelectedAssets}
						/>
					</div>
				</div>

				{renderActions()}
			</BasicPanel>
		</Modal>
	);
};

export default ModalAddAssets;
