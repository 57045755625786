import { IAssignment } from '../../../../services/store/slices/assignments.slice';
import { ICompanyUser } from '../../../../services/store/slices/company-users.slice';
import { dueDateColumnRenderHelper } from '../../../../utils/helpers/common';
import { AppQueryParams, UserTaskType, UserTaskTypeDisplayName } from '../../../../utils/helpers/constants';
import { AppUrls } from '../../../../utils/helpers/constants/app-routes';
import { IIdName } from '../../../../utils/types';
import ChangableOwnerBlock from '../../../primitives/changable-owner-block/ChangableOwnerBlock';
import { ITableColumn } from '../../../primitives/table/ITable';
import styles from './AssignmentsBriefTable.module.scss';

export const getBriefAssignmentsColumns = (
	onOwnerChangeHandler: (ownerId: string, assignmentId: string) => void,
	navigate: any,
) => {
	const ownersColumn = (owner: ICompanyUser, rowData: IAssignment) => {
		const onOwnerChange = (ownerId: string) => {
			onOwnerChangeHandler(ownerId, rowData.id);
		};

		return (
			<ChangableOwnerBlock
				id={rowData.id}
				initialValue={owner.id || ''}
				onOwnerChangeHandler={onOwnerChange}
			/>
		);
	};

	const onRelatedItemClick = (relatedItem: IIdName, taskType: string) => {
		switch (taskType) {
			case UserTaskType.AssetOwner:
				navigate(`${AppUrls.assets}?${AppQueryParams.item}=${relatedItem.id}`);
				break;

			case UserTaskType.ControlOwner:
				navigate(`${AppUrls.controls}?${AppQueryParams.item}=${relatedItem.id}`);
				break;

			case UserTaskType.PolicyOwner:
			case UserTaskType.PolicyReview:
				navigate(`${AppUrls.policies}?${AppQueryParams.item}=${relatedItem.id}`);
				break;

			default:
				break;
		}
	};

	return [
		{
			header: 'Type',
			key: 'taskType',
			sorting: true,
			width: '25%',
			columnRender: (type: string) =>
				UserTaskTypeDisplayName[type as keyof typeof UserTaskTypeDisplayName],
		},
		{
			header: 'Related Item',
			key: 'relatedItem',
			sorting: true,
			width: '35%',
			columnRender: (relatedItem: IIdName, rowData: IAssignment) => (
				<div className={styles['related-item']}>
					<h4 onClick={() => onRelatedItemClick(relatedItem, rowData.taskType)}>
						{relatedItem.name}
					</h4>
				</div>
			),
		},
		{
			header: 'Owner',
			key: 'owner',
			sorting: true,
			width: '22%',
			columnRender: ownersColumn,
		},
		{
			header: 'Due Date',
			key: 'dueDate',
			sorting: true,
			width: '18%',
			columnRender: (dueDate: string) =>
				dueDateColumnRenderHelper(dueDate, styles['date-tag']),
		},
	] as ITableColumn[];
};
