import React, { FC, PropsWithChildren } from "react";
import styles from "./SidebarLayout.module.scss";
import Sidebar from "../../partials/sidebar/Sidebar";

const SidebarLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles["sidebar-layout"]}>
      <Sidebar />

      <main className={styles.main}>{children}</main>
    </div>
  );
};

export default React.memo(SidebarLayout);
