import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './AssignBlock.module.scss';
import classNames from 'classnames';
import IAssignBlock from './IAssignBlock';
import useUser from '../../../utils/helpers/hooks/useUser';
import Select from '../form/select/Select';
import { getOwnerOptions } from '../../../utils/helpers/common';
import useCompanyUsers from '../../../utils/helpers/hooks/useCompanyUsers';
import Loader from '../loader/Loader';

const AssignBlock: FC<IAssignBlock> = ({
	className,
	onAssign,
	show,
	loading,
	withUnassigned = true,
}) => {
	const { items: companyUsers } = useCompanyUsers();
	const { info: userInfo } = useUser();
	const [selectedOwner, setSelectedOwner] = useState('');

	const ownersOptions = useMemo(
		() => getOwnerOptions(companyUsers, userInfo?.id, withUnassigned),
		[companyUsers, userInfo?.id, withUnassigned],
	);

	useEffect(() => {
		if (!show) setSelectedOwner('');
	}, [show]);

	return (
		<div className={classNames(styles['assign-block'], className, show ? styles.show : '')}>
			Assign selected to
			<Select
				id="assign-owner"
				options={ownersOptions || []}
				value={selectedOwner}
				onValueChange={(ownerId: string) => {
					onAssign(ownerId);
					setSelectedOwner(ownerId);
				}}
				className={styles.select}
				placeholder={'Select Owner'}
				smallCarret
			/>
			{loading ? (
				<Loader thin maxHeight={14} maxWidth={14} className={styles.loader} />
			) : null}
		</div>
	);
};

export default AssignBlock;
