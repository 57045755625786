import { FC } from 'react';
import ITagIncludedAssets from './ITagIncludedAssets';
import ManageAssetsTable from '../tables/manage-assets-table/ManageAssetsTable';

const TagIncludedAssets: FC<ITagIncludedAssets> = ({
	className,
	includedAssetIds,
	setAssetIds,
}) => {
	return (
		<div className={className}>
			<ManageAssetsTable
				bodyMaxHeight={160}
				data={includedAssetIds}
				entityAssetIds={includedAssetIds}
				setEntityAssetIds={setAssetIds}
			/>
		</div>
	);
};

export default TagIncludedAssets;
