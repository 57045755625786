import React, { FC } from 'react';
import styles from './ProgressBar.module.scss';
import IProgressBar from './IProgressBar';
import classNames from 'classnames';

const ProgressBar: FC<IProgressBar> = ({ progressPercent, className }) => {
	return (
		<div className={classNames(styles['progress-bar-wrapper'], className)}>
			<span style={{ width: `${progressPercent}%` }} className={styles.progress}></span>
		</div>
	);
};

export default ProgressBar;
