import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonResponseError } from '../../../utils/helpers/errors/CommonResponseError';
import {
	IChangeHistoryEntry,
	IChangeHistoryEntryBasic,
	IComment,
	ICreateComment,
	IIdName,
} from '../../../utils/types';
import { RootState } from '..';
import { CommonStatuses } from '../../../utils/helpers/constants';
import {
	sortBaseControlObjByIdAsc,
	sortByNameAndThenByIdAsc,
	sortByNameAsc,
} from '../../../utils/helpers/common';
import policiesApi from '../../api/policies.api';
import { ICompanyUser } from './company-users.slice';
import { IFramework } from './frameworks.slice';
import { ITag, ITagBrief } from './tags.slice';

export interface IPoliciesSlice {
	loading?: boolean;
	policyLoading?: boolean;
	updateLoading?: boolean;
	archivationLoading?: boolean;
	copyLoading?: boolean;
	commentsLoading?: boolean;
}

export interface IBasePolicyControl {
	id: string;
	controlId: string;
	enhancementName: string;
}

export interface IBasePolicyBasic {
	id: string;
	name: string;
	description?: string;
	version?: string;
	baseControls?: IBasePolicyControl[];
	frameworkId?: number;
}

export interface IBasePolicy extends Omit<IBasePolicyBasic, 'framework' | 'baseControls'> {
	baseControls?: IIdName[];
	framework?: string;
}

export interface IPolicyBasic {
	id: string;
	name: string;
	status?: string;
	frameworkId?: string;
	version?: string;
	ownerId?: number | null;
	description?: string;
	preamble?: string;
	reviewPeriod?: {};
	history?: IChangeHistoryEntryBasic[];
	companyControlIds?: number[];
	comments: IComment[];
	tags?: Partial<ITag>[];
}

export interface IPolicy {
	id: string;
	name: string;
	description?: string;
	preamble?: string;
	controls?: IIdName[];
	status?: string;
	owner?: ICompanyUser | null;
	framework?: IIdName;
	comments?: IComment[];
	history?: IChangeHistoryEntry[];
	version?: string;
	reviewValue?: string;
	reviewUnit?: string;
	tags?: ITagBrief[];
}

export interface IUpdatePolicy {
	id?: string;
	name?: string;
	status?: string;
	ownerId?: string;
	controls?: IIdName[];
	tags?: ITagBrief[];
	header?: string;
	description?: string;
	preamble?: string;
	footer?: string;
	reviewValue?: string;
	reviewUnit?: string;
}

export interface IPolicies extends IPoliciesSlice {
	items: IPolicy[] | null;
	baseItems: IBasePolicy[] | null;
	archivedItems: IPolicy[] | null;
	baseControls: IIdName[] | null;
}

const initialState: IPolicies = {
	loading: false,
	policyLoading: false,
	updateLoading: false,
	archivationLoading: false,
	commentsLoading: false,
	copyLoading: false,
	items: null,
	baseItems: null,
	archivedItems: null,
	baseControls: null,
};

export const policiesSlice = createSlice({
	name: 'policies',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		policyLoading: (state) => {
			state.policyLoading = true;
		},
		policyLoaded: (state) => {
			state.policyLoading = false;
		},
		updateLoading: (state) => {
			state.updateLoading = true;
		},
		updateLoaded: (state) => {
			state.updateLoading = false;
		},
		archivationLoading: (state) => {
			state.archivationLoading = true;
		},
		archivationLoaded: (state) => {
			state.archivationLoading = false;
		},
		commentsLoading: (state) => {
			state.commentsLoading = true;
		},
		commentsLoaded: (state) => {
			state.commentsLoading = false;
		},
		copyLoading: (state) => {
			state.commentsLoading = true;
		},
		copyLoaded: (state) => {
			state.commentsLoading = false;
		},
		setPolicies: (state, { payload }: PayloadAction<IPolicy[]>) => {
			state.items = payload;
		},
		setBasePolicies: (state, { payload }: PayloadAction<IBasePolicy[]>) => {
			state.baseItems = payload;
		},
		setBaseControls: (state, { payload }: PayloadAction<IIdName[]>) => {
			state.baseControls = payload;
		},
		setArchivedPolicies: (state, { payload }: PayloadAction<IPolicy[]>) => {
			state.archivedItems = payload;
		},
	},
});

export const getBasePolicies = () => async (dispatch: Dispatch<any>, getState: () => RootState) => {
	try {
		const companyPrimaryFramework = getState().company.primaryFramework;

		if (!companyPrimaryFramework?.id) return;

		const basePolicies = await policiesApi.getBasePolicies(companyPrimaryFramework.id);
		const frameworks = getState().frameworks.items;

		const baseControls = basePolicies.reduce((acc: IIdName[], item: IBasePolicyBasic) => {
			const controls = item.baseControls?.map((control: IBasePolicyControl) => {
				return {
					id: control.id.toString(),
					displayId: control.controlId,
					name: control.enhancementName,
				};
			});

			return acc.concat(controls || []);
		}, []);

		const uniqueControls = baseControls.filter(
			(control, index, self) => self.findIndex((c) => c.id === control.id) === index,
		);

		dispatch(setBaseControls(uniqueControls || []));

		const transformedData = basePolicies.map((item) => {
			const frameworkObj = frameworks?.find(
				(framework) => item.frameworkId?.toString() === framework.id,
			);

			const transformedBaseControls = item.baseControls?.map(
				(control: IBasePolicyControl) => {
					return {
						id: control.id.toString(),
						displayId: control.controlId,
						name: control.enhancementName,
					};
				},
			);

			return {
				id: item.id.toString(),
				name: item.name,
				description: item.description,
				framework: {
					id: frameworkObj?.id,
					name: frameworkObj?.name,
				} as IFramework,
				version: item.version,
				baseControls: sortBaseControlObjByIdAsc(transformedBaseControls || []),
			};
		});

		const sortedPolicies = sortByNameAsc(transformedData);

		dispatch(setBasePolicies(sortedPolicies as IBasePolicy[]));
	} catch (error: any) {
		dispatch(setBasePolicies([]));
	}
};

export const getPolicies =
	(withLoading?: boolean) => async (dispatch: Dispatch<any>, getState: () => RootState) => {
		if (withLoading) dispatch(loading());

		try {
			const companyPolicies = await policiesApi.getPolicies();
			const filteredPolicies = companyPolicies.filter(
				(p) => p.status !== CommonStatuses.archived,
			);

			const { info, isAdmin } = getState().user;
			const frameworks = getState().frameworks.items;
			const companyUsers = getState().companyUsers.items;
			const controlItems = getState().controls.items;

			const transformedData = filteredPolicies.map((item) => {
				let userObj: ICompanyUser | undefined | null = null;

				if (isAdmin) {
					userObj = companyUsers?.find((user) => user.id === item.ownerId?.toString());
				} else {
					if (info && item.ownerId?.toString() === info?.id) {
						userObj = {
							id: info.id,
							firstName: info.firstName,
							lastName: info.lastName,
							email: info.email,
							fullName: `${info.firstName} ${info.lastName}`,
						} as ICompanyUser;
					}
				}

				const transformedControls =
					controlItems
						?.filter((control) => item.companyControlIds?.includes(Number(control.id)))
						.map((control) => ({
							id: control.id,
							displayId: control.controlId,
							name: control.name,
						})) || [];

				return {
					id: item.id.toString(),
					name: item.name,
					preamble: item.preamble,
					description: item.description,
					owner: userObj || null,
					status: CommonStatuses[item.status as keyof typeof CommonStatuses],
					framework: item.frameworkId?.toString(),
					version: item.version,
					controls: transformedControls,
					tags: item.tags?.map((t) => ({
						id: t.id?.toString(),
						name: t.name,
						color: t.color,
					})),
				} as IPolicy;
			});

			let itemsToDisplay: IPolicy[] = transformedData?.map((policy) => {
				const framework = frameworks?.find(
					(framework) => framework.id === policy.framework,
				);

				return {
					...policy,
					framework: {
						id: framework?.id,
						name: framework?.name,
					} as IIdName,
				} as IPolicy;
			});

			const sortedPolicies = sortByNameAndThenByIdAsc(itemsToDisplay);

			dispatch(setPolicies(sortedPolicies as IPolicy[]));
		} catch (error: any) {
			dispatch(setPolicies([]));
		} finally {
			dispatch(loaded());
		}
	};

export const getArchivedPolicies =
	() => async (dispatch: Dispatch<any>, getState: () => RootState) => {
		try {
			const companyPolicies = await policiesApi.getArchivedPolicies();

			const { info, isAdmin } = getState().user;
			const frameworks = getState().frameworks.items;
			const controlItems = getState().controls.items;
			const companyUsers = getState().companyUsers.items;

			const transformedData = companyPolicies.map((item) => {
				const transformedControls =
					controlItems
						?.filter((control) => item.companyControlIds?.includes(Number(control.id)))
						.map((control) => ({
							id: control.id,
							displayId: control.controlId,
							name: control.name,
						})) || [];

				let userObj: ICompanyUser | undefined | null = null;

				if (isAdmin) {
					userObj = companyUsers?.find((user) => user.id === item.ownerId?.toString());
				} else {
					if (info && item.ownerId?.toString() === info?.id) {
						userObj = {
							id: info.id,
							firstName: info.firstName,
							lastName: info.lastName,
							email: info.email,
							fullName: `${info.firstName} ${info.lastName}`,
						} as ICompanyUser;
					}
				}

				return {
					id: item.id.toString(),
					name: item.name,
					framework: item.frameworkId?.toString(),
					owner: userObj || null,
					version: item.version,
					controls: transformedControls,
					tags: item.tags?.map((t) => ({
						id: t.id?.toString(),
						name: t.name,
						color: t.color,
					})),
				} as IPolicy;
			});

			let itemsToDisplay: IPolicy[] = transformedData?.map((policy) => {
				const framework = frameworks?.find(
					(framework) => framework.id === policy.framework,
				);

				return {
					...policy,
					framework: {
						id: framework?.id,
						name: framework?.name,
					} as IIdName,
				} as IPolicy;
			});

			const sortedPolicies = sortByNameAndThenByIdAsc(itemsToDisplay);

			dispatch(setArchivedPolicies(sortedPolicies as IPolicy[]));
		} catch (error: any) {
			dispatch(setArchivedPolicies([]));
		}
	};

export const getPolicyById =
	(id: string) => async (dispatch: Dispatch<any>, getState: () => RootState) => {
		dispatch(policyLoading());

		try {
			const { info, isAdmin } = getState().user;
			const { items: companyUsers } = getState().companyUsers;
			const { items: controlItems } = getState().controls;

			const companyPolicy = await policiesApi.getPolicyById(id);

			let userObj: ICompanyUser | undefined | null = null;

			if (isAdmin) {
				userObj = companyUsers?.find(
					(user: ICompanyUser) => user.id === companyPolicy.ownerId?.toString(),
				);
			} else {
				if (info && companyPolicy.ownerId?.toString() === info?.id) {
					userObj = {
						id: info.id,
						firstName: info.firstName,
						lastName: info.lastName,
						email: info.email,
						fullName: `${info.firstName} ${info.lastName}`,
					} as ICompanyUser;
				}
			}

			const transformedControls = controlItems
				?.filter((control) => companyPolicy.companyControlIds?.includes(Number(control.id)))
				.map((control) => ({
					id: control.id,
					displayId: control.controlId,
					name: control.name,
				}));

			const getReviewData = () => {
				const period = companyPolicy.reviewPeriod;

				if (period) {
					const periodUnit = Object.keys(period)[0];
					//@ts-ignore
					const periodValue = period[periodUnit];

					return {
						reviewValue: periodValue,
						reviewUnit: periodUnit,
					};
				}
			};

			return {
				...companyPolicy,
				...getReviewData(),
				id: companyPolicy.id.toString(),
				controls: transformedControls || [],
				owner: userObj || null,
				tags: companyPolicy.tags?.map((t) => ({
					id: t.id?.toString(),
					name: t.name,
					color: t.color,
				})),
			} as IPolicy;
		} catch (error: any) {
			throw new CommonResponseError('Error while getting policy.');
		} finally {
			dispatch(policyLoaded());
		}
	};

export const updatePolicy =
	(policyId: string, data: IUpdatePolicy) => async (dispatch: Dispatch<any>) => {
		if (data.status === CommonStatuses.archived) dispatch(archivationLoading());
		else dispatch(updateLoading());

		try {
			await policiesApi.updatePolicy(policyId, data);

			await dispatch(getPolicies());
			if (data.status === CommonStatuses.archived) await dispatch(getArchivedPolicies());
		} catch (error: any) {
			throw new CommonResponseError('Error while updating policy.');
		} finally {
			dispatch(updateLoaded());
			dispatch(archivationLoaded());
		}
	};

export const unarchivePolicy = (policyId: string) => async (dispatch: Dispatch<any>) => {
	dispatch(policyLoading());

	try {
		await policiesApi.updatePolicyStatus(policyId, CommonStatuses.reviewed);

		await dispatch(getPolicies());
		await dispatch(getArchivedPolicies());
	} catch (error: any) {
		throw new CommonResponseError('Error while updating policy.');
	} finally {
		dispatch(policyLoaded());
	}
};

export const assignPolicies =
	(ownerId: string | null, policyIds: string[]) => async (dispatch: Dispatch<any>) => {
		try {
			await policiesApi.assignPolicies(policyIds, ownerId);

			await dispatch(getPolicies());
		} catch (error: any) {
			throw new CommonResponseError('Error while updating policies owner.');
		}
	};

export const addCommentToPolicy =
	(newComment: ICreateComment) => async (dispatch: Dispatch<any>) => {
		dispatch(commentsLoading());

		try {
			await policiesApi.addComment(newComment);
		} catch (error: any) {
			throw new CommonResponseError('Error while adding comment.');
		} finally {
			dispatch(commentsLoaded());
		}
	};

export const deletePolicyFile = (fileId: string) => async () => {
	try {
		return await policiesApi.deletePolicyFile(fileId);
	} catch (error: any) {
		throw new CommonResponseError('Error while removing policy file.');
	}
};

export const downloadPolicyFile = (policyId: string) => async () => {
	try {
		return await policiesApi.downloadPolicyFile(policyId);
	} catch (error: any) {
		if (error && error.message && error.message.includes('approved'))
			throw new CommonResponseError(
				`We can't process your request because there aren't any approved controls assigned to the policy. Please assign at least one approved control to the policy to download it.`,
			);

		throw new CommonResponseError('Error while downloading policy file.');
	}
};

export const addBasePolicyToCompany = (basePolicyId: string) => async (dispatch: Dispatch<any>) => {
	dispatch(copyLoading());

	try {
		const copiedPolicy = await policiesApi.addBasePolicyToCompany(basePolicyId);
		await dispatch(getPolicies());

		return copiedPolicy;
	} catch (error: any) {
		throw new CommonResponseError('Error while adding base policy to company library.');
	} finally {
		dispatch(copyLoaded());
	}
};

export const {
	loading,
	loaded,
	policyLoading,
	policyLoaded,
	updateLoading,
	updateLoaded,
	archivationLoaded,
	archivationLoading,
	commentsLoading,
	commentsLoaded,
	copyLoading,
	copyLoaded,
	setPolicies,
	setBasePolicies,
	setBaseControls,
	setArchivedPolicies,
} = policiesSlice.actions;
export default policiesSlice.reducer;
