import { FC, useEffect, useMemo, useState } from 'react';
import styles from './ModalAddTag.module.scss';
import Modal from '../../../primitives/modal/Modal';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import { ActionsBlock, Button } from '../../../primitives';
import Select from '../../../primitives/form/select/Select';
import useTags from '../../../../utils/helpers/hooks/useTags';
import IModalAddTag from './IModalAddTag';
import { ITagBrief } from '../../../../services/store/slices/tags.slice';

const ModalAddTag: FC<IModalAddTag> = ({ open, setOpen, onNewAdded, entityTagIds }) => {
	const { items: tagItems } = useTags();

	const [selectedTag, setSelectedTag] = useState<string>('');
	const submitIsDisabled = !selectedTag;

	const filteredOptions = useMemo(() => {
		const filtered = tagItems?.filter((tag) => !entityTagIds.some((et) => et.id === tag.id));

		return filtered?.map((tag) => ({ value: tag.id, label: tag.name })) || [];
	}, [entityTagIds, tagItems]);

	const onAdd = () => {
		const selectedObj = tagItems?.find((tag) => tag.id === selectedTag);
		onNewAdded(selectedObj as ITagBrief);
		setOpen(false);
	};

	const renderActions = () => {
		return (
			<ActionsBlock className={styles.actions}>
				<Button width={119} type="button" negative onClick={() => setOpen(false)}>
					Cancel
				</Button>

				<Button width={80} type="button" disabled={submitIsDisabled} onClick={onAdd}>
					Add
				</Button>
			</ActionsBlock>
		);
	};

	useEffect(() => {
		if (!open) setSelectedTag('');
	}, [open]);

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<div className={styles.top}>
					<h2 className={styles.title}>Add Tag</h2>

					<Select
						id="tag"
						className={styles.input}
						options={filteredOptions}
						placeholder="Add new tag"
						onValueChange={setSelectedTag}
					/>
				</div>

				{renderActions()}
			</BasicPanel>
		</Modal>
	);
};

export default ModalAddTag;
