import React, { FC, useState } from 'react';
import BasicPanel from '../../components/primitives/basic-panel/BasicPanel';
import styles from './CompanyProfile.module.scss';
import { Button } from '../../components/primitives';
import DataList from '../../components/primitives/data-list/DataList';
import DataListItem from '../../components/primitives/data-list-item/DataListItem';
import { Alert } from '../../components';
import EditCompany from '../../components/partials/edit-panels/company/EditCompany';
import useCompany from '../../utils/helpers/hooks/useCompany';
import classNames from 'classnames';
import { CommonResultStatusType } from '../../utils/types';
import { IndustriesSelectOptions } from '../../utils/helpers/constants';
import CompanyProfileSkeleton from './CompanyProfileSkeleton';
import useGetCompany from '../../utils/helpers/hooks/useGetCompany';

const CompanyProfile: FC = () => {
	const [openEditCompany, setOpenEditCompany] = useState(false);
	const [companyUpdateResult, setCompanyUpdateResult] = useState<CommonResultStatusType>('');
	const { info, loading } = useCompany();

	const onUpdateSuccess = () => {
		setCompanyUpdateResult('success');
		setOpenEditCompany(false);
	};

	const onUpdateError = () => {
		setCompanyUpdateResult('error');
	};

	const companyInfo = !info || loading ? (
		<CompanyProfileSkeleton />
	) : (
		<>
			<Button
				small
				className={styles.edit}
				width={56}
				negative
				onClick={() => setOpenEditCompany(true)}
			>
				Edit
			</Button>

			<div className={classNames(styles.logo, !info?.logoUrl ? styles.stub : '')}>
				{info?.logoUrl ? <img src={info?.logoUrl} alt="logo" /> : null}
			</div>

			<DataList>
				<DataListItem label="Name" text={info?.name} />
				<DataListItem label="Location" text={info?.locationName} />
				<DataListItem
					label="Industry"
					text={
						IndustriesSelectOptions.find((option) => option.value === info?.industry)
							?.label || ''
					}
				/>
			</DataList>
		</>
	);

	const editCompanySection = (
		<>
			<EditCompany
				open={openEditCompany}
				onUpdateSuccess={onUpdateSuccess}
				onUpdateError={onUpdateError}
				closeHandler={() => setOpenEditCompany(false)}
			/>

			<Alert
				uniqueKey={'company-success'}
				show={companyUpdateResult === 'success'}
				type="success"
				message="Company profile updated!"
				clearActionStatus={() => setCompanyUpdateResult('')}
			/>
			<Alert
				uniqueKey={'company-error'}
				show={companyUpdateResult === 'error'}
				type="error"
				message="Error occurred while updating company profile"
				clearActionStatus={() => setCompanyUpdateResult('')}
			/>
		</>
	);

	useGetCompany();

	return (
		<>
			<h2>Company Profile</h2>

			<BasicPanel className={styles['basic-panel']}>
				<h3>Company Details</h3>

				{companyInfo}
			</BasicPanel>

			{info ? editCompanySection : null}
		</>
	);
};

export default CompanyProfile;
