import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/common.scss';
import './styles/typography.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './services/store';
import setupAxios from './utils/axios-global';

setupAxios();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>,
);
