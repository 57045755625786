import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './EditTwoFactor.module.scss';
import Drawer from '../../../../primitives/drawer/Drawer';
import IEditTwoFactor from './IEditTwoFactor';
import { handleWithTryCatch } from '../../../../../utils/helpers/errors';
import { useAppDispatch } from '../../../../../services/store';
import {
	clearQrCode,
	getUserTwoFactorQrCode,
	IValidateMfaCode,
	validateMFACode,
} from '../../../../../services/store/slices/authorization.slice';
import ColoredTag from '../../../../primitives/tag/ColoredTag';
import { AppTagColors } from '../../../../../utils/helpers/constants';
import Loader from '../../../../primitives/loader/Loader';
import { useForm } from 'react-hook-form';
import { sanitizeData } from '../../../../../utils/helpers/common';
import { updateUserMFAConfigured } from '../../../../../services/store/slices/user.slice';
import Input from '../../../../primitives/form/input/Input';
import {
	requireValidationPattern,
	minLengthValidationPattern,
} from '../../../../../utils/helpers/common/form';
import ModalLeaveWarning from '../../../modals/modal-leave-warning/ModalLeaveWarning';
import useLeaveWarning from '../../../../../utils/helpers/hooks/useLeaveWarning';
import useAuthorization from '../../../../../utils/helpers/hooks/useAuthorization';
import { Alert, IAlertMessage, ActionsBlock, Button } from '../../../../';
import useUser from '../../../../../utils/helpers/hooks/useUser';

const EditTwoFactor: FC<IEditTwoFactor> = ({
	closeHandler,
	onUpdateError,
	onUpdateSuccess,
	open,
}) => {
	const dispatch = useAppDispatch();
	const { info } = useUser();
	const { qrCode, secretCode } = useAuthorization();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const [alert, setAlert] = useState<IAlertMessage>({
		message: '',
		type: 'success',
	});

	const {
		register,
		handleSubmit,
		getValues,
		reset,
		formState: { errors, isValid },
	} = useForm<IValidateMfaCode>({
		mode: 'onChange',
		defaultValues: {
			userCode: '',
		},
	});

	const [warningModalOpen, setWarningModalOpen] = useState(false);
	const [showBrowserLeaveWarning, setBrowserShowLeaveWarning] = useState(false);

	const changesWereMade = useCallback(() => {
		const currentValues = getValues();

		return currentValues.userCode !== '';
	}, [getValues]);

	const copyToClipboard = useCallback(() => {
		const code = secretCode;
		if (!code) return;

		navigator.clipboard.writeText(code);

		setAlert({
			message: 'Code copied to clipboard',
			type: 'success',
		});
	}, [secretCode]);

	useLeaveWarning(showBrowserLeaveWarning);

	const clearAlert = useCallback(() => {
		setAlert({ message: '', type: 'success' });
	}, []);

	const onFormSubmitHandler = useCallback(
		async (data: IValidateMfaCode) => {
			if (Object.keys(errors).length) return;

			const sanitizedData = sanitizeData(data);

			handleWithTryCatch(
				async () => {
					//TODO: change to this when email is changable {...newData, email: newData.email.trim()}
					await dispatch(validateMFACode(sanitizedData));
					await dispatch(updateUserMFAConfigured(true));

					onUpdateSuccess();
				},
				undefined,
				onUpdateError,
			);
		},
		[dispatch, errors, onUpdateError, onUpdateSuccess],
	);

	useEffect(() => {
		if (!qrCode && !loading && !error && open) {
			handleWithTryCatch(async () => {
				await dispatch(getUserTwoFactorQrCode());
				setLoading(false);
			}, setError);
		}
	}, [qrCode, dispatch, error, open, loading]);

	useEffect(() => {
		if (!open) {
			reset({}, { keepValues: false });
			setBrowserShowLeaveWarning(false);
			dispatch(clearQrCode());
		}
	}, [dispatch, open, reset]);

	const formActions = useMemo(
		() => (
			<ActionsBlock>
				<Button
					type="button"
					width={136}
					onClick={() => {
						if (changesWereMade()) setWarningModalOpen(true);
						else closeHandler();
					}}
					negative
					disabled={loading}
				>
					Cancel
				</Button>

				<Button type="submit" width={136} disabled={loading || !isValid}>
					{loading ? <Loader thin maxHeight={14} maxWidth={14} /> : 'Save'}
				</Button>
			</ActionsBlock>
		),
		[changesWereMade, closeHandler, isValid, loading],
	);

	const editForm = useMemo(
		() => (
			<div className={styles['edit-two-factor']}>
				<form onSubmit={handleSubmit(onFormSubmitHandler)}>
					<div className={styles['form-group']}>
						<div className={styles['qr-wrap']}>
							<div className={styles.content}>
								<h4>
									Authenticator app
									<ColoredTag
										tagId={'2-step-verification-status'}
										text={info?.mfaConfigured ? 'Configured' : 'Unconfigured'}
										bgColor={
											info?.mfaConfigured ? AppTagColors[0] : AppTagColors[3]
										}
										className={styles.label}
									/>
								</h4>
								<p>
									You will need an authenticator app to complete this process,
									such as one of the following{' '}
									<b>Google Authenticator, Microsoft Authenticator, 1Password</b>{' '}
									etc. Authenticator app generate one-time passwords that are used
									as a second factor to verify your identity when prompted during
									the log in
								</p>
							</div>
							<div className={styles['qr-code']}>
								{qrCode ? (
									<img src={qrCode} alt="qr code" />
								) : (
									<Loader thin maxHeight={14} maxWidth={14} />
								)}
							</div>
						</div>

						<ul>
							<li>
								1. Scan QR code <br />
								If you can not scan the code, then enter this code into your
								authenticator app: <br />
								<b>Note:</b> only time based key is supported
								<code>
									{secretCode ? (
										secretCode
									) : (
										<Loader thin maxHeight={14} maxWidth={14} />
									)}
									<button
										onClick={copyToClipboard}
										type="button"
										className={styles.copyToClipboard}
									/>
								</code>
							</li>
							<li>
								2. After scanning the QR code above, enter the 6-digit code
								generated by your authenticator app.
								<Input
									{...register('userCode', {
										...requireValidationPattern(),
										...minLengthValidationPattern(
											6,
											'Field must be at least 6 characters long',
										),
									})}
									className={styles.input}
									maxLength={6}
									withErrorStyle={!!errors.userCode}
									pattern="[0-9]*"
									placeholder="XXXXXX"
									type="text"
								/>
							</li>
						</ul>
					</div>
					{formActions}
				</form>
			</div>
		),
		[
			handleSubmit,
			onFormSubmitHandler,
			qrCode,
			secretCode,
			copyToClipboard,
			register,
			errors.userCode,
			formActions,
			info,
		],
	);

	const alerts = (
		<>
			<Alert
				uniqueKey={'edit-two-factor-alert'}
				show={!!alert.message}
				type={alert.type}
				message={alert.message}
				clearActionStatus={() => clearAlert()}
			/>
		</>
	);

	return (
		<Drawer
			open={open}
			title="2-Step Verification"
			onCloseClickHandler={() => {
				if (changesWereMade()) setWarningModalOpen(true);
				else closeHandler();
			}}
		>
			{open && editForm}

			<ModalLeaveWarning
				open={warningModalOpen}
				setOpen={setWarningModalOpen}
				onConfirm={() => {
					setBrowserShowLeaveWarning(false);
					closeHandler();
				}}
			/>

			{alerts}
		</Drawer>
	);
};

export default EditTwoFactor;
