import React, { FC, PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import SidebarLayout from '../../../../components/layouts/sidebar-layout/SidebarLayout';
import useUser from '../../hooks/useUser';
import BasicLayout from '../../../../components/layouts/basic-layout/BasicLayout';
import { LayoutType } from '../../constants';
import { AppUrls } from '../../constants/app-routes';
import useCompany from '../../hooks/useCompany';
import useLoginRedirection from '../../hooks/useLoginRedirection';

const ProtectedRoute: FC<PropsWithChildren & { layout?: LayoutType }> = ({ children, layout }) => {
	const location = useLocation();

	const { authorized, info: userInfo, isAdmin } = useUser();
	const { onboarded } = useCompany();

	useLoginRedirection();

	if (authorized !== null && !authorized) return <Navigate to={AppUrls.login} replace />;

	if (
		userInfo?.role &&
		!isAdmin &&
		(location.pathname.includes(AppUrls.frameworks) ||
			location.pathname.includes(AppUrls.shareholders) ||
			location.pathname.includes(AppUrls.companyProfile) ||
			location.pathname.includes(AppUrls.tags))
	)
		return <Navigate to={AppUrls.home} replace />;

	if (onboarded !== undefined && !onboarded && !location.pathname.includes(AppUrls.onboarding))
		return <Navigate to={AppUrls.onboarding} replace />;

	if (onboarded && location.pathname.includes(AppUrls.onboarding))
		return <Navigate to={AppUrls.home} replace />;

	if (layout === LayoutType.basic) return <BasicLayout>{children}</BasicLayout>;

	return <SidebarLayout>{children}</SidebarLayout>;
};

export default ProtectedRoute;
