import React, { FC } from 'react';
import styles from './StepsProgress.module.scss';
import IStepsProgress from './IStepsProgress';
import classNames from 'classnames';

const StepsProgress: FC<IStepsProgress> = ({ stepsNumber, activeStep, className, small }) => {
	const renderSteps = Array.from({ length: stepsNumber }, (_, index) => {
		const isActive = index === activeStep;

		return (
			<span
				key={index}
				className={classNames(
					styles.step,
					isActive ? styles.active : '',
					small ? styles.small : '',
				)}
			></span>
		);
	});

	return <div className={classNames(className, styles.steps)}>{renderSteps}</div>;
};

export default StepsProgress;
