import { FC, MouseEvent } from 'react';
import IInputComponentOverride from './IInputComponentOverride';
import styles from './InputComponentOverride.module.scss';
import classNames from 'classnames';

const InputComponentOverride: FC<IInputComponentOverride> = ({
	selectedOptions,
	text,
	onClearHandler,
	openToggleHandler,
	isOpened,
}) => {
	const onClearClick = (e: MouseEvent<HTMLSpanElement>) => {
		e.stopPropagation();
		onClearHandler();
	};
	return (
		<div className={styles.input}>
			{selectedOptions?.length ? (
				<div className={styles.clear} onClick={onClearClick}></div>
			) : null}

			<div
				className={classNames(
					styles['input-group'],
					selectedOptions?.length ? styles['with-selected'] : '',
					isOpened ? styles.opened : '',
				)}
				onClick={openToggleHandler}
			>
				<span className={styles.text}>{text}</span>

				{selectedOptions?.length ? (
					<span className={styles.number}>{selectedOptions?.length}</span>
				) : null}
			</div>
		</div>
	);
};

export default InputComponentOverride;
