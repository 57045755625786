import React, { FC } from 'react';

import styles from './ColoredTag.module.scss';
import IColoredTag from './IColoredTag';
import classNames from 'classnames';
import { CommonColors } from '../../../utils/helpers/constants';

const ColoredTag: FC<IColoredTag> = ({
	tagId,
	text,
	onRemoveClickHandler,
	className,
	withoutBackground,
	small,
	medium,
	iconOnLeft,
	iconOnRight,
	bgColor = CommonColors.defaultTagBg,
	borderColor,
	textColor,
	tooltipId,
	tooltipContent,
	withRemoveOnHover,
	asLink,
	onClickHandler,
}) => {
	const withRemove = !!onRemoveClickHandler && !withRemoveOnHover;
	const withIconOnLeft = !!iconOnLeft;
	const withIconOnRight = !!iconOnRight;

	const getStyle = () => {
		if (withoutBackground)
			return {
				borderColor: borderColor || bgColor,
				...(textColor ? { color: textColor } : {}),
			};
		else
			return {
				backgroundColor: bgColor,
				...(borderColor ? { borderColor } : {}),
				...(textColor ? { color: textColor } : {}),
			};
	};

	return (
		<div
			{...(tooltipId ? { 'data-tooltip-id': tooltipId } : {})}
			{...(tooltipContent ? { 'data-tooltip-content': tooltipContent } : {})}
			className={classNames(
				styles.tag,
				className,
				withRemove ? styles['with-close'] : '',
				small ? styles.small : '',
				medium ? styles.medium : '',
				tooltipId ? styles['with-tooltip'] : '',
				withRemoveOnHover ? styles['with-remove-on-hover'] : '',
				asLink ? styles['as-link'] : '',
			)}
			style={getStyle()}
			onClick={(e) => {
				if (onClickHandler) onClickHandler(e, tagId);
			}}
		>
			{withIconOnLeft ? (
				<img className={classNames(styles.icon, styles.left)} src={iconOnLeft} alt="i" />
			) : null}

			{text}

			{withRemove ? (
				<span
					className={styles.close}
					onClick={(e) => onRemoveClickHandler(e, tagId)}
				></span>
			) : null}

			{withRemoveOnHover && !!onRemoveClickHandler ? (
				<span
					className={styles.remove}
					onClick={(e) => onRemoveClickHandler(e, tagId)}
				></span>
			) : null}

			{asLink ? <span className={styles.navigation}></span> : null}

			{withIconOnRight ? (
				<img className={classNames(styles.icon, styles.right)} src={iconOnRight} alt="i" />
			) : null}
		</div>
	);
};

export default ColoredTag;
