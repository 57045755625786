import React, { FC, useEffect, useState } from 'react';
import styles from './ConfirmationStep.module.scss';
import { Button } from '../../../../components/primitives';
import IResetPasswordStep from '../IResetPasswordStep';

const ConfirmationStep: FC<IResetPasswordStep> = ({ email, onSubmitHandler }) => {
	const [disabled, setDisabled] = useState(false);

	const onResendClick = () => {
		setDisabled(true);
		if (onSubmitHandler) onSubmitHandler();
	};

	useEffect(() => {
		if (disabled) {
			setTimeout(() => setDisabled(false), 1000);
		}
	}, [disabled]);

	return (
		<>
			<p className={styles.info}>
				We sent a confirmation letter to<br/> <strong>{email}</strong>
			</p>

			<p className={styles.info}>
				Check your email and click on the confirmation link to continue.
			</p>

			<Button disabled={disabled} className={styles.action} type="submit" negative onClick={onResendClick}>
				Resend email
			</Button>
		</>
	);
};

export default ConfirmationStep;
