import { useEffect } from 'react';
import useUser from './useUser';
import { useAppDispatch } from '../../../services/store';
import { handleWithTryCatch } from '../errors';
import { getCompanyUsers } from '../../../services/store/slices/company-users.slice';

export default function useGetCompanyUsers(withLoading: boolean = true) {
	const dispatch = useAppDispatch();
	const { authorized, info: userInfo } = useUser();

	useEffect(() => {
		if (authorized) {
			handleWithTryCatch(async () => await dispatch(getCompanyUsers(withLoading)));
		}
	}, [authorized, dispatch, withLoading, userInfo?.companyId]);
}
