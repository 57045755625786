import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './ModalCreateTag.module.scss';
import Modal from '../../../primitives/modal/Modal';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import DetailsStep from './steps/details-step/DetailsStep';
import Back from '../../../primitives/back/Back';
import IModalCreateTag from './IModalCreateTag';
import ControlsStep from './steps/controls-step/ControlsStep';
import AssetsStep from './steps/assets-step/AssetsStep';
import { AppTagColors } from '../../../../utils/helpers/constants';
import { ICreateTag, createTag } from '../../../../services/store/slices/tags.slice';
import { useAppDispatch } from '../../../../services/store';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import StepsProgress from '../../../primitives/steps-progress/StepsProgress';

const ModalCreateTag: FC<IModalCreateTag> = ({ open, setOpen, onCreateSuccess, onCreateError }) => {
	const dispatch = useAppDispatch();
	const [currentStep, setCurrentStep] = useState('details');
	const [error, setError] = useState('');
	const [currentFormState, setCurrentFormState] = useState<ICreateTag>({
		name: '',
		color: AppTagColors[0],
		controls: [],
		assets: [],
		policies: [],
	});

	const onTagCreate = useCallback((assets: string[]) => {
		handleWithTryCatch(
			async () => {
				const createdTagId = await dispatch(
					createTag({
						name: currentFormState.name,
						color: currentFormState.color,
						controls: currentFormState.controls,
						assets: assets,
						policies: [],  //temp stub (BE requires this)
					}),
				);
				onCreateSuccess(createdTagId);
				setOpen(false);
			},
			setError,
			(message) => onCreateError(!!message?.includes('already exists')),
		);
	}, [
		currentFormState.color,
		currentFormState.controls,
		currentFormState.name,
		dispatch,
		onCreateError,
		onCreateSuccess,
		setOpen,
	]);

	const createTagSteps = useMemo(
		() => [
			{
				stepName: 'details',
				component: (
					<DetailsStep
						currentFormState={currentFormState}
						setCurrentFormState={setCurrentFormState}
						setNextStep={() => setCurrentStep('controls')}
						setOpen={setOpen}
					/>
				),
			},
			{
				stepName: 'controls',
				component: (
					<ControlsStep
						currentFormState={currentFormState}
						setCurrentFormState={setCurrentFormState}
						setNextStep={() => setCurrentStep('assets')}
						setOpen={setOpen}
					/>
				),
			},
			{
				stepName: 'assets',
				component: (
					<AssetsStep
						currentFormState={currentFormState}
						setCurrentFormState={setCurrentFormState}
						setNextStep={() => setCurrentStep('finish')}
						setOpen={setOpen}
						onFinish={onTagCreate}
					/>
				),
			},
		],
		[currentFormState, onTagCreate, setOpen],
	);

	const activeStepNumber = createTagSteps.findIndex((step) => step.stepName === currentStep);

	const onBackClick = () => {
		if (activeStepNumber === 0) {
			setOpen(false);
			return;
		}

		const prevStep = createTagSteps[activeStepNumber - 1];
		setCurrentStep(prevStep.stepName);
	};

	useEffect(() => {
		if (!open) {
			setCurrentStep('details');
			setCurrentFormState({
				name: '',
				color: AppTagColors[0],
				controls: [],
				assets: [],
				policies: [],
			});
			setError('');
		}
	}, [open]);

	useEffect(() => {
		if (error) {
			setCurrentStep('details');
			setError('');
		}
	}, [error]);

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<div className={styles.title}>
					{currentStep !== 'details' ? (
						<Back className={styles.back} onClick={onBackClick} />
					) : null}

					<h2>Create Tag</h2>
				</div>

				<StepsProgress
					className={styles.progress}
					stepsNumber={createTagSteps.length}
					activeStep={activeStepNumber}
					small
				/>

				{currentStep
					? createTagSteps.find((step) => step.stepName === currentStep)?.component
					: null}
			</BasicPanel>
		</Modal>
	);
};

export default ModalCreateTag;
