import { useSelector } from 'react-redux';
import { RootState } from '../../../services/store';

export default function useFrameworks() {
	const { items, loading } = useSelector((store: RootState) => store.frameworks);

	return {
		items,
		loading,
	};
}
