import { FC, useCallback, useMemo, useState } from 'react';
import styles from './ChangePassword.module.scss';
import Input from '../../../primitives/form/input/Input';
import { ActionsBlock, Button } from '../../../primitives';
import { useForm } from 'react-hook-form';
import useUser from '../../../../utils/helpers/hooks/useUser';
import { useAppDispatch } from '../../../../services/store';
import { changePassword } from '../../../../services/store/slices/user.slice';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import IChangePassword from './IChangePassword';
import PasswordInstructions from '../../../primitives/password-instructions/PasswordInstructions';
import Error from '../../../primitives/error/Error';
import { sanitizeData } from '../../../../utils/helpers/common';
import Loader from '../../../primitives/loader/Loader';

const ChangePassword: FC<IChangePassword> = ({ closeHandler, onUpdateError, onUpdateSuccess }) => {
	const dispatch = useAppDispatch();
	const { handleSubmit } = useForm();
	const { loading } = useUser();
	const [newPasswordIsValid, setNewPasswordIsValid] = useState(true);
	const [error, setError] = useState('');

	const [passwords, setPasswords] = useState({
		oldPassword: '',
		newPassword: '',
		confirmedPassword: '',
	});

	const passwordsAreSimilar =
		passwords.newPassword && passwords.confirmedPassword
			? passwords.newPassword === passwords.confirmedPassword
			: true;

	const submitIsDisabled = !!(
		!passwords.oldPassword ||
		!passwords.newPassword ||
		!passwords.confirmedPassword ||
		!passwordsAreSimilar ||
		!newPasswordIsValid
	);

	const onValidationHandler = useCallback((isValid: boolean) => {
		setNewPasswordIsValid(isValid);
	}, []);

	const onFormSubmitHandler = async () => {
		if (submitIsDisabled) return;

		const sanitizedData = sanitizeData(passwords);

		handleWithTryCatch(
			async () => {
				await dispatch(changePassword(sanitizedData));
				onUpdateSuccess();
			},
			setError,
			onUpdateError,
		);
	};

	const passwordInstructions = useMemo(
		() => (
			<PasswordInstructions
				passwordInput={passwords.newPassword}
				className={styles.instructions}
				onValidationHandler={onValidationHandler}
			/>
		),
		[passwords.newPassword, onValidationHandler],
	);

	const commonInputChange = () => {
		if (error) setError('');
	};

	return (
		<div className={styles['change-password']}>
			<form onSubmit={handleSubmit(onFormSubmitHandler)}>
				<div className={styles.inputs}>
					<Input
						id="old-password"
						type="password"
						autoComplete="off"
						label="Existing Password"
						value={passwords.oldPassword}
						placeholder="Enter existing password"
						withErrorStyle={!newPasswordIsValid || !!error}
						className={styles.input}
						onValueChange={(value: string) => {
							commonInputChange();
							setPasswords((prev) => ({
								...prev,
								oldPassword: value,
							}));
						}}
					/>

					<Input
						id="new-password"
						type="password"
						autoComplete="off"
						label="New Password"
						value={passwords.newPassword}
						placeholder="Enter new password"
						withErrorStyle={!newPasswordIsValid || !!error}
						className={styles.input}
						onValueChange={(value: string) => {
							commonInputChange();

							setPasswords((prev) => ({
								...prev,
								newPassword: value,
							}));
						}}
					/>

					<Input
						id="confirm-password"
						type="password"
						autoComplete="off"
						label="Repeat Password"
						value={passwords.confirmedPassword}
						placeholder="Repeat new password"
						withErrorStyle={!passwordsAreSimilar || !!error}
						error={!passwordsAreSimilar ? `Passwords don't match` : ''}
						className={styles.input}
						onValueChange={(value: string) => {
							commonInputChange();
							setPasswords((prev) => ({
								...prev,
								confirmedPassword: value,
							}));
						}}
					/>

					{error ? <Error message={error} /> : null}

					{passwordInstructions}
				</div>

				<ActionsBlock>
					<Button
						type="button"
						width={136}
						onClick={closeHandler}
						negative
						disabled={loading}
					>
						Cancel
					</Button>

					<Button type="submit" width={136} disabled={loading || submitIsDisabled}>
						{loading ? <Loader thin maxHeight={14} maxWidth={14} /> : 'Save'}
					</Button>
				</ActionsBlock>
			</form>
		</div>
	);
};

export default ChangePassword;
