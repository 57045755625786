import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from './FileInfo.module.scss';
import IFileInfo from './IFileInfo';
import classNames from 'classnames';
import { useUploadForm } from '../../../utils/helpers/hooks/useUploadForm';
import HoverTooltip from '../tooltip/HoverTooltip';
import { IFileUploadResult } from '../../../utils/types';
import { handleWithTryCatch } from '../../../utils/helpers/errors';
import { useAppDispatch } from '../../../services/store';
import { deletePolicyFile } from '../../../services/store/slices/policies.slice';
import { getPreassignedUrlForPolicy } from '../../../services/store/slices/company.slice';
import Error from '../error/Error';
import { ActionButton } from '../action-button';

const FileInfo: FC<IFileInfo> = ({
	file,
	className,
	setPolicyFiles,
	setUploadedFileIds,
	setFilesProgress,
}) => {
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const { isSuccess, isFailed, uploadForm, progress, uploadedFileId } = useUploadForm();

	const removeFileCommon = useCallback(() => {
		if (file) {
			if (error) setError('');

			setPolicyFiles((prev) => {
				if (prev) {
					const filtered = prev?.filter((f) => f.name !== file.name);
					return filtered;
				}

				return prev;
			});

			setFilesProgress((prev) => {
				const filtered = prev.filter((f) => f.name !== file.name);
				return filtered;
			});

			setLoading(false);
		}
	}, [error, file, setFilesProgress, setPolicyFiles]);

	const onFileRemove = async () => {
		if (uploadedFileId) {
			handleWithTryCatch(async () => {
				await dispatch(deletePolicyFile(uploadedFileId));
			}, setError);

			setUploadedFileIds((prev) => {
				if (prev) {
					const filtered = prev?.filter((fileId) => fileId !== Number(uploadedFileId));
					return filtered;
				}

				return prev;
			});
		}

		removeFileCommon();
	};

	const onFileUpload = useCallback(async () => {
		if (file) {
			handleWithTryCatch(async () => {
				const transformedFileName = encodeURIComponent(file.name);
				const preassignedUrl = await dispatch(
					getPreassignedUrlForPolicy(transformedFileName),
				);

				if (preassignedUrl?.signedUrl) {
					const fileId = preassignedUrl.file.id;
					await uploadForm(fileId, file, preassignedUrl?.signedUrl);

					setUploadedFileIds((prev) => [...prev, fileId]);
				}
			}, setError);
		}
	}, [dispatch, file, setUploadedFileIds, uploadForm]);

	const onUploadRetry = () => onFileUpload();

	useEffect(() => {
		if (file && !loading && !isFailed && !isSuccess) {
			setLoading(true);
			onFileUpload();
		}
	}, [file, isFailed, isSuccess, loading, onFileUpload]);

	useEffect(() => {
		if (isFailed || isSuccess) {
			setFilesProgress((prev) => {
				const filtered = prev.filter((f) => f.name !== file.name);
				return filtered;
			});

			setLoading(false);
			return;
		}

		setFilesProgress((prev) => {
			const files = [...prev];
			const existingFile = files.find((f) => f.name === file.name);

			if (existingFile) {
				existingFile.progress = progress;
				existingFile.isSuccess = isSuccess;
			} else {
				const uploadResult = { name: file.name, progress, isSuccess } as IFileUploadResult;
				files.push(uploadResult);
			}

			return files;
		});
	}, [file.name, isFailed, isSuccess, progress, setFilesProgress]);

	return (
		<>
			<div className={classNames(styles['file-info'], className)}>
				<div
					className={classNames(
						styles.name,
						isFailed || error ? styles.error : '',
						isSuccess ? styles.success : '',
						loading ? styles.uploading : '',
					)}
				>
					{file.name}
				</div>

				<div className={styles.actions}>
					{!error ? (
						<div className={styles.progress}>
							{(progress && !isSuccess && !isFailed) || loading ? `${progress}%` : ''}
						</div>
					) : null}

					<div className={styles['action-buttons']}>
						{isFailed ? (
							<ActionButton
								className={styles['action-button']}
								tooltipId={'tooltip-file'}
								tooltipContent={'Retry'}
								type="resume"
								onClick={onUploadRetry}
							/>
						) : null}

						{isFailed || isSuccess || error ? (
							<ActionButton
								className={styles['action-button']}
								tooltipId={'tooltip-file'}
								tooltipContent={'Remove'}
								type="cancel"
								onClick={onFileRemove}
							/>
						) : null}
					</div>

					<HoverTooltip tooltipId="tooltip-file" place="top-end" />
				</div>
			</div>

			{error ? <Error className={styles.error} message={error} /> : null}
		</>
	);
};

export default FileInfo;
