import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonResponseError } from '../../../utils/helpers/errors/CommonResponseError';
import {
	IChangeHistoryEntry,
	IChangeHistoryEntryBasic,
	IIdName,
	RoleType,
} from '../../../utils/types';
import userApi from '../../api/user.api';
import { RootState } from '..';
import { getCompanyUsers } from './company-users.slice';
import { getEntityInfoByType, sortByDateDesc } from '../../../utils/helpers/common';

export interface IPasswordChangeData {
	oldPassword?: string;
	newPassword?: string;
}

export interface IUserSlice {
	isAdmin: boolean;
	authorized: boolean | null;
	loading?: boolean;
}

export interface IUser {
	id?: string;
	email?: string;
	password?: string;
	firstName?: string;
	lastName?: string;
	fullName?: string;
	title?: string;
	role?: RoleType;
	companyId?: string;
	emailPreferences?: boolean;
	mfaConfigured?: boolean;
}

export interface IUserInfo extends IUserSlice {
	info?: Partial<IUser> | null;
}

const initialState: IUserInfo = {
	isAdmin: false,
	authorized: null,
	loading: false,
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		clearUserInfo: (state) => {
			state.info = null;
			state.loading = false;
		},
		setUserIsAuthorized: (state) => {
			state.authorized = true;
		},
		setUserIsUnauthorized: (state) => {
			state.authorized = false;
			state.loading = false;
			state.info = null;
		},
		setUserInfo: (state, { payload }: PayloadAction<Partial<IUser> | null>) => {
			state.info = { ...state.info, ...payload };
			state.isAdmin = payload !== null && payload.role !== RoleType.shareholder;
		},
	},
});

export const getUserInfo =
	(withLoading: boolean = true) =>
	async (dispatch: Dispatch<any>) => {
		if (withLoading) dispatch(loading());

		try {
			const user = await userApi.getUserInfo();

			dispatch(
				setUserInfo({
					...user,
					id: user.id?.toString(),
					companyId: user.companyId?.toString(),
					fullName: `${user.firstName} ${user.lastName}`,
				}),
			);
		} catch (error: any) {
			dispatch(setUserInfo(null));
			throw new CommonResponseError('Error while getting user info');
		} finally {
			dispatch(loaded());
		}
	};

export const updateUserInfo =
	(data: Partial<IUser>) => async (dispatch: Dispatch<any>, getState: () => RootState) => {
		dispatch(loading());

		try {
			const { email, emailPreferences, mfaConfigured } = getState().user.info as IUser;

			await userApi.updateUserInfo({ ...data, email, emailPreferences, mfaConfigured });

			await dispatch(
				setUserInfo({ ...data, fullName: `${data.firstName} ${data.lastName}` }),
			);

			dispatch(getCompanyUsers());
		} catch (error: any) {
			throw new CommonResponseError('Error while updating user');
		} finally {
			dispatch(loaded());
		}
	};

export const updateUserEmailPreferences =
	(newEmailPreferences: boolean) =>
	async (dispatch: Dispatch<any>, getState: () => RootState) => {
		try {
			const { firstName, lastName, title, mfaConfigured } = getState().user.info as IUser;

			await userApi.updateUserInfo({
				firstName,
				lastName,
				title,
				mfaConfigured,
				emailPreferences: newEmailPreferences,
			});

			dispatch(getUserInfo(false));
		} catch (error: any) {
			throw new CommonResponseError('Error while updating email preferences');
		}
	};

export const updateUserMFAConfigured =
	(newMFAConfigured: boolean) => async (dispatch: Dispatch<any>, getState: () => RootState) => {
		try {
			const { firstName, lastName, title, emailPreferences } = getState().user.info as IUser;

			await userApi.updateUserInfo({
				firstName,
				lastName,
				title,
				emailPreferences,
				mfaConfigured: newMFAConfigured,
			});

			dispatch(getUserInfo(false));
		} catch (error: any) {
			throw new CommonResponseError('Error while updating email preferences');
		}
	};

export const changePassword = (data: IPasswordChangeData) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		return await userApi.changePassword(data);
	} catch (error: any) {
		throw new CommonResponseError(
			'Error while changing password. Please check your existing password',
		);
	} finally {
		dispatch(loaded());
	}
};

export const getUserUpdates = () => async (dispatch: Dispatch<any>, getState: () => RootState) => {
	try {
		const userUpdates = (await userApi.getUserUpdates()) as IChangeHistoryEntryBasic[];

		const controls = getState().controls.items;
		const policies = getState().policies.items;
		const assets = getState().assets.items;

		const archivedPolicies = getState().policies.archivedItems;
		const archivedAssets = getState().assets.archivedItems;

		if (!controls || !policies || !assets) return [];

		const transformedData: IChangeHistoryEntry[] = userUpdates.map((item) => {
			const entity: IIdName = getEntityInfoByType(
				item.entityType,
				item.entityId?.toString(),
				controls,
				policies,
				archivedPolicies,
				assets,
				archivedAssets,
			)!;

			return {
				entityType: item.entityType,
				entity,
				action: item.action,
				createdAt: item.createdAt,
			};
		});

		return sortByDateDesc(transformedData, 'createdAt');
	} catch (error: any) {
		return [];
	}
};

export const {
	loading,
	loaded,
	clearUserInfo,
	setUserIsAuthorized,
	setUserIsUnauthorized,
	setUserInfo,
} = userSlice.actions;
export default userSlice.reducer;
