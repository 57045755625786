import { useSelector } from 'react-redux';
import { RootState } from '../../../services/store';

export default function useUser() {
	const { info, authorized, loading, isAdmin } = useSelector((store: RootState) => store.user);

	return {
		info,
		authorized,
		loading,
		isAdmin,
	};
}
