import { FC } from 'react';
import styles from './GSUpdatesBrief.module.scss';
import classNames from 'classnames';
import IGSUpdatesBrief from './IGSUpdatesBrief';
import { GSPosts, LabelColors } from '../../../utils/helpers/constants/gs-posts';
import ColoredTag from '../../primitives/tag/ColoredTag';
import ReadMore from '../../primitives/read-more/ReadMore';
import { useNavigate } from 'react-router-dom';
import { AppUrls } from '../../../utils/helpers/constants/app-routes';

const GSUpdatesBrief: FC<IGSUpdatesBrief> = ({ className }) => {
	const navigate = useNavigate();
	const displayPosts = GSPosts.slice(0, 2);

	const getLabelConfigs = (postType: string) => {
		switch (postType) {
			case 'framework':
				return {
					text: 'Framework',
					bg: LabelColors.frameworkBg,
					border: LabelColors.frameworkBorder,
				};

			case 'security':
				return {
					text: 'Security',
					bg: LabelColors.securityBg,
					border: LabelColors.securityBorder,
				};
		}
	};

	const renderLabel = (postType: string) => {
		const labelConfig = getLabelConfigs(postType);

		return (
			<ColoredTag
				tagId={postType}
				text={labelConfig?.text || ''}
				bgColor={labelConfig?.bg}
				borderColor={labelConfig?.border}
				className={styles.label}
			/>
		);
	};

	return (
		<div className={classNames(styles['gs-updates'], className)}>
			<div className={styles['title-section']}>
				<h3>GS Updates</h3>

				<ReadMore text="See all News" onClickHandler={() => navigate(AppUrls.gsUpdates)} />
			</div>

			{displayPosts.map((post) => (
				<div key={post.id} className={styles.post}>
					<div className={classNames(styles.picture, !post.image ? styles.empty : '')}>
						{post.image ? <img src={post.image} alt="GS Security" /> : null}
					</div>

					<div className={styles.info}>
						{renderLabel(post.label)}

						<p className={styles.text}>
							<span className={styles.title}>{post.title}</span>
							<br />
							<span className={styles.content}>{post.content}</span>
						</p>
					</div>
				</div>
			))}
		</div>
	);
};

export default GSUpdatesBrief;
