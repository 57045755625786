import { FC, useCallback, useEffect, useState } from 'react';
import styles from './NameStep.module.scss';
import { AssetTypeOptions } from '../../../../../../utils/helpers/constants';
import { ActionsBlock, Button } from '../../../../../primitives';
import Select from '../../../../../primitives/form/select/Select';
import Input from '../../../../../primitives/form/input/Input';
import INameStep from './INameStep';
import AddControlsTable from '../../../../tables/add-controls-table/AddControlsTable';
import useControls from '../../../../../../utils/helpers/hooks/useControls';
import {
	IControl,
	getControlsByType,
} from '../../../../../../services/store/slices/controls.slice';
import { handleWithTryCatch } from '../../../../../../utils/helpers/errors';
import { commonStringIsValid } from '../../../../../../utils/helpers/common/form';
import { useAppDispatch } from '../../../../../../services/store';
import Loader from '../../../../../primitives/loader/Loader';
import { IIdName } from '../../../../../../utils/types';

const NameStep: FC<INameStep> = ({
	setOpen,
	currentFormState,
	setCurrentFormState,
	setNextStep,
}) => {
	const dispatch = useAppDispatch();
	const [nextIsDisabled, setNextIsDisabled] = useState(false);
	const { items: controlItems } = useControls();
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const [controlsToShow, setControlsToShow] = useState<IControl[]>([]);
	const [typesLoading, setTypesLoading] = useState(false);

	const formActions = (
		<ActionsBlock className={styles.actions}>
			<Button type="button" width={136} onClick={() => setOpen(false)} negative>
				Cancel
			</Button>

			<Button
				type="button"
				width={66}
				disabled={nextIsDisabled || typesLoading}
				onClick={setNextStep}
			>
				{typesLoading ? (
					<Loader className={styles.loader} thin maxHeight={14} maxWidth={14} />
				) : (
					'Next'
				)}
			</Button>
		</ActionsBlock>
	);

	const controlsTable = (
		<div className={styles['table-wrap']}>
			<p>
				By adding this asset you will also add the following{' '}
				<strong>{controlsToShow?.length}</strong> controls:
			</p>

			<AddControlsTable
				bodyMaxHeight={160}
				withSearch={false}
				data={
					controlsToShow?.map(
						(control) =>
							({
								id: control.id,
								displayId: control.controlId,
								name: control.name,
							}) as IIdName,
					) || []
				}
				selectedRows={selectedRows}
				setSelectedRows={setSelectedRows}
			/>
		</div>
	);

	const getTypeControls = useCallback(
		(type: string) => {
			setTypesLoading(true);

			handleWithTryCatch(
				async () => {
					const controlObjs = await dispatch(getControlsByType(type));

					//@ts-ignore
					if (controlObjs.length) setControlsToShow(controlObjs || []);
					setTypesLoading(false);
				},
				undefined,
				() => setTypesLoading(false),
			);
		},
		[dispatch],
	);

	useEffect(() => {
		setSelectedRows(controlsToShow?.map((control: IControl) => control.id) || []);
	}, [controlsToShow]);

	useEffect(() => {
		setCurrentFormState((prev) => ({ ...prev, companyControlIds: selectedRows }));
	}, [selectedRows, setCurrentFormState]);

	useEffect(() => {
		setNextIsDisabled(
			!currentFormState.name ||
				!currentFormState.type ||
				!commonStringIsValid(currentFormState.name),
		);
	}, [currentFormState]);

	useEffect(() => {
		if (currentFormState.type && controlItems?.length) {
			setControlsToShow([]);
			getTypeControls(currentFormState.type);
		}
	}, [controlItems?.length, currentFormState.type, getTypeControls]);

	const createForm = (
		<div className={styles['add-asset']}>
			<form>
				<div className={styles.inputs}>
					<Input
						className={styles.input}
						type="text"
						label="Asset Name"
						defaultValue={currentFormState?.name}
						placeholder="Enter Asset Name"
						withErrorStyle={!commonStringIsValid(currentFormState?.name)}
						error={
							!commonStringIsValid(currentFormState?.name)
								? 'The input field allows alpha-numeric and special characters'
								: ''
						}
						onValueChange={(value: string) =>
							setCurrentFormState((prev) => ({ ...prev, name: value }))
						}
					/>

					<Select
						id="type"
						onValueChange={(value: string) =>
							setCurrentFormState((prev) => ({ ...prev, type: value }))
						}
						defaultValue={currentFormState?.type}
						className={styles.input}
						label="Asset Type"
						options={AssetTypeOptions}
					/>

					{currentFormState.type && controlsToShow.length ? controlsTable : null}
				</div>

				{formActions}
			</form>
		</div>
	);

	return createForm;
};

export default NameStep;
