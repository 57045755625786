import { ITableColumn } from '../../../primitives/table/ITable';
import { DeviceType } from '../../../../utils/helpers/hooks/useDevice/IUseDevice';
import { getUserRoleDisplay } from '../../../../utils/helpers/common';

export const getShareholderColumns = (device?: DeviceType | null) => {
	return [
		{
			header: 'First Name',
			key: 'firstName',
			sorting: true,
			width: '17%',
		},
		{
			header: 'Last Name',
			key: 'lastName',
			sorting: true,
			width: '17%',
		},
		{
			header: 'User Role',
			key: 'role',
			sorting: true,
			width: '17%',
			columnRender: (value: string) => getUserRoleDisplay(value),
		},
		{
			header: 'Email',
			key: 'email',
			sorting: true,
			width: '25.5%',
		},
		{
			header: 'Title',
			key: 'title',
			sorting: true,
			width: '15.7%',
		},
		{
			header: '',
			key: 'actions',
			width: '5%',
		},
	] as ITableColumn[];
};
