import React, { FC, useMemo } from 'react';
import styles from './ChangableOwnerBlock.module.scss';
import classNames from 'classnames';
import IChangableOwnerBlock from './IChangableOwnerBlock';
import Select from '../form/select/Select';
import { getOwnerOptions } from '../../../utils/helpers/common';
import useUser from '../../../utils/helpers/hooks/useUser';
import useCompanyUsers from '../../../utils/helpers/hooks/useCompanyUsers';

const ChangableOwnerBlock: FC<IChangableOwnerBlock> = ({
	id,
	className,
	initialValue,
	onOwnerChangeHandler,
}) => {
	const { items: companyUsers } = useCompanyUsers();
	const { info: userInfo } = useUser();

	const ownersOptions = useMemo(
		() => getOwnerOptions(companyUsers, userInfo?.id, false),
		[companyUsers, userInfo?.id],
	);

	const onOwnerChange = (value: string) => {
		onOwnerChangeHandler(value);
	};

	return (
		<div className={classNames(styles.changable, className)}>
			<Select
				id={`${id}-owner`}
				onValueChange={onOwnerChange}
				defaultValue={initialValue}
				className={styles.input}
				options={ownersOptions || []}
				withInTableStyle
				smallCarret
			/>
		</div>
	);
};

export default ChangableOwnerBlock;
