import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ISortProps, CommonResultStatusType, IKeyValuePair } from '../../../../utils/types';
import Table from '../../../primitives/table/Table';
import { processTableData } from '../../../../utils/helpers/common';
import TableFilters from '../../../primitives/table-filters/TableFilters';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import styles from './ShareholdersTable.module.scss';
import { getShareholderColumns } from './utils';
import usePagination from '../../../../utils/helpers/hooks/usePagination';
import { Alert } from '../../../';
import useDevice from '../../../../utils/helpers/hooks/useDevice/useDevice';
import TableSkeleton from '../../../primitives/table/TableSkeleton';
import classNames from 'classnames';
import SearchFilter from '../../../primitives/filters/search-filter/SearchFilter';
import IPartialTable from '../common/IPartialTable';
import { Button, ActionButton } from '../../../primitives';
import EditShareholder from '../../edit-panels/shareholder/EditShareholder';
import { ICompanyUser } from '../../../../services/store/slices/company-users.slice';
import ModalAddShareholder from '../../modals/modal-add-shareholder/ModalAddShareholder';
import HoverTooltip from '../../../primitives/tooltip/HoverTooltip';
import ModalDeleteShareholder from '../../modals/modal-delete-shareholder/ModalDeleteShareholder';
import { AppUrls } from '../../../../utils/helpers/constants/app-routes';
import { AppQueryParams } from '../../../../utils/helpers/constants';
import { useNavigate } from 'react-router-dom';

const ShareholdersTable: FC<IPartialTable<ICompanyUser>> = ({ data, dataLoading, showItemId }) => {
	const device = useDevice();
	const navigate = useNavigate();

	const [processedData, setProcessedData] = useState(data);
	const [currentPageData, setCurrentPageData] = useState<ICompanyUser[]>([]);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [selectedShareholder, setSelectedShareholder] = useState<ICompanyUser | null>();
	const [shareholderUpdateResult, setShareholderUpdateResult] =
		useState<CommonResultStatusType>('');
	const [isProcessing, setIsProcessing] = useState(true);
	const [addNewModalOpen, setAddNewModalOpen] = useState(false);
	const [addNewResult, setAddNewResult] = useState<CommonResultStatusType>('');
	const [deleteResult, setDeleteResult] = useState<CommonResultStatusType>('');
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [userToDelete, setUserToDelete] = useState('');
	const [addedUser, setAddedUser] = useState('');

	const {
		paginationSection,
		setCurrentPage,
		pagesCount,
		goToPageByDataEntryProperty: goToPageByDataEntryId,
	} = usePagination(processedData, setCurrentPageData, 10);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const [currentFilters, setCurrentFilters] = useState<IKeyValuePair>({
		search: (dataRecord: ICompanyUser) => true,
	});

	const tableColumnsConfig = useMemo(() => getShareholderColumns(device), [device]);

	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const onRowClickHandler = useCallback(
		(shareholderId: string) => {
			navigate(`${AppUrls.shareholders}?${AppQueryParams.item}=${shareholderId}`);
		},
		[navigate],
	);

	useEffect(() => {
		if (showItemId) {
			const selectedShareholder = data.find(
				(shareholder: ICompanyUser) => shareholder.id === showItemId,
			);
			setSelectedShareholder(selectedShareholder);
			setOpenDrawer(true);
		}
	}, [data, showItemId]);

	const onShareholderUpdateSuccess = () => {
		setShareholderUpdateResult('success');
		setOpenDrawer(false);
		setSelectedShareholder(null);
		navigate(`${AppUrls.shareholders}`);
	};
	const onShareholderUpdateError = () => setShareholderUpdateResult('error');

	const editDrawer = (
		<EditShareholder
			shareholder={selectedShareholder}
			onUpdateSuccess={onShareholderUpdateSuccess}
			onUpdateError={onShareholderUpdateError}
			closeHandler={() => {
				setSelectedShareholder(null);
				setOpenDrawer(false);
				navigate(`${AppUrls.shareholders}`);
			}}
			open={openDrawer}
		/>
	);

	const alerts = (
		<>
			<Alert
				uniqueKey={'shareholder-update-success'}
				show={shareholderUpdateResult === 'success'}
				type="success"
				message="Changes saved!"
				clearActionStatus={() => setShareholderUpdateResult('')}
			/>

			<Alert
				uniqueKey={'shareholder-update-error'}
				show={shareholderUpdateResult === 'error'}
				type="error"
				message="Changes were not saved. Please try again."
				clearActionStatus={() => setShareholderUpdateResult('')}
			/>

			<Alert
				uniqueKey={'shareholder-success'}
				show={addNewResult === 'success'}
				type="success"
				message="Shareholder created!"
				clearActionStatus={() => setAddNewResult('')}
			/>

			<Alert
				uniqueKey={'shareholder-error'}
				show={addNewResult === 'error'}
				type="error"
				message="Shareholder was not created. Please try again"
				clearActionStatus={() => setAddNewResult('')}
			/>

			<Alert
				uniqueKey={'delete-success'}
				show={deleteResult === 'success'}
				type="success"
				message="Shareholder deleted!"
				clearActionStatus={() => setDeleteResult('')}
			/>
			<Alert
				uniqueKey={'delete-error'}
				show={deleteResult === 'error'}
				type="error"
				message="Error occurred while deleting shareholder. Please try again."
				clearActionStatus={() => setDeleteResult('')}
			/>
		</>
	);

	const renderModals = () => {
		const foundedUser = data?.find((user) => user.id === userToDelete);
		const name = `${foundedUser?.firstName} ${foundedUser?.lastName}`;

		return (
			<>
				<ModalAddShareholder
					open={addNewModalOpen}
					setOpen={setAddNewModalOpen}
					onAddSuccess={(id: string) => {
						setAddedUser(id);
						setAddNewResult('success');
					}}
					onAddError={() => setAddNewResult('error')}
				/>

				<ModalDeleteShareholder
					open={deleteModalOpen}
					setOpen={setDeleteModalOpen}
					id={userToDelete}
					name={name}
					onDeleteSuccess={() => setDeleteResult('success')}
					onDeleteError={() => setDeleteResult('error')}
				/>
			</>
		);
	};

	const tableFilters = useMemo(() => {
		const leftSection = (
			<SearchFilter
				disabled={!dataLoading && !data.length}
				placeholder="Search..."
				setFilters={setCurrentFilters}
				properties={['firstName', 'lastName', 'email', 'title']}
			/>
		);
		const rightSection = (
			<Button width={111} negative onClick={() => setAddNewModalOpen(true)}>
				Add New
			</Button>
		);

		return (
			<TableFilters
				disabled={dataLoading || isProcessing}
				rightSection={rightSection}
				leftSection={leftSection}
			/>
		);
	}, [data.length, dataLoading, isProcessing]);

	const mainTable = useMemo(
		() => (
			<Table
				tableKey="shareholders"
				data={currentPageData}
				columns={tableColumnsConfig}
				options={{
					rowActions: [
						{
							actionComponent: (
								<ActionButton
									tooltipId={'tooltip-delete-user'}
									tooltipContent={'Delete'}
									type="delete-user"
								/>
							),
							actionHandler: (id: string) => setUserToDelete(id),
						},
					],
					successHighlightedRows: [data?.find((user) => user.id === addedUser)?.id || ''],
					onSortChange,
					onRowClickHandler,
					emptyResultMessage:
						!dataLoading && !data.length
							? `No shareholders to display yet.`
							: `No matches found. Please try another search query.`,
					emptyResultMessageType: !dataLoading && !data.length ? 'common' : 'search',
				}}
			/>
		),
		[
			addedUser,
			currentPageData,
			data,
			dataLoading,
			onRowClickHandler,
			onSortChange,
			tableColumnsConfig,
		],
	);

	useEffect(() => {
		if (addedUser) {
			goToPageByDataEntryId(addedUser, 'email');

			const addDelay = setTimeout(() => {
				setAddedUser('');
				clearTimeout(addDelay);
			}, 2000);
		}
	}, [addedUser, goToPageByDataEntryId]);

	useEffect(() => {
		setIsProcessing(true);
		setCurrentPage(1);

		const proccessedData: ICompanyUser[] = processTableData(data, currentFilters, currentSort);

		setProcessedData(proccessedData);
		setIsProcessing(false);
	}, [currentFilters, currentSort, data, setCurrentPage]);

	useEffect(() => {
		if (!deleteModalOpen) setUserToDelete('');
	}, [deleteModalOpen]);

	useEffect(() => {
		if (userToDelete) setDeleteModalOpen(true);
	}, [userToDelete]);

	useEffect(() => {
		if (showItemId) goToPageByDataEntryId(showItemId, 'id');
	}, [goToPageByDataEntryId, showItemId]);

	return (
		<>
			<BasicPanel
				className={classNames(styles['basic-panel'], isProcessing ? styles.disabled : '')}
			>
				{tableFilters}

				{isProcessing || dataLoading ? (
					<TableSkeleton rowsNumber={4} columns={tableColumnsConfig} />
				) : (
					mainTable
				)}
			</BasicPanel>

			{pagesCount > 1 && !dataLoading ? paginationSection : null}

			{editDrawer}

			<HoverTooltip tooltipId="tooltip-delete-user" place="top-end" />

			{renderModals()}

			{alerts}
		</>
	);
};

export default ShareholdersTable;
