import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonResponseError } from '../../../utils/helpers/errors/CommonResponseError';
import { RootState } from '..';
import companyUsersApi from '../../api/company-users.api';
import { sortUsersByFullName } from '../../../utils/helpers/common';

export interface ICompanyUsersSlice {
	loading?: boolean;
	updateLoading?: boolean;
	error?: string | null;
}

export interface ICompanyUser {
	id?: string;
	firstName?: string;
	lastName?: string;
	fullName?: string;
	email: string;
	title?: string;
	role?: string;
	company?: string;
}

export interface ICompanyUsers extends ICompanyUsersSlice {
	items: ICompanyUser[] | null;
}

const initialState: ICompanyUsers = {
	loading: false,
	updateLoading: false,
	items: null,
	error: null,
};

export const companyUsersSlice = createSlice({
	name: 'companyUsers',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		updateLoading: (state) => {
			state.updateLoading = true;
		},
		updateLoaded: (state) => {
			state.updateLoading = false;
		},
		setCompanyUsersError: (state) => {
			state.error = 'Error while getting company users';
		},
		setCompanyUsers: (state, { payload }: PayloadAction<ICompanyUser[]>) => {
			state.items = payload;
		},
	},
});

export const getCompanyUsers = (withLoading?: boolean) => async (dispatch: Dispatch<any>) => {
	if (withLoading) dispatch(loading());

	try {
		const users = await companyUsersApi.getCompanyUsers();

		const transformedUsers = users?.map((user) => ({
			...user,
			id: user.id?.toString(),
			fullName: `${user.firstName} ${user.lastName}`,
		}));

		await dispatch(setCompanyUsers(sortUsersByFullName(transformedUsers)));
	} catch (error: any) {
		dispatch(setCompanyUsers([]));
		dispatch(setCompanyUsersError());

		throw new CommonResponseError('Error while getting company users.');
	} finally {
		dispatch(loaded());
	}
};

export const addNewCompanyUser =
	(data: Partial<ICompanyUser>) => async (dispatch: Dispatch<any>, getState: () => RootState) => {
		dispatch(loading());

		try {
			const { info: companyInfo } = getState().company;

			if (companyInfo?.id) {
				await companyUsersApi.addNewCompanyUser(data, companyInfo?.id);

				await dispatch(getCompanyUsers());
			}
		} catch (error: any) {
			if (error && error.message && error.message.toLowerCase().includes('exists')) {
				throw new CommonResponseError('User already exists.');
			}

			throw new CommonResponseError('Error while adding company user.');
		} finally {
			dispatch(loaded());
		}
	};

export const deleteCompanyUser = (userId: string) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		await companyUsersApi.deleteCompanyUser(userId);
		await dispatch(getCompanyUsers());
	} catch (error: any) {
		throw new CommonResponseError('Error while deleting company user.');
	} finally {
		dispatch(loaded());
	}
};

export const updateCompanyUser =
	(data: Partial<ICompanyUser>) =>
	async (dispatch: Dispatch<any>) => {
		dispatch(updateLoading());

		try {
			await companyUsersApi.updateCompanyUser(data);

			await dispatch(getCompanyUsers())
		} catch (error: any) {
			throw new CommonResponseError('Error while updating company user.');
		} finally {
			dispatch(updateLoaded());
		}
	};

export const sendReminder = (userId: string) => async (dispatch: Dispatch<any>) => {
	//try {
	// const userResult = await companyUsersApi.sendReminder({
	// 	...data,
	// });

	return true;
	//}
	// catch (error: any) {
	// 	throw new CommonResponseError('Error while sending reminder.');
	// }
};

export const {
	loading,
	loaded,
	updateLoading,
	updateLoaded,
	setCompanyUsers,
	setCompanyUsersError,
} = companyUsersSlice.actions;
export default companyUsersSlice.reducer;
