import React, { FC } from 'react';
import styles from './HistorySkeleton.module.scss';
import LoadingSkeleton from '../../loading-skeleton/LoadingSkeleton';

const HistorySkeleton: FC = () => {
	return (
		<div className={styles.section}>
			<LoadingSkeleton containerClassName={styles['section-title']} height={24} />

			<LoadingSkeleton height={40} />
		</div>
	);
};

export default HistorySkeleton;
