import React, { FC } from 'react';
import styles from './DataList.module.scss';
import IDataList from './IDataList';
import classNames from 'classnames';

const DataList: FC<IDataList> = ({ className, children }) => {
	return <div className={classNames(styles['data-list'], className)}>{children}</div>;
};

export default DataList;
