import React, { FC } from 'react';
import styles from './UserRecentUpdates.module.scss';
import LoadingSkeleton from '../../primitives/loading-skeleton/LoadingSkeleton';

const UserRecentUpdatesSkeleton: FC = () => {
	return (
		<div className={styles['updates-skeleton']}>
			<LoadingSkeleton containerClassName={styles.item} height={20} />
			<LoadingSkeleton containerClassName={styles.item} height={20} />
			<LoadingSkeleton containerClassName={styles.item} height={20} />
		</div>
	);
};

export default UserRecentUpdatesSkeleton;
