import React, { FC } from 'react';
import styles from './FilePicker.module.scss';
import IFilePicker from './IFilePicker';
import classNames from 'classnames';
import { Button } from '../';
import { useDropzone } from 'react-dropzone';

const FilePicker: FC<IFilePicker> = ({
	className,
	setAcceptedFiles,
	acceptExtensions = { 'image/*': [], 'application/pdf': [] },
	multipleSelection = true,
}) => {
	const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, open } =
		useDropzone({
			multiple: multipleSelection,
			accept: acceptExtensions,
			onDrop: (acceptedFiles: File[]) => {
				if (setAcceptedFiles) setAcceptedFiles((prev) => [...(prev || []), ...acceptedFiles]);
			},
		});

	const dropZone = (
		<div
			{...getRootProps()}
			className={classNames(
				styles['drag-n-drop'],
				className,
				isFocused ? styles.focused : '',
				isDragAccept ? styles.accepted : '',
				isDragReject ? styles.rejected : '',
			)}
		>
			<input {...getInputProps()} />
			<p>Drag and drop files here</p>
		</div>
	);

	return (
		<div className={classNames(styles['file-picker'], className)}>
			{dropZone}

			<div className={styles.divider}>or</div>

			<Button type="button" negative onClick={open}>
				Browse on my computer
			</Button>
		</div>
	);
};

export default FilePicker;
