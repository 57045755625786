import { FC, useEffect, useState } from 'react';
import Table from '../../../primitives/table/Table';
import { getRecentTagsColumns } from './utils';
import styles from './RecentTags.module.scss';
import classNames from 'classnames';
import IRecentTags from './IRecentTags';
import ReadMore from '../../../primitives/read-more/ReadMore';
import { useNavigate } from 'react-router-dom';
import { AppUrls } from '../../../../utils/helpers/constants/app-routes';
import useControls from '../../../../utils/helpers/hooks/useControls';
import useDevice from '../../../../utils/helpers/hooks/useDevice/useDevice';
import TableSkeleton from '../../../primitives/table/TableSkeleton';
import { ITag, getLastUpdatedTags } from '../../../../services/store/slices/tags.slice';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import { useAppDispatch } from '../../../../services/store';

const RecentTags: FC<IRecentTags> = ({ className }) => {
	const dispatch = useAppDispatch();
	const device = useDevice();
	const navigate = useNavigate();
	const { items: controlItems } = useControls();

	const [dataLoading, setDataLoading] = useState(true);
	const [lastUpdatedTags, setLastUpdatedTags] = useState<ITag[] | null>(null);

	const renderEmptyState = () => {
		return (
			<div className={styles['empty-message']}>
				<span className={styles.message}>No tags to display yet</span>
			</div>
		);
	};

	const renderContent = () => {
		if (!lastUpdatedTags?.length) return renderEmptyState();

		return (
			<Table
				tableKey="recent-tags"
				data={lastUpdatedTags || []}
				columns={getRecentTagsColumns(device)}
				options={{
					rowClassName: styles.row,
					emptyResultMessage: 'No tags to display.',
					emptyResultMessageType: 'common',
					extraSmall: true,
					noScroll: true,
				}}
			/>
		);
	};

	useEffect(() => {
		if (controlItems && lastUpdatedTags) setDataLoading(false);
	}, [controlItems, lastUpdatedTags]);

	useEffect(() => {
		handleWithTryCatch(async () => {
			const result = await dispatch(getLastUpdatedTags());

			setLastUpdatedTags(result);
		});
	}, [dispatch]);

	return (
		<div className={classNames(styles['recent-tags'], className)}>
			<div className={styles['title-section']}>
				<h3>Recent Tags</h3>

				{!dataLoading ? (
					<ReadMore
						text="See all Company Tags"
						onClickHandler={() => navigate(AppUrls.tags)}
					/>
				) : null}
			</div>

			{dataLoading ? (
				<TableSkeleton extraSmall rowsNumber={3} columns={getRecentTagsColumns(device)} />
			) : (
				renderContent()
			)}
		</div>
	);
};

export default RecentTags;
