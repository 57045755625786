import { useEffect, useMemo, useState } from 'react';
import useUser from '../../utils/helpers/hooks/useUser';
import useAssets from '../../utils/helpers/hooks/useAssets';
import ArchivedAssetsTable from '../../components/partials/tables/assets/archived/ArchivedAssetsTable';
import AssetsTable from '../../components/partials/tables/assets/not-archived/AssetsTable';
import Tabs from '../../components/primitives/tabs/Tabs';
import useGetAssets from '../../utils/helpers/hooks/useGetAssets';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppQueryParams } from '../../utils/helpers/constants';
import { AppUrls } from '../../utils/helpers/constants/app-routes';
import useControls from '../../utils/helpers/hooks/useControls';

const Assets = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const { isAdmin, info: userInfo } = useUser();
	const {
		items: assetItems,
		archivedItems: assetArchivedItems,
		archivationLoading,
		loading: assetsLoading,
	} = useAssets();

	const { loading: controlsLoading } = useControls();

	const [dataLoading, setDataLoading] = useState(true);
	const [activeTab, setActiveTab] = useState('companyLibrary');

	const navigateToItem = useMemo(() => {
		if (!dataLoading && assetItems && assetArchivedItems && userInfo?.id) {
			const assetIdFromUrl = new URLSearchParams(location.search).get(AppQueryParams.item);

			if (archivationLoading) return navigate(AppUrls.assets);

			if (assetIdFromUrl) {
				const asset = assetItems?.find((item) => item.id === assetIdFromUrl);

				if (asset) {
					if (!isAdmin && asset?.owner?.id !== userInfo?.id)
						return navigate(AppUrls.assets);

					setActiveTab('companyLibrary');

					return {
						id: assetIdFromUrl,
						archived: false,
					};
				} else {
					if (!isAdmin) return navigate(AppUrls.assets);

					const archivedAsset = assetArchivedItems.find(
						(item) => item.id === assetIdFromUrl,
					);

					if (archivedAsset) {
						setActiveTab('archived');

						return {
							id: assetIdFromUrl,
							archived: true,
						};
					}
				}
			}
		}
	}, [
		archivationLoading,
		assetArchivedItems,
		assetItems,
		dataLoading,
		isAdmin,
		location.search,
		navigate,
		userInfo?.id,
	]);

	const archivedTable = useMemo(
		() => (
			<ArchivedAssetsTable
				data={assetArchivedItems || []}
				dataLoading={dataLoading}
				showItemId={navigateToItem && navigateToItem.archived ? navigateToItem?.id : ''}
			/>
		),
		[assetArchivedItems, dataLoading, navigateToItem],
	);

	const assetsTable = useMemo(
		() => (
			<AssetsTable
				data={assetItems || []}
				dataLoading={dataLoading || archivationLoading || controlsLoading || assetsLoading}
				showItemId={navigateToItem && !navigateToItem?.archived ? navigateToItem?.id : ''}
			/>
		),
		[
			assetItems,
			dataLoading,
			navigateToItem,
			archivationLoading,
			controlsLoading,
			assetsLoading,
		],
	);

	useGetAssets();

	useEffect(() => {
		if (assetItems && assetArchivedItems) setDataLoading(false);
	}, [assetItems, assetArchivedItems]);

	return (
		<>
			<h2>Assets</h2>

			<Tabs
				customActiveTab={activeTab}
				setCustomActiveTab={setActiveTab}
				tabs={[
					{
						key: 'companyLibrary',
						text: `Company Library (${assetItems?.length || 0})`,
						component: assetsTable,
					},
					...(isAdmin && assetArchivedItems?.length
						? [
								{
									key: 'archived',
									text: 'Archived',
									component: archivedTable,
								},
							]
						: []),
				]}
			/>
		</>
	);
};

export default Assets;
