import { LayoutType } from '.';
import CompanyProfile from '../../../pages/company-profile/CompanyProfile';
import Assets from '../../../pages/assets/Assets';
import Controls from '../../../pages/controls/Controls';
import Home from '../../../pages/home/Home';
import Frameworks from '../../../pages/frameworks/Frameworks';
import Login from '../../../pages/login/Login';
import Onboarding from '../../../pages/onboarding/Onboarding';
import Policies from '../../../pages/policies/Policies';
import Profile from '../../../pages/profile/Profile';
import ResetPassword from '../../../pages/reset-password/ResetPassword';
import Shareholders from '../../../pages/shareholders/Shareholders';
import Tags from '../../../pages/tags/Tags';
import GSUpdates from '../../../pages/gs-updates/GSUpdates';
import { Verify } from '../../../pages';

export const AppUrls = {
	login: '/login',
	verify: '/verify',
	resetPassword: '/reset-password',
	createPassword: '/create-password',
	onboarding: '/onboarding',
	home: '/',
	frameworks: '/frameworks',
	controls: '/controls',
	policies: '/policies',
	assets: '/assets',
	shareholders: '/shareholders',
	companyProfile: '/company-profile',
	profile: '/profile',
	tags: '/tags',
	gsUpdates: '/gs-updates',
};

export const AppRoutes = [
	{ type: 'unprotected', key: 'login', text: 'Login', url: AppUrls.login, component: Login },
	{ type: 'unprotected', key: 'verify', text: 'Verify', url: AppUrls.verify, component: Verify },
	{
		type: 'unprotected',
		key: 'resetPassword',
		text: 'Reset Password',
		url: AppUrls.resetPassword,
		component: ResetPassword,
	},
	{
		type: 'unprotected',
		key: 'createPassword',
		text: 'Create Password',
		url: AppUrls.createPassword,
		component: ResetPassword,
	},
	{
		type: 'protected',
		key: 'onboarding',
		text: 'Onboarding',
		url: AppUrls.onboarding,
		component: Onboarding,
		layout: LayoutType.basic,
		excludeFromNavigation: true,
	},
	{ type: 'protected', key: 'home', text: 'Home page', url: AppUrls.home, component: Home },
	{
		type: 'protected',
		key: 'frameworks',
		text: 'Frameworks',
		url: AppUrls.frameworks,
		component: Frameworks,
		hideForShareholder: true,
	},
	{
		type: 'protected',
		key: 'controls',
		text: 'Controls',
		url: AppUrls.controls,
		component: Controls,
	},
	{
		type: 'protected',
		key: 'policies',
		text: 'Policies',
		url: AppUrls.policies,
		component: Policies,
	},
	{ type: 'protected', key: 'assets', text: 'Assets', url: AppUrls.assets, component: Assets },
	{
		type: 'protected',
		key: 'shareholders',
		text: 'Shareholders',
		url: AppUrls.shareholders,
		component: Shareholders,
		hideForShareholder: true,
	},
	{
		type: 'protected',
		key: 'companyProfile',
		text: 'Company Profile',
		url: AppUrls.companyProfile,
		component: CompanyProfile,
		hideForShareholder: true,
	},
	{
		type: 'protected',
		key: 'profile',
		text: 'Profile',
		url: AppUrls.profile,
		component: Profile,
		excludeFromNavigation: true,
	},
	{
		type: 'protected',
		key: 'tags',
		text: 'Tags',
		url: AppUrls.tags,
		component: Tags,
		excludeFromNavigation: true,
	},
	{
		type: 'protected',
		key: 'gs-updates',
		text: 'GS Updates',
		url: AppUrls.gsUpdates,
		component: GSUpdates,
		excludeFromNavigation: true,
	},
];

export const AppUnauthorizedRoutes = [AppUrls.createPassword, AppUrls.resetPassword, AppUrls.login, AppUrls.verify];
