import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Table from '../../../primitives/table/Table';
import { getBriefTasksColumns } from './utils';
import styles from './UserTasksBriefTable.module.scss';
import { ISortProps } from '../../../../utils/types';
import { processTableData } from '../../../../utils/helpers/common';
import classNames from 'classnames';
import IUserTasksBriefTable from './IUserTasksBriefTable';
import {
	IAssignment,
	getAssignmentsForUser,
} from '../../../../services/store/slices/assignments.slice';
import useUser from '../../../../utils/helpers/hooks/useUser';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import { useAppDispatch } from '../../../../services/store';
import { useNavigate } from 'react-router-dom';
import { AppUrls } from '../../../../utils/helpers/constants/app-routes';
import useControls from '../../../../utils/helpers/hooks/useControls';
import usePolicies from '../../../../utils/helpers/hooks/usePolicies';
import useAssets from '../../../../utils/helpers/hooks/useAssets';
import TableSkeleton from '../../../primitives/table/TableSkeleton';
import { AppQueryParams, UserTaskType } from '../../../../utils/helpers/constants';

const UserTasksBriefTable: FC<IUserTasksBriefTable> = ({ className }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { info: userInfo } = useUser();
	const { items: controlItems } = useControls();
	const { items: policyItems } = usePolicies();
	const { items: assetItems } = useAssets();

	const [dataLoading, setDataLoading] = useState(true);
	const [dataToDisplay, setDataToDisplay] = useState<IAssignment[]>([]);
	const [currentTasks, setCurrentTasks] = useState<IAssignment[] | null>(null);

	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const onTaskClick = useCallback(
		(taskId: string) => {
			const task = currentTasks?.find((task) => task.id === taskId);

			switch (task?.taskType) {
				case UserTaskType.AssetOwner:
					navigate(`${AppUrls.assets}?${AppQueryParams.item}=${task?.relatedItem.id}`);
					break;

				case UserTaskType.ControlOwner:
					navigate(
						`${AppUrls.controls}?${AppQueryParams.item}=${task?.relatedItem.id}`,
					);
					break;

				case UserTaskType.PolicyOwner:
				case UserTaskType.PolicyReview:
					navigate(`${AppUrls.policies}?${AppQueryParams.item}=${task?.relatedItem.id}`);
					break;

				default:
					break;
			}
		},
		[currentTasks, navigate],
	);

	const renderEmptyState = () => {
		return (
			<div className={styles['empty-message']}>
				<span className={styles.message}>No tasks to display yet</span>
			</div>
		);
	};

	const mainTable = useMemo(
		() => (
			<Table
				tableKey="tasks-brief"
				data={dataToDisplay}
				columns={getBriefTasksColumns()}
				options={{
					onRowClickHandler: onTaskClick,
					onSortChange,
					emptyResultMessage: 'No tasks to display yet',
					emptyResultMessageType: 'tasks',
					bodyMaxHeight: 168,
					extraSmall: true,
					smallScrollbar: true,
				}}
			/>
		),
		[dataToDisplay, onSortChange, onTaskClick],
	);

	const renderTasks = () => {
		if (dataToDisplay.length) return mainTable;

		return renderEmptyState();
	};

	useEffect(() => {
		if (userInfo?.id && !currentTasks) {
			handleWithTryCatch(
				async () => {
					const assignments = await dispatch(getAssignmentsForUser(userInfo?.id));
					setCurrentTasks(assignments);
					setDataLoading(false);
				},
				undefined,
				() => {
					setDataLoading(false);
					setCurrentTasks([]);
				},
			);
		}
	}, [dispatch, userInfo?.id, currentTasks, controlItems, policyItems, assetItems]);

	useEffect(() => {
		if (currentTasks?.length) {
			const proccessedData: IAssignment[] = processTableData(currentTasks, {}, currentSort);

			setDataToDisplay(proccessedData);
		}
	}, [currentSort, currentTasks]);

	return (
		<div className={classNames(styles.tasks, className)}>
			<h3>My Tasks</h3>

			{dataLoading ? (
				<TableSkeleton rowsNumber={1} columns={getBriefTasksColumns()} />
			) : (
				renderTasks()
			)}
		</div>
	);
};

export default UserTasksBriefTable;
