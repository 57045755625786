import React, { FC } from 'react';
import styles from './Back.module.scss';
import IBack from './IBack';
import classNames from 'classnames';

const Back: FC<IBack> = ({ className, onClick }) => {
	return <span className={classNames(styles.back, className)} onClick={onClick}></span>;
};

export default React.memo(Back);
