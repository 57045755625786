import React, { FC } from 'react';
import styles from './Close.module.scss';
import IClose from './IClose';
import classNames from 'classnames';

const Close: FC<IClose> = ({ className, onClick }) => {
	return <span className={classNames(styles.close, className)} onClick={onClick}></span>;
};

export default React.memo(Close);
