import { ITableColumn } from '../../../primitives/table/ITable';

export const getBriefPoliciesColumns = () => {
	return [
		{
			header: 'Policy Name',
			key: 'name',
			sorting: true,
			width: '100%',
		},
	] as ITableColumn[];
};
