import { FC } from 'react';
import styles from './VariablesBlock.module.scss';
import IVariablesBlock from './IVariablesBlock';
import { IVariable } from '../../../utils/types';
import classNames from 'classnames';
import VariableItem from '../variable-item/VariableItem';
import useUser from '../../../utils/helpers/hooks/useUser';

const VariablesBlock: FC<IVariablesBlock> = ({ className, variables, onValuesChange }) => {
	const { isAdmin } = useUser();

	const onSingleValueChange = (value: string, variableId: string) => {
		if (!isAdmin) return;

		const variablesCopy = { ...variables };

		const newObj = {
			...variablesCopy,
			values: {
				...variablesCopy.values,
				[variableId]: {
					...variablesCopy.values[variableId],
					value: [value],
				},
			},
		};

		onValuesChange(newObj);
	};

	const onPairValueChange = (value: string, unit: string, variableId: string) => {
		if (!isAdmin) return;

		const variablesCopy = { ...variables };

		const newObj = {
			...variablesCopy,
			values: {
				...variablesCopy.values,
				[variableId]: {
					...variablesCopy.values[variableId],
					value: [value, unit],
				},
			},
		};

		onValuesChange(newObj);
	};

	const renderVariablesText = () => {
		const placeholderRegex = /\[Variable:(.*?)\]/g;
		const text = variables?.text?.replace(/\n/g, '\n\n');

		if (!text) return '';

		const segments = text.split(placeholderRegex);

		return segments.map((segment, index) => {
			if (index % 2 === 1) {
				const variableId = segment.trim();

				const variableProps = variables.values[
					variableId as keyof typeof variables
				] as IVariable;

				if (!variableProps) return '';

				const { type, value = [] } = variableProps || {};

				return (
					<VariableItem
						key={variableId}
						variable={{ id: variableId, type, value }}
						onSingleValueChange={onSingleValueChange}
						onPairValueChange={onPairValueChange}
					/>
				);
			} else {
				return <span key={index}>{segment}</span>;
			}
		});
	};

	return (
		<div className={classNames(styles['variables-wrap'], className)}>
			<div className={classNames(styles.variables, styles['inline-style'])}>
				<p className={styles.text}>{renderVariablesText()}</p>
			</div>
		</div>
	);
};

export default VariablesBlock;
