import { FC, useEffect, useState } from 'react';
import styles from './ModalAddTagWithControls.module.scss';
import Modal from '../../../primitives/modal/Modal';
import BasicPanel from '../../../primitives/basic-panel/BasicPanel';
import IModalAddTagWithControls from './IModalAddTagWithControls';
import { ActionsBlock, Button } from '../../../primitives';
import { IControl } from '../../../../services/store/slices/controls.slice';
import useControls from '../../../../utils/helpers/hooks/useControls';
import Select from '../../../primitives/form/select/Select';
import useTags from '../../../../utils/helpers/hooks/useTags';
import AddControlsTable from '../../tables/add-controls-table/AddControlsTable';
import { IIdName } from '../../../../utils/types';
import { ITagBrief } from '../../../../services/store/slices/tags.slice';

const ModalAddTagWithControls: FC<IModalAddTagWithControls> = ({
	open,
	setOpen,
	onNewTagAdded,
	onNewControlsAdded,
	entityControlIds,
	entityTagIds,
	setEntityControls,
	setEntityTags,
}) => {
	const { items: controlItems } = useControls();
	const { items: tagItems } = useTags();

	const [selectedTag, setSelectedTag] = useState<string>('');
	const [tagControls, setTagControls] = useState<IControl[]>([]);
	const [selectedControls, setSelectedControls] = useState<string[]>([]);
	const submitIsDisabled = !selectedTag;

	const onAdd = () => {
		if (selectedControls.length) {
			const selectedControlObjs =
				controlItems
					?.filter((control) => selectedControls.includes(control.id))
					.map(
						(control) =>
							({
								id: control.id,
								displayId: control.controlId,
								name: control.name,
							}) as IIdName,
					) || [];

			const newControls = entityControlIds
				? [...entityControlIds, ...selectedControlObjs]
				: selectedControlObjs;

			onNewControlsAdded(selectedControlObjs);
			setEntityControls(newControls);
			setSelectedControls([]);
		} else if (selectedTag) {
			const selectedTagObj = tagItems?.find((tag) => tag.id === selectedTag) as ITagBrief;
			const newTags = entityTagIds ? [...entityTagIds, selectedTagObj] : [selectedTagObj];

			onNewTagAdded(selectedTagObj);
			setEntityTags(newTags);
			setSelectedTag('');
		}

		setOpen(false);
	};

	const renderControlsTable = () => {
		return (
			<div className={styles['table-wrap']}>
				<div className={styles.text}>
					{tagControls?.length ? (
						<p>
							By adding this tag to a Policy you will also add the following{' '}
							{tagControls?.length} controls to it:
						</p>
					) : (
						<p className={styles.empty}>No controls to add</p>
					)}
				</div>

				{tagControls?.length ? (
					<AddControlsTable
						className={styles.controls}
						bodyMaxHeight={160}
						withSearch={false}
						data={
							tagControls?.map(
								(control) =>
									({
										id: control.id,
										displayId: control.controlId,
										name: control.name,
									}) as IIdName,
							) || []
						}
						selectedRows={selectedControls}
						setSelectedRows={setSelectedControls}
					/>
				) : null}
			</div>
		);
	};

	const renderActions = () => {
		return (
			<ActionsBlock className={styles.actions}>
				<Button width={119} type="button" negative onClick={() => setOpen(false)}>
					Cancel
				</Button>

				<Button width={80} type="button" disabled={submitIsDisabled} onClick={onAdd}>
					Add
				</Button>
			</ActionsBlock>
		);
	};

	useEffect(() => {
		if (selectedTag) {
			const controlsNotInEntity = controlItems?.filter(
				(control) => !entityControlIds.some((ec) => ec.id === control.id),
			);

			const controlsWithSelectedTag = controlsNotInEntity?.filter((control) => {
				return control.tags?.some((tag) => tag.id === selectedTag);
			});

			setTagControls(controlsWithSelectedTag || []);
			setSelectedControls(controlsWithSelectedTag?.map((control) => control.id) || []);
		}
	}, [entityControlIds, controlItems, selectedTag]);

	useEffect(() => {
		if (!open) {
			setSelectedTag('');
			setTagControls([]);
			setSelectedControls([]);
		}
	}, [open]);

	return (
		<Modal {...{ open, setOpen }}>
			<BasicPanel className={styles['basic-panel']}>
				<div className={styles.top}>
					<h2 className={styles.title}>Add Tag</h2>

					<Select
						id="tag"
						className={styles.input}
						options={
							tagItems
								?.filter((tag) => !entityTagIds.some((et) => et.id === tag.id))
								?.map((tag) => ({ value: tag.id, label: tag.name })) || []
						}
						placeholder="Add new tag"
						onValueChange={setSelectedTag}
					/>
				</div>

				{selectedTag ? renderControlsTable() : null}

				{renderActions()}
			</BasicPanel>
		</Modal>
	);
};

export default ModalAddTagWithControls;
