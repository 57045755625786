import axios from 'axios';
import { CHANGE_PASSWORD, HISTORY, USERS } from './api.path';
import { IPasswordChangeData, IUser } from '../store/slices/user.slice';
import { IChangeHistoryEntryBasic } from '../../utils/types';

const userApi = {
	getUserInfo: (): Promise<IUser> => {
		return axios.get(`${USERS}/current-user`);
	},
	updateUserInfo: (data: Partial<IUser>): Promise<IUser> => {
		return axios.put(`${USERS}`, data);
	},
	changePassword: (data: IPasswordChangeData) => {
		return axios.post(CHANGE_PASSWORD, {
			previousPassword: data.oldPassword,
			proposedPassword: data.newPassword,
		});
	},
	getUserUpdates: (): Promise<IChangeHistoryEntryBasic[]> => {
		return axios.get(HISTORY);
	},
};

export default userApi;
