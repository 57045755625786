import axios from 'axios';
import { COMMENTS, CONTROLS } from './api.path';
import { IControlBasic, IUpdateControl } from '../store/slices/controls.slice';
import { ICreateComment } from '../../utils/types';

const controlsApi = {
	getControls: (): Promise<IControlBasic[]> => {
		return axios.get(`${CONTROLS}/all`);
	},
	getControlById: (id: string): Promise<IControlBasic> => {
		return axios.get(`${CONTROLS}/${id}`);
	},
	updateControl: (id: string, data: IUpdateControl) => {
		const owner = data.ownerId === null ? data.ownerId : Number(data.ownerId);

		return axios.put(`${CONTROLS}/${id}`, { ...data, ownerId: owner });
	},
	addComment: (newComment: ICreateComment): Promise<IControlBasic> => {
		return axios.post(`${COMMENTS}`, { ...newComment, entityId: Number(newComment.entityId) });
	},
	getControlsByType: (type: string, companyId: string): Promise<string[]> => {
		return axios.get(`${CONTROLS}/asset-type?assetType=${type}&companyId=${companyId}`);
	},
	assignControls: (ownerId: string, controls: string[]) => {
		return axios.put(`${CONTROLS}/owner/bulk-update`, {
			ownerId: Number(ownerId),
			companyControlIds: controls,
		});
	},
};

export default controlsApi;
