import { useEffect } from 'react';
import useUser from './useUser';
import { useAppDispatch } from '../../../services/store';
import { handleWithTryCatch } from '../errors';
import { getUserInfo } from '../../../services/store/slices/user.slice';
import {
	getCompanyInfo,
	setCompanyPrimaryFramework,
} from '../../../services/store/slices/company.slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppUnauthorizedRoutes, AppUrls } from '../constants/app-routes';
import useFrameworks from './useFrameworks';
import useCompany from './useCompany';
import { getFrameworks } from '../../../services/store/slices/frameworks.slice';
import { getCompanyUsers } from '../../../services/store/slices/company-users.slice';
import { getTags } from '../../../services/store/slices/tags.slice';
import { getControls } from '../../../services/store/slices/controls.slice';
import { getPolicies } from '../../../services/store/slices/policies.slice';
import { getAssets } from '../../../services/store/slices/assets.slice';

export default function useDataPreload() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { authorized, info: userInfo } = useUser();

	const { items: frameworkItems } = useFrameworks();
	const { info: companyInfo, onboarded } = useCompany();

	useEffect(() => {
		if (!AppUnauthorizedRoutes.includes(location.pathname)) {
			handleWithTryCatch(
				async () => {
					if (userInfo === undefined) await dispatch(getUserInfo());
				},
				undefined,
				() => navigate(AppUrls.login),
			);
		}
	}, [dispatch, location.pathname, navigate, userInfo]);

	useEffect(() => {
		if (authorized) handleWithTryCatch(() => dispatch(getUserInfo()));
	}, [authorized, dispatch]);

	useEffect(() => {
		(async () => {
			if (authorized && userInfo?.companyId) {
				await handleWithTryCatch(
					async () => await dispatch(getCompanyInfo(userInfo?.companyId)),
				);

				if (!frameworkItems)
					await handleWithTryCatch(async () => await dispatch(getFrameworks()));
			}
		})();
	}, [authorized, dispatch, userInfo?.companyId, frameworkItems]);

	useEffect(() => {
		(async () => {
			if (authorized && onboarded && frameworkItems) {
				const framework = frameworkItems?.find(
					(framework) => framework.id === companyInfo?.primaryFrameworkId,
				);

				if (framework) await dispatch(setCompanyPrimaryFramework(framework));

				await handleWithTryCatch(async () => await dispatch(getCompanyUsers()));

				if (window.location.pathname !== AppUrls.tags)
					await handleWithTryCatch(async () => await dispatch(getTags()));

				if (window.location.pathname !== AppUrls.controls)
					await handleWithTryCatch(async () => await dispatch(getControls()));

				if (window.location.pathname !== AppUrls.policies)
					await handleWithTryCatch(async () => await dispatch(getPolicies()));

				if (window.location.pathname !== AppUrls.assets)
					await handleWithTryCatch(async () => await dispatch(getAssets()));
			}
		})();
	}, [authorized, companyInfo?.primaryFrameworkId, dispatch, frameworkItems, onboarded]);

	//TODO: think about adding check for shareholder
}
