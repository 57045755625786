import React, { FC } from 'react';
import IOwnerFilter from './IOwnerFilter';
import { ISelectOption } from '../../../../utils/types';
import MultiSelectFilter from '../multi-select-filter/MultiSelectFilter';

const OwnerFilter: FC<IOwnerFilter> = ({ options, placeholder, setFilters }) => {
	const filterKey = 'ownerFilter';
	const propertyName = 'owner';

	const onMultiSelectValueChange = (selectedOptions: ISelectOption[]) => {
		if (!selectedOptions.length) {
			setFilters((prev: any) => ({
				...prev,
				[filterKey]: (dataRecord: any) => true,
			}));
			return;
		}

		setFilters((prev: any) => ({
			...prev,
			[filterKey]: (dataRecord: any) => {
				return selectedOptions.some((v) => {
					if (v.value === 'unassigned') {
						return dataRecord[propertyName] === null;
					}
					return v.value === dataRecord[propertyName]?.id;
				});
			},
		}));
	};

	return (
		<MultiSelectFilter
			placeholder={placeholder || 'Owner'}
			options={options || []}
			onChange={onMultiSelectValueChange}
			isTagStyledList={false}
		/>
	);
};

export default OwnerFilter;
