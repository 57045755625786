import { FC } from 'react';
import styles from './Table.module.scss';
import Checkbox from '../form/checkbox/Checkbox';
import classNames from 'classnames';
import LoadingSkeleton from '../loading-skeleton/LoadingSkeleton';
import { ITableColumn } from './ITable';

const TableSkeleton: FC<{
	rowCheckbox?: boolean;
	columns: ITableColumn[];
	rowsNumber?: number;
	small?: boolean;
	extraSmall?: boolean;
}> = ({ rowCheckbox, columns, small, extraSmall, rowsNumber = 5 }) => {
	const headerSection = (
		<div className={styles.thead}>
			{rowCheckbox ? (
				<div className={styles.th}>
					<Checkbox id="all" disabled />
				</div>
			) : null}

			{columns.map((column, idx) => (
				<div className={styles.th} key={idx} style={{ width: column.width }}>
					{column.header}
				</div>
			))}
		</div>
	);

	return (
		<div
			className={classNames(
				styles.table,
				styles.skeleton,
				small ? styles.small : '',
				extraSmall ? styles['extra-small'] : '',
			)}
		>
			{headerSection}

			<div className={styles.body}>
				<div>
					{Array.from(Array(rowsNumber).keys()).map((row, idx) => (
						<div
							key={idx}
							className={classNames(
								styles.tr,
								rowCheckbox ? styles['with-checkbox'] : '',
							)}
						>
							{rowCheckbox ? (
								<div className={styles.td}>
									<Checkbox id={idx.toString()} disabled />
								</div>
							) : null}

							{columns.map((column, idx) => {
								return (
									<div
										className={styles.td}
										style={{ width: column.width }}
										key={idx}
									>
										<LoadingSkeleton height={24} />
									</div>
								);
							})}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default TableSkeleton;
