import { FC, useEffect, useState } from 'react';
import styles from './TagsBlock.module.scss';
import classNames from 'classnames';
import ColoredTag from '../../primitives/tag/ColoredTag';
import { Button } from '../../primitives';
import useUser from '../../../utils/helpers/hooks/useUser';
import useTags from '../../../utils/helpers/hooks/useTags';
import ITagsBlock from './ITagsBlock';
import ModalAddTag from '../modals/modal-add-tag/ModalAddTag';
import { ITagBrief } from '../../../services/store/slices/tags.slice';

const TagsBlock: FC<ITagsBlock> = ({ className, entityTagIds, setEntityTagIds }) => {
	const { items: tagItems } = useTags();
	const { isAdmin } = useUser();
	const [highlightedTag, setHighlightedTag] = useState<string | null>(null);

	const [openAddTagModal, setOpenAddTagModal] = useState(false);

	const entityTagObjs = tagItems?.filter((tag) => entityTagIds.some((et) => et.id === tag.id));

	const onRemoveTagClick = async (e: any, tagId: string) => {
		if (!isAdmin) return;

		setEntityTagIds(entityTagIds.filter((tag) => tag.id !== tagId));
	};

	const renderTags = () => {
		return (
			<div className={styles.tags}>
				{entityTagObjs?.map((tag) => (
					<ColoredTag
						tagId={tag.id}
						key={tag.id}
						text={tag.name}
						bgColor={tag.color}
						withoutBackground
						onRemoveClickHandler={isAdmin ? onRemoveTagClick : undefined}
						className={classNames(
							styles.tag,
							highlightedTag && highlightedTag === tag.id ? styles.added : '',
						)}
					/>
				))}
			</div>
		);
	};

	const onNewTagAdded = (newTag: ITagBrief) => {
		setHighlightedTag(newTag.id);
		setEntityTagIds([...entityTagIds, newTag]);
	};

	const renderEmptyState = () => {
		if (!entityTagIds?.length)
			return (
				<p className={styles.empty}>
					You don't have any tags assigned to this control. Click "Add Tag" to assign a
					tag.
				</p>
			);
	};

	const renderActions = () => {
		return (
			<div className={styles.actions}>
				<Button
					type="button"
					negative
					small
					width={82}
					className={styles.action}
					onClick={() => setOpenAddTagModal(true)}
				>
					Add Tag
				</Button>
			</div>
		);
	};

	const modals = (
		<ModalAddTag
			open={openAddTagModal}
			setOpen={setOpenAddTagModal}
			onNewAdded={onNewTagAdded}
			entityTagIds={entityTagIds}
		/>
	);

	useEffect(() => {
		if (highlightedTag) {
			setTimeout(() => setHighlightedTag(null), 2000);
		}
	}, [highlightedTag]);

	return (
		<div className={classNames(styles['tags-block'], className)}>
			{renderEmptyState()}

			{renderTags()}

			{isAdmin ? renderActions() : null}

			{modals}
		</div>
	);
};

export default TagsBlock;
