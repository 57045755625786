import React, { FC, useEffect, useState } from 'react';
import styles from './FrameworkStep.module.scss';
import IOnboardingStep, { IFrameworkStepData } from '../IOnboardingStep';
import Card from '../../../components/primitives/card/Card';
import { Button } from '../../../components/primitives';
import useUser from '../../../utils/helpers/hooks/useUser';
import useFrameworks from '../../../utils/helpers/hooks/useFrameworks';
import { IFramework } from '../../../services/store/slices/frameworks.slice';
import ReadMore from '../../../components/primitives/read-more/ReadMore';
import Drawer from '../../../components/primitives/drawer/Drawer';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const FrameworkStep: FC<IOnboardingStep> = ({ setNextStep, setFramework }) => {
	const { info: userInfo } = useUser();
	const { items: frameworkItems } = useFrameworks();
	const [frameworks, setFrameworks] = useState<IFramework[]>([]);
	const [openInfo, setOpenInfo] = useState(false);
	const [currentFramework, setCurrentFramework] = useState<IFramework>();

	const onFrameworkClick = (framework: IFrameworkStepData) => {
		if (setFramework) setFramework(framework);
		setNextStep();
	};

	const renderInfoSection = () => {
		const renderDescription = () => {
			if (currentFramework?.name?.toLowerCase().includes('nist')) {
				const nistFrameworks = frameworkItems?.filter((item) =>
					item.name?.toLowerCase().includes('nist'),
				);

				return nistFrameworks?.map((item) => {
					return (
						<div className={styles.framework} key={item.name}>
							<h4>{item.name}</h4>
							<div className={styles.details}>{item?.description}</div>
						</div>
					);
				});
			} else {
				return <div className={styles.details}>{currentFramework?.description}</div>;
			}
		};

		return (
			<Drawer
				open={openInfo}
				onCloseClickHandler={() => setOpenInfo(false)}
				title={currentFramework?.displayName}
			>
				{openInfo ? renderDescription() : null}
			</Drawer>
		);
	};

	const renderFrameworks = () => {
		return (
			<Swiper
				slidesPerView={2}
				spaceBetween={24}
				pagination={{
					clickable: true,
				}}
				modules={[Pagination]}
				className="mySwiper"
			>
				{frameworks.map((framework) => {
					return (
						<SwiperSlide key={framework.id}>
							<Card className={styles.card}>
								<div className={styles.title}>
									{framework.logo ? <img src={framework.logo} alt="F" /> : null}

									<h3>{framework.displayName}</h3>
								</div>

								<div className={styles.description}>{framework.description}</div>

								<ReadMore
									className={styles.more}
									onClickHandler={() => {
										setCurrentFramework(framework);
										setOpenInfo(true);
									}}
								/>

								<Button
									className={styles.action}
									type="button"
									onClick={() =>
										onFrameworkClick({
											key: framework.name || '',
											name: framework.displayName || '',
											logo: framework.logo || '',
										})
									}
								>
									Select
								</Button>
							</Card>
						</SwiperSlide>
					);
				})}
			</Swiper>
		);
	};

	useEffect(() => {
		const activeFrameworks = frameworkItems?.filter((item) => item.isActive);
		const frameworksToDisplay: IFramework[] = [];

		const nistFramework = activeFrameworks?.find((item) =>
			item.name?.toLowerCase().includes('nist'),
		);
		const isoFramework = activeFrameworks?.find((item) =>
			item.name?.toLowerCase().includes('iso'),
		);
		const socFramework = activeFrameworks?.find((item) =>
			item.name?.toLowerCase().includes('soc'),
		);

		if (nistFramework) frameworksToDisplay.push(nistFramework);
		if (isoFramework) frameworksToDisplay.push(isoFramework);
		if (socFramework) frameworksToDisplay.push(socFramework);

		setFrameworks(frameworksToDisplay || []);
	}, [frameworkItems]);

	return (
		<>
			<p className={styles.info}>
				Welcome {userInfo?.firstName}! Just a couple more steps before we can prepare your
				gap assessment
			</p>

			<p className={styles.info}>
				First, select a desired primary framework to run the analysis.
			</p>

			<div className={styles.cards}>{renderFrameworks()}</div>

			{renderInfoSection()}
		</>
	);
};

export default FrameworkStep;
