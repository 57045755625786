import React, { FC } from 'react';
import styles from './Card.module.scss';
import ICard from './ICard';
import classNames from 'classnames';

const Card: FC<ICard> = ({ className, children }) => {
	return <div className={classNames(styles.card, className)}>{children}</div>;
};

export default Card;
