import React, { FC, PropsWithChildren } from "react";
import styles from "./BasicLayout.module.scss";

const BasicLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles["basic-layout"]}>
      {children}
    </div>
  );
};

export default React.memo(BasicLayout);
