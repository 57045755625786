import React, { FC, useEffect, useState } from 'react';
import styles from './CollapsiblePanel.module.scss';
import ICollapsiblePanel from './ICollapsiblePanel';
import classNames from 'classnames';
import { Collapse } from 'react-collapse';

const CollapsiblePanel: FC<ICollapsiblePanel> = ({
	className,
	children,
	trigger,
	isOpened = true,
}) => {
	const [opened, setOpened] = useState(false);

	useEffect(() => setOpened(isOpened), [isOpened]);

	return (
		<div className={classNames(styles['collapsible-panel'], className)}>
			<div
				className={classNames(styles.trigger, opened ? styles.opened : '')}
				onClick={() => setOpened((prev) => !prev)}
			>
				{trigger}
			</div>

			<Collapse isOpened={opened}>{children}</Collapse>
		</div>
	);
};

export default CollapsiblePanel;
