import React, { FC, useCallback, useState } from 'react';
import IDownloadPolicy from './IDownloadPolicy';
import { ActionButton, ActionProgress } from '../../../primitives';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import { useAppDispatch } from '../../../../services/store';
import { downloadPolicyFile } from '../../../../services/store/slices/policies.slice';
import { Alert }  from '../../../';
import { useFileDownload } from '../../../../utils/helpers/hooks/useFileDownload';

const DownloadPolicy: FC<IDownloadPolicy> = ({
	entityData,
	tooltipId,
	tooltipContent,
	setDownloadingPolicyId,
}) => {
	const dispatch = useAppDispatch();
	const [fileLoading, setFileLoading] = useState(false);
	const [downloadErrorMessage, setDownloadErrorMessage] = useState<string>('');

	const { progress, setProgress, setDownloadResult, downloadResult, downloadFileByUrl } =
		useFileDownload();

	const onPolicyDownload = useCallback(() => {
		setDownloadingPolicyId(entityData?.id || '');
		setFileLoading(true);
		setProgress(0);

		if (entityData?.id) {
			handleWithTryCatch(
				async () => {
					const result = await dispatch(downloadPolicyFile(entityData?.id));

					//@ts-ignore
					await downloadFileByUrl(result.file, `${entityData?.name}.pdf`);
					setTimeout(() => {
						setFileLoading(false);
						setDownloadingPolicyId(null);
					}, 2000);
				},
				undefined,
				(message) => {
					setFileLoading(false);
					setDownloadingPolicyId(null);
					setDownloadErrorMessage(message || '');
					setDownloadResult('error');
				},
			);
		}
	}, [
		dispatch,
		downloadFileByUrl,
		entityData?.id,
		entityData?.name,
		setDownloadResult,
		setDownloadingPolicyId,
		setProgress,
	]);

	const alerts = (
		<>
			<Alert
				uniqueKey={'download-success'}
				show={downloadResult === 'success'}
				type="success"
				message="Policy downloaded"
				clearActionStatus={() => setDownloadResult('')}
			/>
			<Alert
				uniqueKey={'download-error'}
				show={downloadResult === 'error'}
				type="error"
				message={downloadErrorMessage || "Policy wasn't downloaded. Please try again"}
				clearActionStatus={() => setDownloadResult('')}
				hideDelayTime={4000}
			/>
		</>
	);

	return (
		<>
			{fileLoading ? (
				<ActionProgress progress={progress} />
			) : (
				<ActionButton
					tooltipId={tooltipId}
					tooltipContent={tooltipContent}
					type="download"
					onClick={onPolicyDownload}
				/>
			)}

			{alerts}
		</>
	);
};

export default DownloadPolicy;
