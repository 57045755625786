import axios from 'axios';
import { ASSETS, COMMENTS } from './api.path';
import { IComment, ICreateComment } from '../../utils/types';
import { IAssetBasic, ICreateAsset, IUpdateAsset } from '../store/slices/assets.slice';

const assetsApi = {
	getAssets: (companyId: string): Promise<IAssetBasic[]> => {
		return axios.get(`${ASSETS}?archived=false&companyId=${companyId}`);
	},
	getArchivedAssets: (companyId: string): Promise<IAssetBasic[]> => {
		return axios.get(`${ASSETS}?archived=true&companyId=${companyId}`);
	},
	getAssetById: (id: string): Promise<IAssetBasic> => {
		return axios.get(`${ASSETS}/${id}`);
	},
	updateAsset: (assetId: string, companyId: string, data: IUpdateAsset) => {
		const owner = data.ownerId === null ? data.ownerId : Number(data.ownerId);

		return axios.put(`${ASSETS}/${assetId}`, {
			...data,
			companyId: Number(companyId),
			ownerId: owner,
		});
	},
	createAsset: (companyId: string, data: ICreateAsset): Promise<{ assetId: number }> => {
		const owner = data.ownerId === null ? data.ownerId : Number(data.ownerId);

		return axios.post(`${ASSETS}`, { ...data, companyId: Number(companyId), ownerId: owner });
	},
	assignAssets: (ownerId: string | null, assets: string[]) => {
		const owner = ownerId === null ? ownerId : Number(ownerId);

		return axios.put(`${ASSETS}/owner/bulk-update`, {
			ownerId: Number(owner),
			assetIds: assets,
		});
	},
	archiveAssets: (assets: string[]) => {
		return axios.put(`${ASSETS}/archive/bulk-update`, { archive: true, assetIds: assets });
	},
	addComment: (newComment: ICreateComment): Promise<IComment> => {
		return axios.post(`${COMMENTS}`, { ...newComment, entityId: Number(newComment.entityId) });
	},
};

export default assetsApi;
