import React, { FC } from 'react';
import styles from './LoadingSkeleton.module.scss';
import ILoadingSkeleton from './ILoadingSkeleton';
import classNames from 'classnames';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton from 'react-loading-skeleton';
import { CommonColors } from '../../../utils/helpers/constants';

const LoadingSkeleton: FC<ILoadingSkeleton> = ({
	className,
	height,
	width,
	containerClassName,
}) => {
	return (
		<Skeleton
			baseColor={CommonColors.skeletonBaseColor}
			className={classNames(styles.skeleton, className)}
			{...{ height, width, containerClassName }}
		/>
	);
};

export default LoadingSkeleton;
