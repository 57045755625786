import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonResponseError } from '../../../utils/helpers/errors/CommonResponseError';
import companyApi from '../../api/company.api';
import { IFramework } from './frameworks.slice';
import { getLocationWithActualTime } from '../../../utils/helpers/common';
import { IFilePreassignedData } from '../../../utils/types';

export interface IOnboardingStatus {
	onboarded?: boolean | null;
	onBoardingStatus?: string | null;
}

export interface IOnboardingData {
	framework: number;
	fileIds: number[];
}

export interface ICompanySlice {
	loading?: boolean;
	logoLoading?: boolean;
	error?: string | null;
	onboarded?: boolean | null;
	onBoardingStatus?: string | null;
}

export interface ICompanyLocation {
	name: string;
	timeZone: string;
}

export interface ICompanyBasic {
	name?: string;
	location?: ICompanyLocation;
	industry?: string;
	logoId?: string;
	logoUrl?: string;
	primaryFrameworkId?: string;
	primaryFrameworkCompliancePercentage?: number;
	primaryFrameworkComplianceEvaluationDate?: Date;
}

export interface ICompany {
	id?: string;
	name?: string;
	locationName?: string;
	locationTimeZone?: string;
	industry?: string;
	logoId?: string;
	logoUrl?: string;
	primaryFrameworkId?: string;
	primaryFrameworkCompliancePercentage?: number;
	primaryFrameworkComplianceEvaluationDate?: Date;
}

export interface ICompanyInfo extends ICompanySlice {
	info?: Partial<ICompany> | null;
	primaryFramework?: IFramework | null;
}

const initialState: ICompanyInfo = {
	loading: false,
	logoLoading: false,
	error: null,
};

export const companySlice = createSlice({
	name: 'company',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		logoLoading: (state) => {
			state.logoLoading = true;
		},
		logoLoaded: (state) => {
			state.logoLoading = false;
		},
		setCompanyInfoError: (state) => {
			state.error = 'Error while getting company info';
		},
		setCompanyInfo: (state, { payload }: PayloadAction<Partial<ICompany>>) => {
			state.error = null;
			state.info = { ...state.info, ...payload };
		},
		setCompanyPrimaryFramework: (state, { payload }: PayloadAction<IFramework>) => {
			state.primaryFramework = payload;
		},
		setOnboardingStatus: (state, { payload }: PayloadAction<IOnboardingStatus>) => {
			state.onBoardingStatus = payload.onBoardingStatus;
			state.onboarded = payload.onboarded;
		},
	},
});

export const initiateOnboarding = (data: IOnboardingData) => async () => {
	try {
		await companyApi.initiateOnboarding(data);
	} catch (error: any) {
		throw new CommonResponseError('Error while onboarding');
	}
};

export const checkIfOnboarded = () => async (dispatch: Dispatch<any>) => {
	try {
		const onboardingResult = await companyApi.checkIfOnboarded();

		await dispatch(setOnboardingStatus(onboardingResult));
		return onboardingResult;
	} catch (error: any) {
		setOnboardingStatus({ onboarded: null, onBoardingStatus: null });
		throw new CommonResponseError('Error while getting onboarding status');
	}
};

export const getCompanyInfo =
	(companyId?: string, withLoading: boolean = true) =>
	async (dispatch: Dispatch<any>) => {
		if (!companyId) return;
		if (withLoading) dispatch(loading());

		try {
			const companyInfo = await companyApi.getCompanyInfo(companyId);
			
			const {
				name,
				location,
				industry,
				logoId,
				logoUrl,
				primaryFrameworkId,
				primaryFrameworkCompliancePercentage,
				primaryFrameworkComplianceEvaluationDate,
			} = companyInfo || {};

			const transformedLocationTime = location?.name
				? getLocationWithActualTime(location?.name, location?.timeZone)
				: location?.name;

			await dispatch(
				setCompanyInfo({
					id: companyId,
					name,
					industry,
					logoId,
					logoUrl,
					locationName: transformedLocationTime,
					locationTimeZone: location?.timeZone,
					primaryFrameworkId: primaryFrameworkId?.toString(),
					primaryFrameworkCompliancePercentage,
					primaryFrameworkComplianceEvaluationDate,
				}),
			);

			await dispatch(checkIfOnboarded());
		} catch (error: any) {
			dispatch(setCompanyInfoError());
		} finally {
			dispatch(loaded());
		}
	};

export const updateCompanyInfo =
	(companyId: string, data: Partial<ICompany>) => async (dispatch: Dispatch<any>) => {
		dispatch(loading());

		try {
			const companyInfo = await companyApi.updateCompanyInfo(companyId, data);
			const { name, location, industry, logoId, logoUrl } = companyInfo || {};

			await dispatch(
				setCompanyInfo({
					id: companyId,
					name,
					logoId,
					logoUrl,
					industry,
					locationName: location?.name,
					locationTimeZone: location?.timeZone,
				}),
			);
		} catch (error: any) {
			throw new CommonResponseError('Error while updating company');
		} finally {
			dispatch(loaded());
		}
	};

export const getPreassignedUrlForPolicy = (fileName: string) => async () => {
	try {
		const result: IFilePreassignedData = await companyApi.getPreassignedUrlForPolicy(fileName);
		return result;
	} catch (error: any) {
		if (error && error.message && error.message.includes('type'))
			throw new CommonResponseError(error.message);

		throw new CommonResponseError('Error while getting preassigned url for policy');
	}
};

export const getPreassignedUrlForLogo = (fileName: string) => async () => {
	try {
		const result: IFilePreassignedData = await companyApi.getPreassignedUrlForLogo(fileName);
		return result;
	} catch (error: any) {
		throw new CommonResponseError('Error while getting preassigned url for logo');
	}
};

export const deleteCompanyLogo = (fileId: string) => async (dispatch: Dispatch<any>) => {
	dispatch(logoLoading());

	try {
		await companyApi.deleteCompanyLogo(fileId);
	} catch (error: any) {
		throw new CommonResponseError('Error while deleting company logo');
	} finally {
		dispatch(logoLoaded());
	}
};

export const {
	loading,
	loaded,
	logoLoading,
	logoLoaded,
	setCompanyInfoError,
	setCompanyInfo,
	setCompanyPrimaryFramework,
	setOnboardingStatus,
} = companySlice.actions;
export default companySlice.reducer;
