import React, { FC } from 'react';
import styles from './ActionProgress.module.scss';
import IActionProgress from './IActionProgress';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import classNames from 'classnames';

export const ActionProgress: FC<IActionProgress> = React.memo(({ progress, className }) => {
	return (
		<>
			<div className={classNames(styles['progress-wrap'], className)}>
				<CircularProgressbar
					value={progress}
					strokeWidth={16}
					styles={buildStyles({
						pathColor: '#7967E3',
						trailColor: '#EFEDFC',
					})}
				/>

				<div className={styles.percent}>{progress}%</div>
			</div>
		</>
	);
});
