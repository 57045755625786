import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Table from '../../../primitives/table/Table';
import { getBriefAssignmentsColumns } from './utils';
import styles from './AssignmentsBriefTable.module.scss';
import { CommonResultStatusType, IKeyValuePair, ISortProps } from '../../../../utils/types';
import { processTableData } from '../../../../utils/helpers/common';
import classNames from 'classnames';
import IAssignmentsBriefTable from './IAssignmentsBriefTable';
import { IAssignment } from '../../../../services/store/slices/assignments.slice';
import SearchFilter from '../../../primitives/filters/search-filter/SearchFilter';
import HoverTooltip from '../../../primitives/tooltip/HoverTooltip';
import { Alert } from '../../../';
import { ICompanyUser } from '../../../../services/store/slices/company-users.slice';
import useCompanyUsers from '../../../../utils/helpers/hooks/useCompanyUsers';
import { useNavigate } from 'react-router-dom';
import TableSkeleton from '../../../primitives/table/TableSkeleton';
import AssignBlock from '../../../primitives/assign-block/AssignBlock';

const AssignmentsBriefTable: FC<IAssignmentsBriefTable> = ({
	className,
	assignments,
	onAssignmentsChange,
}) => {
	const navigate = useNavigate();
	const { items: companyUsers } = useCompanyUsers();
	const [dataToDisplay, setDataToDisplay] = useState<IAssignment[]>([]);
	const [reminderSendResult, setReminderSendResult] = useState<CommonResultStatusType>('');
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const [showAssignBlock, setShowAssignBlock] = useState(false);

	const [currentAssignments, setCurrentAssignments] = useState<IAssignment[] | null>(null);

	const [currentFilters, setCurrentFilters] = useState<IKeyValuePair>({
		search: (dataRecord: IAssignment) => true,
	});
	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	const reassignSelectedTasks = (ownerId: string) => {
		const ownerToAssign = companyUsers?.find((owner) => owner.id === ownerId);

		const newChanges = currentAssignments?.map((assignment: IAssignment) => {
			if (selectedRows.includes(assignment.id))
				return {
					...assignment,
					owner: ownerToAssign,
				};

			return assignment;
		});

		const onlyChanged = newChanges?.filter((assignment) =>
			selectedRows.includes(assignment.id),
		);
		onAssignmentsChange(onlyChanged || []);

		setCurrentAssignments(newChanges || []);
		setSelectedRows([]);
	};

	// const onSendReminder = useCallback(
	// 	(id: string) => {
	// 		handleWithTryCatch(
	// 			async () => {
	// 				if (id) {
	// 					await dispatch(sendReminder(id));
	// 					setReminderSendResult('success');
	// 				}
	// 			},
	// 			undefined,
	// 			() => setReminderSendResult('error'),
	// 		);
	// 	},
	// 	[dispatch],
	// );

	const onOwnerChange = useCallback(
		(ownerId: string, assignmentId: string) => {
			const ownerToAssign = companyUsers?.find((owner: ICompanyUser) => owner.id === ownerId);

			if (!currentAssignments) return;

			const newChanges = currentAssignments.map((assignment: IAssignment) => {
				if (assignment.id === assignmentId)
					return {
						...assignment,
						owner: ownerToAssign,
					};

				return assignment;
			});

			setCurrentAssignments(newChanges);

			const onlyChanged = newChanges.filter((assignment) => assignment.id === assignmentId);
			onAssignmentsChange(onlyChanged);
		},
		[currentAssignments, onAssignmentsChange, companyUsers],
	);

	const alerts = (
		<>
			<Alert
				uniqueKey={'reminder-success'}
				show={reminderSendResult === 'success'}
				type="success"
				message="Reminder sent successfuly!"
				clearActionStatus={() => setReminderSendResult('')}
			/>
			<Alert
				uniqueKey={'reminder-error'}
				show={reminderSendResult === 'error'}
				type="error"
				message="Error occurred while sending reminder"
				clearActionStatus={() => setReminderSendResult('')}
			/>
		</>
	);

	const mainTable = useMemo(
		() => (
			<Table
				tableKey="assignments-brief"
				data={dataToDisplay}
				columns={getBriefAssignmentsColumns(onOwnerChange, navigate)}
				options={{
					// rowActions: [
					// 	{
					// 		actionComponent: (
					// 			<ActionButton
					// 				className={styles['action-button']}
					// 				tooltipId={'tooltip-reminder'}
					// 				tooltipContent={'Send Reminder'}
					// 				type="reminder"
					// 			/>
					// 		),
					// 		actionHandler: (id: string) => onSendReminder(id),
					// 	},
					// ],
					rowCheckbox: true,
					rowClassName: styles.row,
					selectedRows,
					setSelectedRows,
					onSortChange,
					emptyResultMessage: 'No assignments to display.',
					emptyResultMessageType: 'tasks',
					bodyMaxHeight: 308,
					bodyMinHeight: 250,
					small: true,
					smallScrollbar: true,
				}}
			/>
		),
		[dataToDisplay, navigate, onOwnerChange, onSortChange, selectedRows],
	);

	useEffect(() => {
		if (assignments) setCurrentAssignments(assignments);
	}, [assignments]);

	useEffect(() => {
		setShowAssignBlock(!!selectedRows.length);
	}, [selectedRows.length]);

	useEffect(() => {
		if (currentAssignments) {
			const proccessedData: IAssignment[] = processTableData(
				currentAssignments,
				currentFilters,
				currentSort,
			);

			setDataToDisplay(proccessedData);
		}
	}, [currentFilters, currentSort, currentAssignments]);

	return (
		<div className={classNames(styles['table-wrap'], className)}>
			{!showAssignBlock ? (
				<SearchFilter
					disabled={!currentAssignments?.length}
					className={styles.search}
					properties={['type', 'relatedItem']}
					setFilters={setCurrentFilters}
				/>
			) : null}

			{showAssignBlock ? (
				<AssignBlock
					className={showAssignBlock ? styles.assign : ''}
					show={showAssignBlock}
					onAssign={reassignSelectedTasks}
					withUnassigned={false}
				/>
			) : null}

			{!currentAssignments ? (
				<TableSkeleton
					rowCheckbox
					columns={getBriefAssignmentsColumns(onOwnerChange, navigate)}
					rowsNumber={2}
				/>
			) : (
				mainTable
			)}

			<HoverTooltip tooltipId="tooltip-reminder" place="top-end" />

			{alerts}
		</div>
	);
};

export default AssignmentsBriefTable;
