import React, { FC } from 'react';
import ITagsFilter from './ITagsFilter';
import { ISelectOption } from '../../../../utils/types';
import MultiSelectFilter from '../multi-select-filter/MultiSelectFilter';
import { ITagBrief } from '../../../../services/store/slices/tags.slice';

const TagsFilter: FC<ITagsFilter> = ({ options, placeholder, setFilters }) => {
	const filterKey = 'tagsFilter';
	const propertyName = 'tags';

	const onMultiSelectValueChange = (selectedOptions: ISelectOption[]) => {
		if (!selectedOptions.length) {
			setFilters((prev: any) => ({
				...prev,
				[filterKey]: (dataRecord: any) => true,
			}));
			return;
		}

		setFilters((prev: any) => ({
			...prev,
			[filterKey]: (dataRecord: any) => {
				const arrayOfValues = dataRecord[propertyName].map((tag: ITagBrief) => tag.id);

				return selectedOptions.some((v) => {
					return arrayOfValues.includes(v.value);
				});
			},
		}));
	};

	return (
		<MultiSelectFilter
			placeholder={placeholder || 'Tags'}
			options={options || []}
			onChange={onMultiSelectValueChange}
			isTagStyledList
			isTagsWithoutBg
		/>
	);
};

export default TagsFilter;
